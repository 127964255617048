import { Spin, Layout, Col, Row } from "antd";
import moment from "moment";
import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useDispatch } from "react-redux";
import {
	getCalendar,
	setStoreData,
} from "../../../../redux/actions/managerActions/calendar/actions";

const { Content } = Layout;

const CommonCalendar = (props) => {
	const {
		events = [],
		isLoading = false,
		sidebar = null,
		handleEventClick,
	} = props;
	const calendarRef = React.createRef();
	const dispatch = useDispatch();

	const handleDateSet = (fetchInfo) => {
		let start = moment(fetchInfo.start);
		let startFormatted = moment(fetchInfo.start).format("DD-MM-YYYY");

		let end = moment(fetchInfo.end);
		let endFormatted = moment(fetchInfo.end).format("DD-MM-YYYY");

		let date = startFormatted + "to" + endFormatted;
		dispatch(
			setStoreData({
				currentDate: date,
				events: [],
			})
		);
		dispatch(
			setStoreData({
				start:
					props?.start > start || props?.start === null ? start : props?.start,
				end: props?.end < end || props?.start === null ? end : props?.end,
			})
		);
		const keys = Object.keys(props?.activeCalendars || {});
		keys.forEach((key, index) => {
			if (props.activeCalendars[key] === true) {
				dispatch(getCalendar({ date, required_field: key }));
			}
		});
	};

	const renderEventContent = (eventInfo) => {
		return (
			<>
				<b>{eventInfo.timeText}</b>
				<b style={{ padding: "2px" }}>{eventInfo.event.title}</b>
			</>
		);
	};

	return (
		<div>
			<Spin spinning={isLoading}>
				<Content style={{ padding: 0, background: "white" }}>
					<Row>
						<Col
							span={4}
							style={{
								maxHeight: "90vh",
								overflow: "auto",
							}}>
							{sidebar()}
						</Col>
						<Col
							span={20}
							style={{
								padding: "32px 20px 20px",
							}}>
							<FullCalendar
								collapsibleWidth={true}
								datesSet={handleDateSet}
								ref={calendarRef}
								plugins={[dayGridPlugin, timeGridPlugin]}
								headerToolbar={{
									left: "prev,next today",
									center: "title",
									right: "dayGridMonth,timeGridWeek,timeGridDay",
								}}
								initialView="dayGridMonth"
								selectable={true}
								selectMirror={true}
								dayMaxEvents={true}
								weekends={true}
								events={events}
								initialEvents={events}
								height={1000}
								eventContent={renderEventContent}
								eventClick={handleEventClick}
							/>
						</Col>
					</Row>
				</Content>
			</Spin>
		</div>
	);
};

export default CommonCalendar;
