import React from "react";
import {
  Space,
  Row,
  Col,
  Tag,
  Collapse,
  Switch,
  Menu,
  Checkbox,
  Typography,
  Divider,
} from "antd";
import { PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import SelectFilter from "./../../SelectFilter";
import MenuFilter from "../../MenuFilter";
import SimilarityFilter from "./../../SimilarityFilter";
import DateFilter from "./../../DateFilter";
import ProprietorFilterComponent from "./../../ProprietorFilterComponent";
import MultipleCheckboxComponents from "../../MultipleCheckboxComponents";
import TagFilter from "../../TagFilter";

const { Panel } = Collapse;
const { Text } = Typography;

const FilterSection = (props) => {
  let urlKey = window.location.pathname.split("/")[2];
  let dashboardLayout = window.location.pathname.split("/")[3];
  const [activeDashboardCollapseKeys, setActiveDashboardCollapseKeys] =
    React.useState([]);
  const [activeAdvancedCollapseKeys, setActiveAdvancedCollapseKeys] =
    React.useState([]);
  const [showSelection, setShowSelection] = React.useState(false);
  const {
    journal,
    tmTypes,
    setTMTypes,
    threatTMTypes,
    setThreatTMTypes,
    tmCategories,
    setTMCategories,
    threatTMCategories,
    setThreatTMCategories,
    tmOffices,
    setTMOffices,
    threatTMOffices,
    setThreatTMOffices,
    infringement,
    setInfringement,
    threatAttorneyValue,
    setThreatAttorneyValue,
    proprietors,
    setProprietors,
    threatProprietors,
    setThreatProprietors,
    tags,
    setTags,
    tmClass,
    setTMClass,
    threatTMClass,
    setThreatClass,
    similarity,
    setSimilarity,
    yourDateOfApplication,
    setYourDateOfApplication,
    threatDateOfApplication,
    setThreatDateOfApplication,
    yourDateOfUsage,
    setYourDateOfUsage,
    threatDateOfUsage,
    setThreatDateOfUsage,
    yourDateOfUsageProposed,
    setYourDateOfUsageProposed,
    threatDateOfUsageProposed,
    setThreatDateOfUsageProposed,
    noTags,
    setNoTags,
    excludeMarkProprietors,
    setExcludeMarkProprietors,
    excludeThreatProprietors,
    setExcludeThreatProprietors,
    sameClassInfringement,
    setSameClassInfringement,
    excludeSameClassInfringement,
    setExcludeSameClassInfringement,
    current_template_id,
    andOrTags,
    setAndOrTags,
    andOrClass,
    setAndOrClass,
    andOrThreatClass,
    setAndOrThreatClass,
    shortlisted,
    setShortlisted,
    excludeShortlisted,
    setExcludeShortlisted,
    excludeReported,
    setExcludeReported,
    filterSelected,
    imageTextPercentage,
    setImageTextPercentage,
    threatImageTextPercentage,
    setThreatImageTextPercentage,
  } = props;

  const useMemoizedComponent = (Component, deps, showComponent = true) => {
    return React.useMemo(() => (showComponent ? Component : null), deps);
  };

  const {
    markTypeConfig,
    threatTypeConfig,
    markCategoryConfig,
    threatCategoryConfig,
    markOfficeConfig,
    threatOfficeConfig,
    infringementConfig,
    threatAttorneyConfig,
    markproprietorConfig,
    threatproprietorConfig,
    markClassConfig,
    threatClassConfig,
    tagsConfig,
  } = useSelector(
    (state) => state.watchState.watchFilters[dashboardLayout][urlKey].config,
  );

  const handleRemoveTag = (e, id, list, setVal) => {
    e.preventDefault();
    let tempData = list.filter((item) => item !== id);
    setVal(tempData);
  };

  const twoLayoutChildrenComponent = (
    data1,
    data2,
    value1,
    value2,
    setValue1,
    setValue2,
  ) => (
    <React.Fragment>
      <Row style={{ marginBottom: "10px", fontWeight: "600" }}>
        {!(urlKey === "tm_protect" || urlKey === "journals") && (
          <Col span={12}>{data1.menu_label ? data1.menu_label : ""}</Col>
        )}
        <Col
          span={!(urlKey === "tm_protect" || urlKey === "journals") ? 12 : 24}
        >
          {data2.menu_label ? data2.menu_label : ""}
        </Col>
      </Row>
      <Row>
        {!(urlKey === "tm_protect" || urlKey === "journals") && (
          <Col
            span={12}
            style={{
              maxHeight: "80px",
              overflow: "auto",
              padding: "0px 5px",
              borderRight: "1px solid lightGrey",
            }}
          >
            <Space wrap>
              {value1.length ? (
                value1.map((value, index) => (
                  <Tag
                    closable
                    key={index}
                    onClose={(event) =>
                      handleRemoveTag(event, value, value1, setValue1)
                    }
                    style={{ margin: "auto" }}
                  >
                    {data1.data.length || data1.selectedValues.length
                      ? [...data1.data, ...data1.selectedValues].find(
                          (item) => String(item.value) === String(value),
                        )?.label
                      : ""}
                  </Tag>
                ))
              ) : (
                <div>Filter not selected</div>
              )}
            </Space>
          </Col>
        )}
        <Col
          span={!(urlKey === "tm_protect" || urlKey === "journals") ? 12 : 24}
          style={{ maxHeight: "80px", overflow: "auto", padding: "0px 5px" }}
        >
          <Space wrap>
            {value2.length ? (
              value2.map((value, index) => (
                <Tag
                  closable
                  onClose={(event) =>
                    handleRemoveTag(event, value, value2, setValue2)
                  }
                  style={{ margin: "auto" }}
                >
                  {data2.data.length || data2.selectedValues.length
                    ? [...data2.data, ...data2.selectedValues].find(
                        (item) => String(item.value) === String(value),
                      )?.label
                    : ""}
                </Tag>
              ))
            ) : (
              <div>Filter not selected</div>
            )}
          </Space>
        </Col>
      </Row>
    </React.Fragment>
  );

  const twoLayoutExcludeChildrenComponent = (
    data1,
    data2,
    value1,
    value2,
    setValue1,
    setValue2,
  ) => (
    <React.Fragment>
      <Row style={{ marginBottom: "10px", fontWeight: "600" }}>
        {!(urlKey === "tm_protect" || urlKey === "journals") && (
          <Col span={12}>{data1.menu_label ? data1.menu_label : ""}</Col>
        )}
        <Col
          span={!(urlKey === "tm_protect" || urlKey === "journals") ? 12 : 24}
        >
          {data2.menu_label ? data2.menu_label : ""}
        </Col>
      </Row>
      <Row>
        {!(urlKey === "tm_protect" || urlKey === "journals") && (
          <Col
            span={12}
            style={{
              maxHeight: "80px",
              overflow: "auto",
              padding: "0px 5px",
              borderRight: "1px solid lightGrey",
            }}
          >
            <Space wrap>
              {value1.length ? (
                value1.map((value, index) => (
                  <Tag
                    closable
                    key={index}
                    onClose={(event) =>
                      handleRemoveTag(event, value, value1, setValue1)
                    }
                    style={{ margin: "auto" }}
                  >
                    {data1.data.length || data1.excludeValues.length
                      ? [...data1.data, ...data1.excludeValues].find(
                          (item) => String(item.value) === String(value),
                        )?.label
                      : ""}
                  </Tag>
                ))
              ) : (
                <div>Filter not selected</div>
              )}
            </Space>
          </Col>
        )}
        <Col
          span={!(urlKey === "tm_protect" || urlKey === "journals") ? 12 : 24}
          style={{ maxHeight: "80px", overflow: "auto", padding: "0px 5px" }}
        >
          <Space wrap>
            {value2.length ? (
              value2.map((value, index) => (
                <Tag
                  closable
                  onClose={(event) =>
                    handleRemoveTag(event, value, value2, setValue2)
                  }
                  style={{ margin: "auto" }}
                >
                  {data2.data.length || data2.excludeValues.length
                    ? [...data2.data, ...data2.excludeValues].find(
                        (item) => String(item.value) === String(value),
                      )?.label
                    : ""}
                </Tag>
              ))
            ) : (
              <div>Filter not selected</div>
            )}
          </Space>
        </Col>
      </Row>
    </React.Fragment>
  );

  const oneLayoutChildrenComponent = (data, value, setValue) => (
    <React.Fragment>
      <Row style={{ marginBottom: "10px", fontWeight: "600" }}>
        <Col span={24}>{data.menu_label ? data.menu_label : ""}</Col>
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            maxHeight: "80px",
            overflow: "auto",
            padding: "0px 5px",
          }}
        >
          <Space wrap>
            {value.length ? (
              value.map((id, index) => (
                <Tag
                  closable
                  key={index}
                  onClose={(event) =>
                    handleRemoveTag(event, id, value, setValue)
                  }
                  style={{ margin: "auto" }}
                >
                  {data.data.length || data.selectedValues.length
                    ? [...data.data, ...data.selectedValues].find(
                        (item) => String(item.value) === String(id),
                      )?.label
                    : ""}
                </Tag>
              ))
            ) : (
              <div>Filter not selected</div>
            )}
          </Space>
        </Col>
      </Row>
    </React.Fragment>
  );

  let extraTagChildren = (
    <Menu>
      <Menu.Item
        disabled={tags?.length > 0}
        key="no_tags"
        style={{
          position: "relative",
          marginLeft: "-5px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        className="menu-items-list"
      >
        <Checkbox
          checked={noTags}
          onChange={(event) => setNoTags(event.target.checked)}
          disabled={tags?.length > 0}
        >
          <Text style={{ fontWeight: "400" }}>No Tags</Text>
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  const renderSimpleTag = (label, color = "default") => {
    return (
      <div style={{ marginTop: "3px", marginBottom: "3px" }}>
        <Tag color={color}>{label}</Tag>
      </div>
    );
  };

  const renderBoldText = (text) => (
    <Text style={{ fontWeight: 600 }}>{text}</Text>
  );

  const showNotSelected = (
    <div style={{ marginTop: "3px", marginBottom: "3px" }}>Not Selected</div>
  );

  const dateChildrenComponent = (
    <Row>
      {!(urlKey === "tm_protect" || urlKey === "journals") && (
        <Col span={12}>
          {renderBoldText("Your TM DoA")}
          <br />
          {yourDateOfApplication.dateString
            ? renderSimpleTag(
                yourDateOfApplication.dateString.replace("to", " -> "),
              )
            : showNotSelected}
          <br />
          {renderBoldText("Your TM DoU")}
          <br />
          {yourDateOfUsage.dateString
            ? renderSimpleTag(yourDateOfUsage.dateString.replace("to", " -> "))
            : showNotSelected}
          {yourDateOfUsageProposed
            ? renderSimpleTag("Proposed", "processing")
            : ""}
        </Col>
      )}
      <Col span={!(urlKey === "tm_protect" || urlKey === "journals") ? 12 : 24}>
        {renderBoldText(urlKey === "journals" ? "TM DoA" : "Threat's TM DoA")}
        <br />
        {threatDateOfApplication.dateString
          ? renderSimpleTag(
              threatDateOfApplication.dateString.replace("to", " -> "),
            )
          : showNotSelected}
        <br />
        {renderBoldText(urlKey === "journals" ? "TM DoU" : "Threat's TM DoU")}
        <br />
        {threatDateOfUsage.dateString
          ? renderSimpleTag(threatDateOfUsage.dateString.replace("to", " -> "))
          : showNotSelected}
        {threatDateOfUsageProposed
          ? renderSimpleTag("Proposed", "processing")
          : ""}
      </Col>
    </Row>
  );

  const extraClassChildren = (
    <>
      <div style={{ textAlign: "left", padding: "5px" }}>
        <Checkbox
          disabled={excludeSameClassInfringement}
          checked={sameClassInfringement}
          onChange={(event) => setSameClassInfringement(event.target.checked)}
        >
          Same Class Infringements
        </Checkbox>
      </div>
      <div style={{ textAlign: "left", padding: "5px" }}>
        <Checkbox
          disabled={sameClassInfringement}
          checked={excludeSameClassInfringement}
          onChange={(event) =>
            setExcludeSameClassInfringement(event.target.checked)
          }
        >
          Exclude Same Class Infringements
        </Checkbox>
      </div>
    </>
  );

  const advancedFilterItems = [
    {
      key: "1",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <SelectFilter
                filteredData={threatAttorneyConfig}
                key={threatAttorneyConfig.key}
                value={threatAttorneyValue}
                setValue={setThreatAttorneyValue}
                loading={threatAttorneyConfig.loading}
                journal_number={journal}
                current_template_id={current_template_id}
                filterPath={props.getFilterPath()}
                rename={urlKey === "journals" ? "Attorney" : ""}
              />,
              [threatAttorneyValue, threatAttorneyConfig],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !threatAttorneyValue.length,
      children: (
        <div>
          {oneLayoutChildrenComponent(
            threatAttorneyConfig,
            threatAttorneyValue,
            setThreatAttorneyValue,
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <ProprietorFilterComponent
                filteredData={markproprietorConfig}
                key={markproprietorConfig.key}
                value={proprietors}
                setValue={setProprietors}
                loading={markproprietorConfig.loading}
                journal_number={journal}
                exclude={excludeMarkProprietors}
                setExclude={setExcludeMarkProprietors}
                current_template_id={current_template_id}
                filterPath={props.getFilterPath()}
              />,
              [proprietors, markproprietorConfig, excludeMarkProprietors],
              urlKey === "tm_protect" || urlKey === "journals" ? false : true,
            )}
            {useMemoizedComponent(
              <ProprietorFilterComponent
                filteredData={threatproprietorConfig}
                key={threatproprietorConfig.key}
                value={threatProprietors}
                setValue={setThreatProprietors}
                loading={threatproprietorConfig.loading}
                journal_number={journal}
                exclude={excludeThreatProprietors}
                setExclude={setExcludeThreatProprietors}
                current_template_id={current_template_id}
                filterPath={props.getFilterPath()}
                rename={urlKey === "journals" ? "Proprietors" : ""}
              />,
              [
                threatProprietors,
                threatproprietorConfig,
                excludeThreatProprietors,
              ],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !(
        proprietors.length ||
        threatProprietors.length ||
        excludeMarkProprietors.length ||
        excludeThreatProprietors.length
      ),
      children: (
        <div>
          <div style={{ paddingLeft: "10px", paddingTop: "10px" }}>
            {twoLayoutChildrenComponent(
              markproprietorConfig,
              threatproprietorConfig,
              proprietors,
              threatProprietors,
              setProprietors,
              setThreatProprietors,
            )}
          </div>

          <Divider
            style={{ margin: "0px", marginTop: "15px", marginBottom: "10px" }}
          />
          <Text style={{ color: "#ff0000", fontWeight: 600 }}>Excluded</Text>
          <div style={{ paddingLeft: "10px", paddingTop: "10px" }}>
            {twoLayoutExcludeChildrenComponent(
              markproprietorConfig,
              threatproprietorConfig,
              excludeMarkProprietors,
              excludeThreatProprietors,
              setExcludeMarkProprietors,
              setExcludeThreatProprietors,
            )}
          </div>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <SelectFilter
                filteredData={markClassConfig}
                key={markClassConfig.key}
                value={tmClass}
                setValue={setTMClass}
                loading={markClassConfig.loading}
                journal_number={journal}
                extraChildren={extraClassChildren}
                showSwitch
                andOr={andOrClass}
                setAndOr={setAndOrClass}
                current_template_id={current_template_id}
                extraHighlight={
                  sameClassInfringement || excludeSameClassInfringement
                }
                filterPath={props.getFilterPath()}
              />,
              [
                tmClass,
                markClassConfig,
                excludeSameClassInfringement,
                sameClassInfringement,
                andOrClass,
              ],
              urlKey === "tm_protect" || urlKey === "journals" ? false : true,
            )}
            {useMemoizedComponent(
              <SelectFilter
                filteredData={threatClassConfig}
                key={threatClassConfig.key}
                value={threatTMClass}
                setValue={setThreatClass}
                loading={threatClassConfig.loading}
                journal_number={journal}
                extraChildren={
                  !(urlKey === "tm_protect" || urlKey === "journals") &&
                  extraClassChildren
                }
                showSwitch
                andOr={andOrThreatClass}
                setAndOr={setAndOrThreatClass}
                current_template_id={current_template_id}
                extraHighlight={
                  sameClassInfringement || excludeSameClassInfringement
                }
                filterPath={props.getFilterPath()}
                rename={urlKey === "journals" ? "Classes" : ""}
              />,
              [
                threatTMClass,
                threatClassConfig,
                excludeSameClassInfringement,
                sameClassInfringement,
                andOrThreatClass,
              ],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !(tmClass.length || threatTMClass.length),
      children: (
        <div>
          {twoLayoutChildrenComponent(
            markClassConfig,
            threatClassConfig,
            tmClass,
            threatTMClass,
            setTMClass,
            setThreatClass,
          )}
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <TagFilter
                filteredData={tagsConfig}
                key={tagsConfig.key}
                value={tags}
                setValue={setTags}
                extraChildren={extraTagChildren}
                menuItemDisabled={noTags}
                loading={tagsConfig.loading}
                journal_number={journal}
                current_template_id={current_template_id}
                andOrTags={andOrTags}
                setAndOrTags={setAndOrTags}
                filterPath={props.getFilterPath()}
              />,
              [tags, tagsConfig, noTags, andOrTags],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !(tags.length || noTags),
      children: (
        <div>
          {oneLayoutChildrenComponent(tagsConfig, tags, setTags)}
          {noTags ? renderSimpleTag("No Tags", "processing") : null}
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <SimilarityFilter
                key={"similarity"}
                value={similarity}
                setValue={setSimilarity}
              />,
              [similarity],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !(
        (similarity.from > 0 || similarity.to < 100) &&
        similarity.to !== null
      ),
      children: (
        <div>
          Range
          <br />
          {renderSimpleTag(`${similarity.from} - ${similarity.to}`)}
        </div>
      ),
    },
    {
      key: "6",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <DateFilter
                menu_label="Your TM DoA|DoU"
                subtitle1="Your TM Date of Application"
                subtitle2="Your TM Date of Usage"
                key={"tm_date"}
                value1={yourDateOfApplication}
                setValue1={setYourDateOfApplication}
                value2={yourDateOfUsage}
                setValue2={setYourDateOfUsage}
                proposed={yourDateOfUsageProposed}
                setProposed={setYourDateOfUsageProposed}
              />,
              [yourDateOfApplication, yourDateOfUsage, yourDateOfUsageProposed],
              urlKey === "tm_protect" || urlKey === "journals" ? false : true,
            )}
            {useMemoizedComponent(
              <DateFilter
                menu_label={
                  urlKey === "journals" ? "TM DoA|DoU" : "Threat's TM DoA|DoU"
                }
                subtitle1={
                  urlKey === "journals"
                    ? "TM Date of Application"
                    : "Threat's TM Date of Application"
                }
                subtitle2={
                  urlKey === "journals"
                    ? "TM Date of Usage"
                    : "Threat's TM Date of Usage"
                }
                key={"threat_date"}
                value1={threatDateOfApplication}
                setValue1={setThreatDateOfApplication}
                value2={threatDateOfUsage}
                setValue2={setThreatDateOfUsage}
                proposed={threatDateOfUsageProposed}
                setProposed={setThreatDateOfUsageProposed}
              />,
              [
                threatDateOfApplication,
                threatDateOfUsage,
                threatDateOfUsageProposed,
              ],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !(
        yourDateOfApplication?.dateString.length ||
        yourDateOfUsage?.dateString.length ||
        threatDateOfApplication?.dateString.length ||
        threatDateOfUsage?.dateString.length ||
        yourDateOfUsageProposed ||
        threatDateOfUsageProposed
      ),
      children: <div>{dateChildrenComponent}</div>,
    },
  ];

  const dashboardFilterItems = [
    {
      key: "1",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <MultipleCheckboxComponents
                excludedReport={excludeReported}
                setExcludedReport={setExcludeReported}
                shortlist={shortlisted}
                setShortlist={setShortlisted}
                excludeShortlisted={excludeShortlisted}
                setExcludeShortlisted={setExcludeShortlisted}
              />,
              [excludeReported, shortlisted, excludeShortlisted],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !(shortlisted || excludeShortlisted || excludeReported),
      children: (
        <div>
          {useMemoizedComponent(
            <MultipleCheckboxComponents
              excludedReport={excludeReported}
              setExcludedReport={setExcludeReported}
              shortlist={shortlisted}
              setShortlist={setShortlisted}
              excludeShortlisted={excludeShortlisted}
              setExcludeShortlisted={setExcludeShortlisted}
              tableView
            />,
            [excludeReported, shortlisted, excludeShortlisted],
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <MenuFilter
                filteredData={markTypeConfig}
                key={markTypeConfig.key}
                value={tmTypes}
                setValue={setTMTypes}
                loading={markTypeConfig.loading}
              />,
              [tmTypes, markTypeConfig],
              urlKey === "tm_protect" || urlKey === "journals" ? false : true,
            )}
            {useMemoizedComponent(
              <MenuFilter
                filteredData={threatTypeConfig}
                key={threatTypeConfig.key}
                value={threatTMTypes}
                setValue={setThreatTMTypes}
                loading={threatTypeConfig.loading}
                rename={urlKey === "journals" ? "TM Types" : ""}
              />,
              [threatTMTypes, threatTypeConfig],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !(tmTypes.length || threatTMTypes.length),
      children: (
        <div>
          {twoLayoutChildrenComponent(
            markTypeConfig,
            threatTypeConfig,
            tmTypes,
            threatTMTypes,
            setTMTypes,
            setThreatTMTypes,
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <MenuFilter
                filteredData={markCategoryConfig}
                key={markCategoryConfig.key}
                value={tmCategories}
                setValue={setTMCategories}
                loading={markCategoryConfig.loading}
              />,
              [tmCategories, markCategoryConfig],
              urlKey === "tm_protect" || urlKey === "journals" ? false : true,
            )}
            {useMemoizedComponent(
              <MenuFilter
                filteredData={threatCategoryConfig}
                key={threatCategoryConfig.key}
                value={threatTMCategories}
                setValue={setThreatTMCategories}
                loading={threatCategoryConfig.loading}
                rename={urlKey === "journals" ? "Categories" : ""}
              />,
              [threatTMCategories, threatCategoryConfig],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !(tmCategories.length || threatTMCategories.length),
      children: (
        <div>
          {twoLayoutChildrenComponent(
            markCategoryConfig,
            threatCategoryConfig,
            tmCategories,
            threatTMCategories,
            setTMCategories,
            setThreatTMCategories,
          )}
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <MenuFilter
                filteredData={markOfficeConfig}
                key={markOfficeConfig.key}
                value={tmOffices}
                setValue={setTMOffices}
                loading={markOfficeConfig.loading}
              />,
              [tmOffices, markOfficeConfig],
              urlKey === "tm_protect" || urlKey === "journals" ? false : true,
            )}
            {useMemoizedComponent(
              <MenuFilter
                filteredData={threatOfficeConfig}
                key={threatOfficeConfig.key}
                value={threatTMOffices}
                setValue={setThreatTMOffices}
                loading={threatOfficeConfig.loading}
                rename={urlKey === "journals" ? "Offices" : ""}
              />,
              [threatTMOffices, threatOfficeConfig],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !(tmOffices.length || threatTMOffices.length),
      children: (
        <div>
          {twoLayoutChildrenComponent(
            markOfficeConfig,
            threatOfficeConfig,
            tmOffices,
            threatTMOffices,
            setTMOffices,
            setThreatTMOffices,
          )}
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <MenuFilter
                filteredData={infringementConfig}
                key={infringementConfig.key}
                value={infringement}
                setValue={setInfringement}
                loading={infringementConfig.loading}
              />,
              [infringement, infringementConfig],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !infringement.length,
      children: (
        <div>
          {oneLayoutChildrenComponent(
            infringementConfig,
            infringement,
            setInfringement,
          )}
        </div>
      ),
    },
  ];

  const extraJournalDashboardFilters = [
    {
      key: "6",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <ProprietorFilterComponent
                filteredData={markproprietorConfig}
                key={markproprietorConfig.key}
                value={proprietors}
                setValue={setProprietors}
                loading={markproprietorConfig.loading}
                journal_number={journal}
                exclude={excludeMarkProprietors}
                setExclude={setExcludeMarkProprietors}
                current_template_id={current_template_id}
                filterPath={props.getFilterPath()}
              />,
              [proprietors, markproprietorConfig, excludeMarkProprietors],
              urlKey === "tm_protect" || urlKey === "journals" ? false : true,
            )}
            {useMemoizedComponent(
              <ProprietorFilterComponent
                filteredData={threatproprietorConfig}
                key={threatproprietorConfig.key}
                value={threatProprietors}
                setValue={setThreatProprietors}
                loading={threatproprietorConfig.loading}
                journal_number={journal}
                exclude={excludeThreatProprietors}
                setExclude={setExcludeThreatProprietors}
                current_template_id={current_template_id}
                filterPath={props.getFilterPath()}
                rename={urlKey === "journals" ? "Proprietors" : ""}
              />,
              [
                threatProprietors,
                threatproprietorConfig,
                excludeThreatProprietors,
              ],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !(
        proprietors.length ||
        threatProprietors.length ||
        excludeMarkProprietors.length ||
        excludeThreatProprietors.length
      ),
      children: (
        <div>
          <div style={{ paddingLeft: "10px", paddingTop: "10px" }}>
            {twoLayoutChildrenComponent(
              markproprietorConfig,
              threatproprietorConfig,
              proprietors,
              threatProprietors,
              setProprietors,
              setThreatProprietors,
            )}
          </div>

          <Divider
            style={{ margin: "0px", marginTop: "15px", marginBottom: "10px" }}
          />
          <Text style={{ color: "#ff0000", fontWeight: 600 }}>Excluded</Text>
          <div style={{ paddingLeft: "10px", paddingTop: "10px" }}>
            {twoLayoutExcludeChildrenComponent(
              markproprietorConfig,
              threatproprietorConfig,
              excludeMarkProprietors,
              excludeThreatProprietors,
              setExcludeMarkProprietors,
              setExcludeThreatProprietors,
            )}
          </div>
        </div>
      ),
    },
    {
      key: "7",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <SelectFilter
                filteredData={markClassConfig}
                key={markClassConfig.key}
                value={tmClass}
                setValue={setTMClass}
                loading={markClassConfig.loading}
                journal_number={journal}
                extraChildren={extraClassChildren}
                showSwitch
                andOr={andOrClass}
                setAndOr={setAndOrClass}
                current_template_id={current_template_id}
                extraHighlight={
                  sameClassInfringement || excludeSameClassInfringement
                }
                filterPath={props.getFilterPath()}
              />,
              [
                tmClass,
                markClassConfig,
                excludeSameClassInfringement,
                sameClassInfringement,
                andOrClass,
              ],
              urlKey === "tm_protect" || urlKey === "journals" ? false : true,
            )}
            {useMemoizedComponent(
              <SelectFilter
                filteredData={threatClassConfig}
                key={threatClassConfig.key}
                value={threatTMClass}
                setValue={setThreatClass}
                loading={threatClassConfig.loading}
                journal_number={journal}
                extraChildren={
                  !(urlKey === "tm_protect" || urlKey === "journals") &&
                  extraClassChildren
                }
                showSwitch
                andOr={andOrThreatClass}
                setAndOr={setAndOrThreatClass}
                current_template_id={current_template_id}
                extraHighlight={
                  sameClassInfringement || excludeSameClassInfringement
                }
                filterPath={props.getFilterPath()}
                rename={urlKey === "journals" ? "Classes" : ""}
              />,
              [
                threatTMClass,
                threatClassConfig,
                excludeSameClassInfringement,
                sameClassInfringement,
                andOrThreatClass,
              ],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !(tmClass.length || threatTMClass.length),
      children: (
        <div>
          {twoLayoutChildrenComponent(
            markClassConfig,
            threatClassConfig,
            tmClass,
            threatTMClass,
            setTMClass,
            setThreatClass,
          )}
        </div>
      ),
    },
    {
      key: "8",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <DateFilter
                menu_label="Your TM DoA|DoU"
                subtitle1="Your TM Date of Application"
                subtitle2="Your TM Date of Usage"
                key={"tm_date"}
                value1={yourDateOfApplication}
                setValue1={setYourDateOfApplication}
                value2={yourDateOfUsage}
                setValue2={setYourDateOfUsage}
                proposed={yourDateOfUsageProposed}
                setProposed={setYourDateOfUsageProposed}
              />,
              [yourDateOfApplication, yourDateOfUsage, yourDateOfUsageProposed],
              urlKey === "tm_protect" || urlKey === "journals" ? false : true,
            )}
            {useMemoizedComponent(
              <DateFilter
                menu_label={
                  urlKey === "journals" ? "TM DoA|DoU" : "Threat's TM DoA|DoU"
                }
                subtitle1={
                  urlKey === "journals"
                    ? "TM Date of Application"
                    : "Threat's TM Date of Application"
                }
                subtitle2={
                  urlKey === "journals"
                    ? "TM Date of Usage"
                    : "Threat's TM Date of Usage"
                }
                key={"threat_date"}
                filter_key_1="threat_date_of_application"
                filter_key_2="threat_date_of_usage"
                value1={threatDateOfApplication}
                setValue1={setThreatDateOfApplication}
                value2={threatDateOfUsage}
                setValue2={setThreatDateOfUsage}
                proposed={threatDateOfUsageProposed}
                setProposed={setThreatDateOfUsageProposed}
              />,
              [
                threatDateOfApplication,
                threatDateOfUsage,
                threatDateOfUsageProposed,
              ],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !(
        yourDateOfApplication?.dateString.length ||
        yourDateOfUsage?.dateString.length ||
        threatDateOfApplication?.dateString.length ||
        threatDateOfUsage?.dateString.length ||
        yourDateOfUsageProposed ||
        threatDateOfUsageProposed
      ),
      children: <div>{dateChildrenComponent}</div>,
    },
  ];

  if (urlKey === "journals") {
    dashboardFilterItems.push(...extraJournalDashboardFilters);
  }

  const extraMarkPercentage = [
    {
      key: "7",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <SimilarityFilter
                key={"image_text_percentage"}
                value={imageTextPercentage}
                setValue={setImageTextPercentage}
                type={"image_text_percentage"}
              />,
              [imageTextPercentage],
            )}
            {useMemoizedComponent(
              <SimilarityFilter
                key={"threat_image_text_percentage"}
                value={threatImageTextPercentage}
                setValue={setThreatImageTextPercentage}
                type={"threat_image_text_percentage"}
              />,
              [threatImageTextPercentage],
            )}
          </Space>
        </React.Fragment>
      ),
      disable:
        !(
          (imageTextPercentage.from > 0 || imageTextPercentage.to < 100) &&
          imageTextPercentage.to !== null
        ) &&
        !(
          (threatImageTextPercentage.from > 0 ||
            threatImageTextPercentage.to < 100) &&
          threatImageTextPercentage.to !== null
        ),
      children: (
        <div>
          <Row>
            <Col span={12}>
              Range
              <br />
              {renderSimpleTag(
                `${imageTextPercentage.from} - ${imageTextPercentage.to}`,
              )}
            </Col>
            <Col span={12}>
              Range
              <br />
              {renderSimpleTag(
                `${threatImageTextPercentage.from} - ${threatImageTextPercentage.to}`,
              )}
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  if (urlKey === "image_similarity") {
    advancedFilterItems.push(...extraMarkPercentage);
  }

  const onDashboardCollapseChange = (keys) => {
    setActiveDashboardCollapseKeys(keys);
  };

  const onAdvancedCollapseChange = (keys) => {
    setActiveAdvancedCollapseKeys(keys);
  };

  const collapseProps = {
    size: "small",
    expandIcon: ({ isActive }) =>
      !isActive ? <PlusSquareOutlined /> : <MinusSquareOutlined />,
    expandIconPosition: "end",
    style: { marginBottom: "20px" },
  };

  const handleShowSelection = (value) => {
    setShowSelection(value);
    if (value) {
      let tempDashboardActiveKeys = [
        shortlisted || excludeShortlisted || excludeReported ? "0" : null,
        tmTypes.length || threatTMTypes.length ? "1" : null,
        tmCategories.length || threatTMCategories.length ? "2" : null,
        tmOffices.length || threatTMOffices.length ? "3" : null,
        infringement.length ? "4" : null,

        // for journal dashboard
        proprietors.length ||
        threatProprietors.length ||
        excludeMarkProprietors.length ||
        excludeThreatProprietors.length
          ? "5"
          : null,
        tmClass.length || threatTMClass.length ? "6" : null,
        yourDateOfApplication.dateString.length ||
        threatDateOfApplication.dateString.length ||
        yourDateOfUsage.dateString.length ||
        threatDateOfUsage.dateString.length ||
        yourDateOfUsageProposed ||
        threatDateOfUsageProposed
          ? "7"
          : null,
      ].filter(Boolean);

      let tempAdvancedActiveKeys = [
        threatAttorneyValue.length ? "0" : null,
        proprietors.length ||
        threatProprietors.length ||
        excludeMarkProprietors.length ||
        excludeThreatProprietors.length
          ? "1"
          : null,
        tmClass.length || threatTMClass.length ? "2" : null,
        tags.length || noTags ? "3" : null,
        similarity.from > 0 || similarity.to < 100 ? "4" : null,
        yourDateOfApplication.dateString.length ||
        threatDateOfApplication.dateString.length ||
        yourDateOfUsage.dateString.length ||
        threatDateOfUsage.dateString.length ||
        yourDateOfUsageProposed ||
        threatDateOfUsageProposed
          ? "5"
          : null,
        imageTextPercentage.from > 0 ||
        imageTextPercentage.to < 100 ||
        threatImageTextPercentage.from > 0 ||
        threatImageTextPercentage.to < 100
          ? "6"
          : null,
      ];

      setActiveDashboardCollapseKeys(tempDashboardActiveKeys);
      setActiveAdvancedCollapseKeys(tempAdvancedActiveKeys);
    } else {
      setActiveDashboardCollapseKeys([]);
      setActiveAdvancedCollapseKeys([]);
    }
  };

  useEffect(() => {
    if (!filterSelected) handleShowSelection(filterSelected);
  }, [filterSelected]);

  return (
    <React.Fragment>
      <Row style={{ marginBottom: "10px" }}>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            fontWeight: "500",
            color: filterSelected ? "black" : "lightgrey",
          }}
        >
          Show Selections &nbsp;
          <Switch
            checked={showSelection}
            disabled={!filterSelected}
            onChange={handleShowSelection}
          />
        </Col>
        {urlKey !== "journals" && <Col span={24} style={{ fontWeight: "600", marginBottom: "10px" }}>
          Advanced Filters
        </Col>}
        <Col span={24}>
          {advancedFilterItems.map((item, index) => {
            if (
              (urlKey === "tm_protect" || urlKey === "journals") &&
              index === 3
            ) {
              // to remove tag collapse for tm_protect and journals
              return null;
            } else if (
              urlKey === "journals" &&
              (index === 1 || index === 4 || index === 2 || index === 5 || index === 0)
            ) {
              // to remove similarity collapse for journals
              return null;
            } else {
              return (
                <Collapse
                  key={index}
                  {...collapseProps}
                  activeKey={activeAdvancedCollapseKeys}
                  onChange={onAdvancedCollapseChange}
                >
                  <Panel
                    header={item.label}
                    key={index}
                    collapsible={item.disable ? "disabled" : "icon"}
                    style={{
                      background: !item.disable ? "#ebf4ff" : "inherit",
                    }}
                  >
                    {item.children}
                  </Panel>
                </Collapse>
              );
            }
          })}
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ fontWeight: "600", margin: "10px 0px" }}>
          Dashboard Filters
        </Col>
        <Col span={24}>
          {dashboardFilterItems.map((item, index) => {
            if (
              (urlKey === "journals" || urlKey === "image_similarity") &&
              index === 4
            ) {
              // to remove infringement collapse for journal
              return null;
            } else {
              return (
                <Collapse
                  key={index}
                  {...collapseProps}
                  activeKey={activeDashboardCollapseKeys}
                  onChange={onDashboardCollapseChange}
                >
                  <Panel
                    header={item.label}
                    key={index}
                    collapsible={item.disable ? "disabled" : "icon"}
                    style={{
                      background: !item.disable ? "#ebf4ff" : "inherit",
                    }}
                  >
                    {item.children}
                  </Panel>
                </Collapse>
              );
            }
          })}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FilterSection;
