import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Link } from "react-router-dom";
import deepFreeze from "deep-freeze";

import { Layout, Menu, Avatar, Tooltip, Badge, Alert, Spin } from "antd";
import {
  BellOutlined,
  CalendarOutlined,
  DotChartOutlined,
  FileOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  GlobalOutlined,
  HddOutlined,
  LogoutOutlined,
  MailOutlined,
  OrderedListOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  SmileOutlined,
  TableOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import TrademarkLayout from "./trademark/TrademarkLayout";
import PatentLayout from "./patent/PatentLayout";
import Profile from "./profile/Profile";
import Report from "./report/Report";
import TodoTask from "./to-do-task/TodoList";
import Notification from "./notification/Notification";
import Workflow from "./workflow/Workflow";
import ExamReport from "./report/ExamReport";
import Calendar from "./calendar/Calendar";
//--------IP-SUITE COMMON COMPONENTS--------
import UploadProfileLogo from "../commonComponents/ProfileDetails/UploadProfileLogo";
import WhatsNew from "./../commonComponents/Drawers/Whats-New";
import SurveyModal from "../commonComponents/Modals/SurveyModal";
import EmailTemplate from "../commonComponents/EmailTemplates";
import EmailDashboard from "../commonComponents/EmailDashboard";
import ManagerUpdate from "./subComponents/Modal/managerUpdate";
import ManageEmails from "../commonComponents/ClientEmails";
import ProfileDashboard from "../commonComponents/ProfileDetails/ProfileDashboard";

//--------IP-SUITE ACTIONS--------
import {
  logOut,
  getUserSubscriptions,
  getProfile,
  getAlertMessage,
} from "../../redux/actions/commonActions/actions";

//--------IP-SUITE CONSTANTS--------
import { SUCCESS } from "../../constants/commonConstants/generalConstants";

//--------IP-SUITE STYLE CONSTANTS--------
import { ipSuiteMenuStyle } from "../../constants/commonConstants/styleConstants";
import { changeStateValue_L1 } from "./../../functions/commonFunctions/generalFunctions";
import { GET_MANAGER_ALERT } from "../../redux/actions/commonActions/types";
import Faqs from "./user-maual/Faqs";
import { ContainerOutlined, CopyOutlined } from "@ant-design/icons";
import * as Sentry from "@sentry/browser";
import Notes from "./notes/Notes";
import EmailPreferences from "./profile/EmailPreferences";
import ClientEmailCustomReport from "../commonComponents/CommonReports/ClientEmailCustomReport";
import { ClientMaster } from "../commonComponents/MasterComponents/ClientMaster/ClientMaster";
// import '../../stylesheets/searchStyleSheets/sidebar.css';

const { Sider } = Layout;
const { SubMenu } = Menu;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKeys: [window.location.pathname],
      collapsed: false,
      drawersVisibility: {
        whatsNew: false,
      },
      modalsVisibility: {
        uploadLogo: false,
        CSSurvey: !(
          document.cookie &&
          document.cookie
            .split("; ")
            .find((row) => row.startsWith("Manager_CSSurvey"))
        ),
        suggestions: false,
        complaints: false,
        queriesSurvey: false,
        imageRecognition: true,
        managerUpdate: true,
      },
    };
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.userSubscriptions === null) {
        await this.props.getUserSubscriptions();

        if (this.props.userSubscriptionStatus === SUCCESS) {
          if (
            this.props?.userSubscriptions?.manager.includes("TRADEMARK") ||
            this.props?.userSubscriptions?.manager.includes("PATENT")
          ) {
            this.props.getProfile();
            this.props.getAlertMessage(GET_MANAGER_ALERT, "manager");
          } else if (this.props.userSubscriptions.watch.length > 0) {
            this.props.history.push("/");
          } else {
            this.props.logOut();
          }
        }
      } else {
        if (
          this.props?.userSubscriptions?.manager.includes("TRADEMARK") ||
          this.props?.userSubscriptions?.manager.includes("PATENT")
        ) {
          this.props.getProfile();
          this.props.getAlertMessage(GET_MANAGER_ALERT, "manager");
        } else if (this.props.userSubscriptions.watch.length > 0) {
          this.props.history.push("/");
        } else {
          this.props.logOut();
        }
      }
    } else {
      this.props.logOut();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props?.userDetails?.name !== null && props?.userDetails?.email) {
      localStorage.setItem("userName", props?.userDetails?.name);
      localStorage.setItem("userEmail", props?.userDetails?.email);
      Sentry.setTag("userName", props?.userDetails?.name || "NA");
      Sentry.setTag("userEmail", props?.userDetails?.email || "NA");
    }
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  handleMenuClick(e) {
    this.setState({ selectedKeys: [e.key] });
  }

  render() {
    const { userSubscriptions, userProfile } = this.props;
    if (
      this.props.userSubscriptionStatus === "LOADING" ||
      this.props.userSubscriptionStatus === null
    ) {
      return (
        <div className="loading-spin">
          <Spin />
        </div>
      );
    }
    return (
      <>
        <Layout
          className="MANAGER"
          style={{ height: "100vh", backgroundColor: "white" }}
        >
          <Sider
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
            className="Sidebar side-container vertical_scroll"
          >
            <div
              className="Mikelegal"
              onClick={() => this.props.history.push("/")}
            >
              {this.state.collapsed ? "M" : "MikeLegal"}
            </div>
            {userProfile && (
              <div
                style={{
                  display: `${this.state.collapsed ? "none" : ""}`,
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                <Tooltip
                  placement="right"
                  title="Click here to Upload/Change logo"
                >
                  <Avatar
                    size={80}
                    shape="circle"
                    style={{ margin: "10px" }}
                    className="avatar-logo"
                    onClick={() =>
                      this.changeStateValue_L1(
                        "modalsVisibility",
                        "uploadLogo",
                        true,
                      )
                    }
                  >
                    {userProfile.picture ? (
                      <img
                        src={userProfile.picture}
                        style={{ width: "100%" }}
                        alt="user"
                      />
                    ) : (
                      <UserOutlined />
                    )}
                  </Avatar>
                </Tooltip>
                <div className="Navbar-Profile-Details">{userProfile.name}</div>
                <div className="Navbar-Profile-Details">
                  {userProfile.email}
                </div>
              </div>
            )}
            <Menu
              theme="dark"
              selectedKeys={
                this.state.selectedKeys[0] === "/manager/whats-new"
                  ? this.state.selectedKeys
                  : [window.location.pathname]
              }
              onClick={this.handleMenuClick}
              mode="inline"
              style={ipSuiteMenuStyle}
              className="vertical_scroll"
            >
              {userSubscriptions &&
                (userSubscriptions.manager.includes("TRADEMARK") ||
                  userSubscriptions.manager.includes("PSEUDO_TRADEMARK") ||
                  userSubscriptions.manager.includes("CUSTOM_TRADEMARK") ||
                  userSubscriptions.manager.includes(
                    "INTERNATIONAL_TRADEMARK",
                  )) && (
                  <SubMenu
                    key="trademark"
                    className="ipsuite-submenu-item"
                    title={
                      <span>
                        <i className="anticon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            width="1em"
                            height="1em"
                            viewBox="64 64 600 600"
                          >
                            <image
                              id="TM"
                              data-name="TM"
                              x="50"
                              y="0"
                              width="570"
                              height="570"
                              xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAZCAQAAADdqGXyAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfjCxYSEwrAPiVMAAABk0lEQVRIx+3VP2gTYRzG8U9rQNRA4+DYChUR0UEKgkqRIFgcOoij0EEF6d5FVIodqlv3gIIUQYSCiGDbQdrNxc3JSWjQQpE6FFpirK/DnWfq5ZKQy6bPLe+9z++97++e+/P2hZdO2dVOBdOe44wX4J6FNiseuI41oVPNBEEoJ+eDQYvjUlxVK3jtpJBwdx1wFDvW9Df00+cjqCUzi05ndl/0Jh59SbOPhRBCWM3o7HzDPVUy+3+X1HzqT9GjHn+2zLcKbpto6s44B75CGnAI7GsJeOgGmDec8i6YBle9bQ7oREOeWoEk69/abxnMeRXBuwMcwbhvOOHJHmdJER9Modg9oI5tY+CmW8n8HWXUlcGP7gGR3psEjx0HIx6BMZt/ivIAqKjEwSB6qO5bbSwp5AIw6awRw2YNKGHZ7N6CvAAuqzroLthw5W87X0SwaTwZX0zb+QGsmAIT8f+qxxHBnGu+e9bM6g2A0SyjFxG11H/APwmI9rKBjProtS52dO0SDqe/g7otrGcsqtkS77Zt9VlJ9RdbpM8dDLA8ogAAAABJRU5ErkJggg=="
                            />
                          </svg>
                        </i>
                        <span>Trademark</span>
                      </span>
                    }
                  >
                    {(userSubscriptions.manager.includes("TRADEMARK") ||
                      userSubscriptions.manager.includes(
                        "PSEUDO_TRADEMARK",
                      )) && (
                      <SubMenu
                        key="your-trademark"
                        className="ipsuite-submenu-item-1"
                        title={
                          <span>
                            <i className="anticon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                width="1em"
                                height="1em"
                                viewBox="64 64 600 600"
                              >
                                <image
                                  id="TM"
                                  data-name="TM"
                                  x="50"
                                  y="0"
                                  width="570"
                                  height="570"
                                  xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAZCAQAAADdqGXyAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfjCxYSEwrAPiVMAAABk0lEQVRIx+3VP2gTYRzG8U9rQNRA4+DYChUR0UEKgkqRIFgcOoij0EEF6d5FVIodqlv3gIIUQYSCiGDbQdrNxc3JSWjQQpE6FFpirK/DnWfq5ZKQy6bPLe+9z++97++e+/P2hZdO2dVOBdOe44wX4J6FNiseuI41oVPNBEEoJ+eDQYvjUlxVK3jtpJBwdx1wFDvW9Df00+cjqCUzi05ndl/0Jh59SbOPhRBCWM3o7HzDPVUy+3+X1HzqT9GjHn+2zLcKbpto6s44B75CGnAI7GsJeOgGmDec8i6YBle9bQ7oREOeWoEk69/abxnMeRXBuwMcwbhvOOHJHmdJER9Modg9oI5tY+CmW8n8HWXUlcGP7gGR3psEjx0HIx6BMZt/ivIAqKjEwSB6qO5bbSwp5AIw6awRw2YNKGHZ7N6CvAAuqzroLthw5W87X0SwaTwZX0zb+QGsmAIT8f+qxxHBnGu+e9bM6g2A0SyjFxG11H/APwmI9rKBjProtS52dO0SDqe/g7otrGcsqtkS77Zt9VlJ9RdbpM8dDLA8ogAAAABJRU5ErkJggg=="
                                />
                              </svg>
                            </i>
                            <span>Your Trademark</span>
                          </span>
                        }
                      >
                        <Menu.Item
                          key="/manager/trademark/your-trademark/dashboard"
                          className="ipsuite-submenu-item-2"
                        >
                          <Link to="/manager/trademark/your-trademark/dashboard">
                            <span>Dashboard</span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key="/manager/trademark/your-trademark/total-marks"
                          className="ipsuite-submenu-item-2"
                        >
                          <Link to="/manager/trademark/your-trademark/total-marks">
                            <span>Total Application</span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key="/manager/trademark/your-trademark/reply-to-correspondence"
                          className="ipsuite-submenu-item-2"
                        >
                          <Link to="/manager/trademark/your-trademark/reply-to-correspondence">
                            <span>Reply To Correspondence</span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key="/manager/trademark/your-trademark/upcoming-hearings"
                          className="ipsuite-submenu-item-2"
                        >
                          <Link to="/manager/trademark/your-trademark/upcoming-hearings">
                            <span>Upcoming Hearings</span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key="/manager/exam-report"
                          className="ipsuite-submenu-item-2"
                        >
                          <Link to="/manager/exam-report">
                            <span>Examination Report</span>
                          </Link>
                        </Menu.Item>
                      </SubMenu>
                    )}
                    {(userSubscriptions.manager.includes("TRADEMARK") ||
                      userSubscriptions.manager.includes(
                        "PSEUDO_TRADEMARK",
                      )) && (
                      <SubMenu
                        key="opposed-trademark"
                        className="ipsuite-submenu-item-1"
                        title={
                          <span>
                            <i className="anticon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                width="1em"
                                height="1em"
                                viewBox="64 64 600 600"
                              >
                                <image
                                  id="TM"
                                  data-name="TM"
                                  x="50"
                                  y="0"
                                  width="570"
                                  height="570"
                                  xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAZCAQAAADdqGXyAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfjCxYSEwrAPiVMAAABk0lEQVRIx+3VP2gTYRzG8U9rQNRA4+DYChUR0UEKgkqRIFgcOoij0EEF6d5FVIodqlv3gIIUQYSCiGDbQdrNxc3JSWjQQpE6FFpirK/DnWfq5ZKQy6bPLe+9z++97++e+/P2hZdO2dVOBdOe44wX4J6FNiseuI41oVPNBEEoJ+eDQYvjUlxVK3jtpJBwdx1wFDvW9Df00+cjqCUzi05ndl/0Jh59SbOPhRBCWM3o7HzDPVUy+3+X1HzqT9GjHn+2zLcKbpto6s44B75CGnAI7GsJeOgGmDec8i6YBle9bQ7oREOeWoEk69/abxnMeRXBuwMcwbhvOOHJHmdJER9Modg9oI5tY+CmW8n8HWXUlcGP7gGR3psEjx0HIx6BMZt/ivIAqKjEwSB6qO5bbSwp5AIw6awRw2YNKGHZ7N6CvAAuqzroLthw5W87X0SwaTwZX0zb+QGsmAIT8f+qxxHBnGu+e9bM6g2A0SyjFxG11H/APwmI9rKBjProtS52dO0SDqe/g7otrGcsqtkS77Zt9VlJ9RdbpM8dDLA8ogAAAABJRU5ErkJggg=="
                                />
                              </svg>
                            </i>
                            <span>3rd Party TradeMarks</span>
                          </span>
                        }
                      >
                        <Menu.Item
                          key="/manager/trademark/opposed-trademark/dashboard"
                          className="ipsuite-submenu-item-2"
                        >
                          <Link to="/manager/trademark/opposed-trademark/dashboard">
                            <span>Dashboard</span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key="/manager/trademark/opposed-trademark/total-marks"
                          className="ipsuite-submenu-item-2"
                        >
                          <Link to="/manager/trademark/opposed-trademark/total-marks">
                            <span>Total Application</span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key="/manager/trademark/opposed-trademark/reply-to-correspondence"
                          className="ipsuite-submenu-item-2"
                        >
                          <Link to="/manager/trademark/opposed-trademark/reply-to-correspondence">
                            <span>Reply To Correspondence</span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key="/manager/trademark/opposed-trademark/upcoming-hearings"
                          className="ipsuite-submenu-item-2"
                        >
                          <Link to="/manager/trademark/opposed-trademark/upcoming-hearings">
                            <span>Upcoming Hearings</span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key="/manager/trademark/opposed-trademark/status-tracker"
                          className="ipsuite-submenu-item-2"
                        >
                          <Link to="/manager/trademark/opposed-trademark/status-tracker">
                            {/* <Icon type="global" /> */}
                            <span>Status Tracker</span>
                          </Link>
                        </Menu.Item>
                      </SubMenu>
                    )}
                    {(userSubscriptions.manager.includes("CUSTOM_TRADEMARK") ||
                      userSubscriptions.manager.includes(
                        "INTERNATIONAL_TRADEMARK",
                      )) && (
                      <SubMenu
                        key="Docketed-trademark"
                        className="ipsuite-submenu-item-1"
                        title={
                          <span>
                            <i className="anticon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                width="1em"
                                height="1em"
                                viewBox="64 64 600 600"
                              >
                                <image
                                  id="TM"
                                  data-name="TM"
                                  x="50"
                                  y="0"
                                  width="570"
                                  height="570"
                                  xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAZCAQAAADdqGXyAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfjCxYSEwrAPiVMAAABk0lEQVRIx+3VP2gTYRzG8U9rQNRA4+DYChUR0UEKgkqRIFgcOoij0EEF6d5FVIodqlv3gIIUQYSCiGDbQdrNxc3JSWjQQpE6FFpirK/DnWfq5ZKQy6bPLe+9z++97++e+/P2hZdO2dVOBdOe44wX4J6FNiseuI41oVPNBEEoJ+eDQYvjUlxVK3jtpJBwdx1wFDvW9Df00+cjqCUzi05ndl/0Jh59SbOPhRBCWM3o7HzDPVUy+3+X1HzqT9GjHn+2zLcKbpto6s44B75CGnAI7GsJeOgGmDec8i6YBle9bQ7oREOeWoEk69/abxnMeRXBuwMcwbhvOOHJHmdJER9Modg9oI5tY+CmW8n8HWXUlcGP7gGR3psEjx0HIx6BMZt/ivIAqKjEwSB6qO5bbSwp5AIw6awRw2YNKGHZ7N6CvAAuqzroLthw5W87X0SwaTwZX0zb+QGsmAIT8f+qxxHBnGu+e9bM6g2A0SyjFxG11H/APwmI9rKBjProtS52dO0SDqe/g7otrGcsqtkS77Zt9VlJ9RdbpM8dDLA8ogAAAABJRU5ErkJggg=="
                                />
                              </svg>
                            </i>
                            <span>Docketed Trademark</span>
                          </span>
                        }
                      >
                        {userSubscriptions.manager.includes(
                          "INTERNATIONAL_TRADEMARK",
                        ) && (
                          <Menu.Item
                            key="/manager/trademark/docketed-trademark/international-trademark"
                            className="ipsuite-submenu-item-2"
                          >
                            <Link to="/manager/trademark/docketed-trademark/international-trademark">
                              <span>International Trademark</span>
                            </Link>
                          </Menu.Item>
                        )}
                        {userSubscriptions.manager.includes(
                          "CUSTOM_TRADEMARK",
                        ) && (
                          <Menu.Item
                            key="/manager/trademark/docketed-trademark/custom-trademark"
                            className="ipsuite-submenu-item-2"
                          >
                            <Link to="/manager/trademark/docketed-trademark/custom-trademark">
                              <span>Custom Trademark</span>
                            </Link>
                          </Menu.Item>
                        )}
                      </SubMenu>
                    )}
                    <Menu.Item
                      key="/manager/trademark/manage-trademark"
                      className="ipsuite-submenu-list"
                    >
                      <Link to="/manager/trademark/manage-trademark">
                        <TableOutlined />
                        <span>Manage Trademark</span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                )}
              {userSubscriptions &&
                (userSubscriptions.manager.includes("PATENT") ||
                  userSubscriptions.manager.includes("PSEUDO_PATENT") ||
                  userSubscriptions.manager.includes("CUSTOM_PATENT") ||
                  userSubscriptions.manager.includes(
                    "INTERNATIONAL_PATENT",
                  )) && (
                  <SubMenu
                    key="patent"
                    className="ipsuite-submenu-item"
                    title={
                      <span>
                        <i className="anticon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            width="1em"
                            height="1em"
                            viewBox="64 64 896 896"
                          >
                            <image
                              id="P_copy"
                              data-name="P copy"
                              x="155"
                              y="50"
                              width="600"
                              height="600"
                              xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAQAAACbHsZYAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfjCxYRNDjQanjwAAAA9ElEQVQ4y8XRMUoDQRiG4XcWFSE2sVAIWgZs7C1sbAQl57DyAB7BAxg8iZVgYxewSiNqYWKzglgoGNSwr8VsMEGys53fNMPwzPzzzwSvWaNgNgF55Y5LrsjLNasz8kREgjnrfHP/58wVNsv5BR3AXH2Mu2ZGsG23PPfMChjHfkk3IhwY5tJTVc/TsKVqPyOVN0bAchoWjIEsDZdoAOM03CEDBulmhqoeRvgwB23bU/VWFgBockRBmGpgkRZ77ALwxcHkC6vSd0ushM/2PJ5cI5bO6eBU6YC8kPP523yEH9ykXikreagHa+Rf4SrQrAOHvPOUhj+g+CsPaYO9dgAAAABJRU5ErkJggg=="
                            />
                          </svg>
                        </i>
                        <span>Patent</span>
                      </span>
                    }
                  >
                    {(userSubscriptions.manager.includes("PATENT") ||
                      userSubscriptions.manager.includes("PSEUDO_PATENT")) && (
                      <SubMenu
                        key="your-patent"
                        className="ipsuite-submenu-item-1"
                        title={
                          <span>
                            <i className="anticon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                width="1em"
                                height="1em"
                                viewBox="64 64 896 896"
                              >
                                <image
                                  id="P_copy"
                                  data-name="P copy"
                                  x="155"
                                  y="50"
                                  width="600"
                                  height="600"
                                  xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAQAAACbHsZYAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfjCxYRNDjQanjwAAAA9ElEQVQ4y8XRMUoDQRiG4XcWFSE2sVAIWgZs7C1sbAQl57DyAB7BAxg8iZVgYxewSiNqYWKzglgoGNSwr8VsMEGys53fNMPwzPzzzwSvWaNgNgF55Y5LrsjLNasz8kREgjnrfHP/58wVNsv5BR3AXH2Mu2ZGsG23PPfMChjHfkk3IhwY5tJTVc/TsKVqPyOVN0bAchoWjIEsDZdoAOM03CEDBulmhqoeRvgwB23bU/VWFgBockRBmGpgkRZ77ALwxcHkC6vSd0ushM/2PJ5cI5bO6eBU6YC8kPP523yEH9ykXikreagHa+Rf4SrQrAOHvPOUhj+g+CsPaYO9dgAAAABJRU5ErkJggg=="
                                />
                              </svg>
                            </i>
                            <span>Your Patent</span>
                          </span>
                        }
                      >
                        <Menu.Item
                          key="/manager/patent/your-patent/dashboard"
                          className="ipsuite-submenu-item-2"
                        >
                          <Link to="/manager/patent/your-patent/dashboard">
                            <span>Dashboard</span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key="/manager/patent/your-patent/total-marks"
                          className="ipsuite-submenu-item-2"
                        >
                          <Link to="/manager/patent/your-patent/total-marks">
                            <span>Total Application</span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key="/manager/patent/your-patent/reply-to-fer"
                          className="ipsuite-submenu-item-2"
                        >
                          <Link to="/manager/patent/your-patent/reply-to-fer">
                            <span>Reply To Fer</span>
                          </Link>
                        </Menu.Item>
                      </SubMenu>
                    )}
                    {userSubscriptions.manager.includes(
                      "UNPUBLISHED_PATENT",
                    ) && (
                      <SubMenu
                        key="unpublished-patent"
                        className="ipsuite-submenu-item-1"
                        title={
                          <span>
                            <i className="anticon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                width="1em"
                                height="1em"
                                viewBox="64 64 896 896"
                              >
                                <image
                                  id="U_copy"
                                  data-name="U copy"
                                  x="155"
                                  y="50"
                                  width="600"
                                  height="600"
                                  xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAQAAACbHsZYAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfjCxYRNDjQanjwAAAA9ElEQVQ4y8XRMUoDQRiG4XcWFSE2sVAIWgZs7C1sbAQl57DyAB7BAxg8iZVgYxewSiNqYWKzglgoGNSwr8VsMEGys53fNMPwzPzzzwSvWaNgNgF55Y5LrsjLNasz8kREgjnrfHP/58wVNsv5BR3AXH2Mu2ZGsG23PPfMChjHfkk3IhwY5tJTVc/TsKVqPyOVN0bAchoWjIEsDZdoAOM03CEDBulmhqoeRvgwB23bU/VWFgBockRBmGpgkRZ77ALwxcHkC6vSd0ushM/2PJ5cI5bO6eBU6YC8kPP523yEH9ykXikreagHa+Rf4SrQrAOHvPOUhj+g+CsPaYO9dgAAAABJRU5ErkJggg=="
                                />
                              </svg>
                            </i>
                            <span>Unpublished Patent</span>
                          </span>
                        }
                      >
                        <Menu.Item
                          key="/manager/patent/unpublished-patent/dashboard"
                          className="ipsuite-submenu-item-2"
                        >
                          <Link to="/manager/patent/unpublished-patent/dashboard">
                            <span>Dashboard</span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key="/manager/patent/unpublished-patent/manage"
                          className="ipsuite-submenu-item-2"
                        >
                          <Link to="/manager/patent/unpublished-patent/manage">
                            <span>Manage</span>
                          </Link>
                        </Menu.Item>
                      </SubMenu>
                    )}

                    {(userSubscriptions.manager.includes("CUSTOM_PATENT") ||
                      userSubscriptions.manager.includes(
                        "INTERNATIONAL_PATENT",
                      )) && (
                      <SubMenu
                        key="Docketed-patent"
                        className="ipsuite-submenu-item-1"
                        title={
                          <span>
                            <i className="anticon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                width="1em"
                                height="1em"
                                viewBox="64 64 896 896"
                              >
                                <image
                                  id="P_copy"
                                  data-name="P copy"
                                  x="155"
                                  y="50"
                                  width="600"
                                  height="600"
                                  xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAQAAACbHsZYAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfjCxYRNDjQanjwAAAA9ElEQVQ4y8XRMUoDQRiG4XcWFSE2sVAIWgZs7C1sbAQl57DyAB7BAxg8iZVgYxewSiNqYWKzglgoGNSwr8VsMEGys53fNMPwzPzzzwSvWaNgNgF55Y5LrsjLNasz8kREgjnrfHP/58wVNsv5BR3AXH2Mu2ZGsG23PPfMChjHfkk3IhwY5tJTVc/TsKVqPyOVN0bAchoWjIEsDZdoAOM03CEDBulmhqoeRvgwB23bU/VWFgBockRBmGpgkRZ77ALwxcHkC6vSd0ushM/2PJ5cI5bO6eBU6YC8kPP523yEH9ykXikreagHa+Rf4SrQrAOHvPOUhj+g+CsPaYO9dgAAAABJRU5ErkJggg=="
                                />
                              </svg>
                            </i>
                            <span>Docketed Patent</span>
                          </span>
                        }
                      >
                        {userSubscriptions.manager.includes(
                          "INTERNATIONAL_PATENT",
                        ) && (
                          <Menu.Item
                            key="/manager/patent/docketed-patent/international-patent"
                            className="ipsuite-submenu-item-2"
                          >
                            <Link to="/manager/patent/docketed-patent/international-patent">
                              <span>International Patent</span>
                            </Link>
                          </Menu.Item>
                        )}
                        {userSubscriptions.manager.includes(
                          "CUSTOM_PATENT",
                        ) && (
                          <Menu.Item
                            key="/manager/patent/docketed-patent/custom-patent"
                            className="ipsuite-submenu-item-2"
                          >
                            <Link to="/manager/patent/docketed-patent/custom-patent">
                              <span>Custom Patent</span>
                            </Link>
                          </Menu.Item>
                        )}
                      </SubMenu>
                    )}

                    <Menu.Item
                      key="/manager/patent/your-patent/manage-patent"
                      className="ipsuite-submenu-list"
                    >
                      <Link to="/manager/patent/your-patent/manage-patent">
                        <TableOutlined />
                        <span>Manage Patent</span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                )}
              <SubMenu
                key="manage-emails"
                className="ipsuite-submenu-item"
                title={
                  <span>
                    {/* <i className="anticon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="1em" height="1em" viewBox="64 64 600 600">
                      <image id="TM" data-name="TM" x="50" y="0" width="570" height="570" xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAZCAQAAADdqGXyAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfjCxYSEwrAPiVMAAABk0lEQVRIx+3VP2gTYRzG8U9rQNRA4+DYChUR0UEKgkqRIFgcOoij0EEF6d5FVIodqlv3gIIUQYSCiGDbQdrNxc3JSWjQQpE6FFpirK/DnWfq5ZKQy6bPLe+9z++97++e+/P2hZdO2dVOBdOe44wX4J6FNiseuI41oVPNBEEoJ+eDQYvjUlxVK3jtpJBwdx1wFDvW9Df00+cjqCUzi05ndl/0Jh59SbOPhRBCWM3o7HzDPVUy+3+X1HzqT9GjHn+2zLcKbpto6s44B75CGnAI7GsJeOgGmDec8i6YBle9bQ7oREOeWoEk69/abxnMeRXBuwMcwbhvOOHJHmdJER9Modg9oI5tY+CmW8n8HWXUlcGP7gGR3psEjx0HIx6BMZt/ivIAqKjEwSB6qO5bbSwp5AIw6awRw2YNKGHZ7N6CvAAuqzroLthw5W87X0SwaTwZX0zb+QGsmAIT8f+qxxHBnGu+e9bM6g2A0SyjFxG11H/APwmI9rKBjProtS52dO0SDqe/g7otrGcsqtkS77Zt9VlJ9RdbpM8dDLA8ogAAAABJRU5ErkJggg==" />
                    </svg>
                    
                  </i> */}
                    {/* <Icon type="ContainerOutlined" /> */}
                    <MailOutlined />

                    <span>Manage Emails</span>
                  </span>
                }
              >
                <Menu.Item
                  icon={<CopyOutlined />}
                  key="/manager/email-template"
                  className="ipsuite-submenu-list"
                >
                  <Link to="/manager/email-template">
                    <span>Email Template</span>
                  </Link>
                </Menu.Item>
                <Menu.Item
                  icon={<ContainerOutlined />}
                  key="/manager/email-dashboard"
                  className="ipsuite-submenu-list"
                >
                  <Link to="/manager/email-dashboard">
                    <span>Email Dashboard</span>
                  </Link>
                </Menu.Item>
                <Menu.Item
                  icon={<TableOutlined />}
                  key="/manager/email-management"
                  className="ipsuite-submenu-list"
                >
                  <Link to="/manager/email-management">
                    <span>Client Emails</span>
                  </Link>
                </Menu.Item>

                <Menu.Item
                  icon={<SettingOutlined />}
                  key="/manager/email-preferences"
                  className="ipsuite-submenu-list"
                >
                  <Link to="/manager/email-preferences">
                    <span>Email Preferences</span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu
                icon={<DotChartOutlined />}
                key="reports"
                className="ipsuite-submenu-item"
                title={<span>Reports</span>}
              >
                <Menu.Item
                  icon={<FolderOpenOutlined />}
                  key="/manager/report"
                  className="ipsuite-submenu-list"
                >
                  <Link to="/manager/report">
                    <span>Report Dashboard</span>
                  </Link>
                </Menu.Item>
                <Menu.Item
                  icon={<OrderedListOutlined />}
                  key="/manager/client-email-report"
                  className="ipsuite-submenu-list"
                >
                  <Link to="/manager/client-email-report">
                    <span>Client Email</span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <Menu.Item
                key="/manager/calendar"
                className="ipsuite-menu-item"
                icon={<CalendarOutlined />}
              >
                <Link to="/manager/calendar">
                  <span>Calendar</span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key="/manager/client-master"
                className="ipsuite-menu-item"
                icon={<UsergroupAddOutlined />}
              >
                <Link to="/manager/client-master">
                  <span>Client Master</span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key="/manager/profile"
                className="ipsuite-menu-item"
                icon={<UserOutlined />}
              >
                <Link to="/manager/profile">
                  <span>Profile</span>
                </Link>
              </Menu.Item>
              <Menu.Item
                icon={<FileOutlined />}
                key="/manager/to-do-task"
                className="ipsuite-menu-item"
              >
                <Link to="/manager/to-do-task">
                  <span>Todo Task</span>
                </Link>
              </Menu.Item>
              <Menu.Item
                icon={<BellOutlined />}
                key="/manager/notification"
                className="ipsuite-menu-item"
              >
                <Link to="/manager/notification">
                  <span>Notification</span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key="/manager/notes"
                className="ipsuite-menu-item"
                icon={<FileTextOutlined />}
              >
                <Link to="/manager/notes">
                  <span>Notes</span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key="/manager/workflow"
                className="ipsuite-menu-item"
                icon={<HddOutlined />}
              >
                <Link to="/manager/workflow">
                  <span>Workflow</span>
                </Link>
              </Menu.Item>
              {/* <Menu.Item
                icon={
                  <GlobalOutlined
                    style={{
                      color:
                        this.state.selectedKeys[0] === "/manager/whats-new"
                          ? "#fff"
                          : "#bebebe",
                    }}
                  />
                }
                key="/manager/whats-new"
                className="ipsuite-menu-item"
                onClick={() => {
                  this.changeStateValue_L1(
                    "drawersVisibility",
                    "whatsNew",
                    true,
                  );
                  document.cookie = "whatsNewManager=false";
                }}
              >
                <Link to="#">
                  <Badge
                    dot={this.state.collapsed}
                    count={
                      document.cookie
                        .split("; ")
                        .find((row) => row.startsWith("whatsNewManager"))
                        ? "0"
                        : "New"
                    }
                    style={{
                      top: `${this.state.collapsed ? "10px" : "7px"}`,
                      right: `${this.state.collapsed ? "-10px" : "-30px"}`,
                    }}
                  >
                    <span
                      style={{
                        color:
                          this.state.selectedKeys[0] === "/manager/whats-new"
                            ? "#fff"
                            : "#bebebe",
                      }}
                    >
                      What's New
                    </span>
                  </Badge>
                </Link>
              </Menu.Item> */}

              <Menu.Item
                key="/manager/faqs"
                className="ipsuite-menu-item"
                icon={<QuestionCircleOutlined />}
              >
                <Link to="/manager/faqs">
                  <span>FAQs</span>
                </Link>
              </Menu.Item>

              <SubMenu
                icon={<SmileOutlined />}
                key="feedbacks"
                className="ipsuite-submenu-item"
                title={
                  <span>
                    <span>Feedback</span>
                  </span>
                }
              >
                <Menu.Item
                  key="customer-satisfaction"
                  onClick={() =>
                    this.changeStateValue_L1(
                      "modalsVisibility",
                      "CSSurvey",
                      true,
                    )
                  }
                >
                  Customer Satisfaction
                </Menu.Item>
                <Menu.Item
                  key="suggestions"
                  onClick={() =>
                    this.changeStateValue_L1(
                      "modalsVisibility",
                      "suggestions",
                      true,
                    )
                  }
                >
                  Suggestions
                </Menu.Item>
                <Menu.Item
                  key="complaints"
                  onClick={() =>
                    this.changeStateValue_L1(
                      "modalsVisibility",
                      "complaints",
                      true,
                    )
                  }
                >
                  Complaints
                </Menu.Item>
                <Menu.Item
                  key="queries-survey"
                  onClick={() =>
                    this.changeStateValue_L1(
                      "modalsVisibility",
                      "queriesSurvey",
                      true,
                    )
                  }
                >
                  Queries
                </Menu.Item>
              </SubMenu>
              <Menu.Item
                icon={<LogoutOutlined />}
                key="logout"
                className="ipsuite-menu-item"
                onClick={this.props.logOut}
              >
                <span>Log out</span>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout style={{ background: "white" }}>
            {this.props.managerAlert.length > 0 && (
              <Alert
                type="error"
                message={
                  <div
                    style={{ textAlign: "center" }}
                    dangerouslySetInnerHTML={{
                      __html: this.props.managerAlert,
                    }}
                  ></div>
                }
              />
            )}
            <Route path="/manager/trademark" component={TrademarkLayout} />
            <Route path="/manager/patent" component={PatentLayout} />
            <Route path="/manager/client-master" component={ClientMaster} />
            <Route path="/manager/profile" component={ProfileDashboard} />
            <Route path="/manager/report" component={Report} />
            <Route
              path="/manager/client-email-report"
              component={ClientEmailCustomReport}
            />
            <Route path="/manager/to-do-task" component={TodoTask} />
            <Route path="/manager/notification" component={Notification} />
            <Route path="/manager/email-template" component={EmailTemplate} />
            <Route path="/manager/email-dashboard" component={EmailDashboard} />
            {/* <Route path="/manager/email-management" component={EmailManagement}/> */}
            <Route
              path="/manager/email-preferences"
              component={EmailPreferences}
            />

            <Route path="/manager/email-management" component={ManageEmails} />
            <Route path="/manager/exam-report" component={ExamReport} />

            <Route path="/manager/workflow" component={Workflow} />
            <Route path="/manager/faqs" component={Faqs} />
            <Route path="/manager/notes" component={Notes} />
            <Route path="/manager/calendar" component={Calendar} />
          </Layout>
        </Layout>

        <UploadProfileLogo
          visible={this.state.modalsVisibility.uploadLogo}
          onCloseModal={() =>
            this.changeStateValue_L1("modalsVisibility", "uploadLogo", false)
          }
        />
        {/*WhatsNew*/}
        <WhatsNew
          visible={this.state.drawersVisibility.whatsNew}
          onCloseDrawer={() =>
            this.changeStateValue_L1("drawersVisibility", "whatsNew", false)
          }
          collapsed={this.state.collapsed}
        />

        {/* TM-Watch customer satisfaction survey. */}
        {/* <SurveyModal
        title="customerSatisfactionManager"
        src="https://s.surveyplanet.com/LS0muhkyH"
        visible={this.state.modalsVisibility.CSSurvey}
        maskClosable={false}
        onCancel={() => {
          document.cookie = `Manager_CSSurvey=;max-age=${60 * 60 * 24 * 20}`;
          this.changeStateValue_L1("modalsVisibility", "CSSurvey", false);
        }}
      /> */}

        {/* Suggestions survey. */}
        {/* <SurveyModal
        title="suggestions"
        src="https://s.surveyplanet.com/1eI4bBov0"
        visible={this.state.modalsVisibility.suggestions}
        maskClosable={true}
        onCancel={() =>
          this.changeStateValue_L1("modalsVisibility", "suggestions", false)
        }
      /> */}

        {/* Complaints survey */}
        {/* <SurveyModal
        title="complaints"
        src="https://s.surveyplanet.com/7597Miojp"
        visible={this.state.modalsVisibility.complaints}
        maskClosable={true}
        onCancel={() =>
          this.changeStateValue_L1("modalsVisibility", "complaints", false)
        }
      /> */}

        {/* Queries survey */}
        {/* <SurveyModal
        title="queriesSurvey"
        src="https://s.surveyplanet.com/JlqHxcfFh"
        visible={this.state.modalsVisibility.queriesSurvey}
        maskClosable={true}
        onCancel={() =>
          this.changeStateValue_L1("modalsVisibility", "queriesSurvey", false)
        }
      /> */}

        {/* Manager Update */}
        {/* <ManagerUpdate
        visible={this.state.modalsVisibility.managerUpdate}
        onCloseModal={() =>
          this.changeStateValue_L1("modalsVisibility", "managerUpdate", false)
        }
      /> */}
      </>
    );
  }
}

const mapDispatchToProps = {
  getUserSubscriptions,
  getProfile,
  logOut,
  getAlertMessage,
};

function mapStateToProps(state) {
  const { userSubscriptions, userSubscriptionStatus } = state.userSubscriptions;
  const { userProfile, userProfileStatus } = state.profile;
  return deepFreeze({
    userSubscriptions,
    userSubscriptionStatus,
    userProfile,
    userProfileStatus,
    managerAlert: state.alertMessages.manager,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
