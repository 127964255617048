import React from "react";
import { UploadOutlined, UserOutlined } from "@ant-design/icons";
import {
	Row,
	Col,
	Modal,
	Button,
	Avatar,
	Upload,
	message,
} from "antd";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";

const CommonUploadImage = (props) => {
	const {
		beforeUploadChangeState,
		uploadConfirm,
		imagePreviewUrl,
		section1,
		section2,
		title = "Upload Logo",
		visible = false,
		onCloseModal,
		loading = false,
        fileSize = 2,
	} = props;

	const beforeUpload = (file) => {
		const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
		if (!isJpgOrPng) {
			message.error("You can only upload JPG/PNG file!");
		}
		const isSizeInLimit = file.size / (1024 * 1024) < fileSize;
		if (!isSizeInLimit) {
			message.error(`Image must smaller than ${fileSize}MB!`);
		}
		if (isJpgOrPng && isSizeInLimit) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				setTimeout(() => {
                    beforeUploadChangeState({
                        file,
                        imagePreviewUrl: reader.result,
                        section1: false,
                        section2: true,
                    });
                }, 0);
			};
		}
		return false;
	};

	const uploadConfirmChild = () => {
		uploadConfirm();
	};

	return (
		<div>
			<Modal
				title={title}
				open={visible}
				onCancel={onCloseModal}
				footer={false}>
				<div>
					<Row gutter={16} className={section1 ? "" : "display_none"}>
                        <Col span={24} className="alignC">
                            <Avatar
                                shape="square"
                                size={200}
                                className="avatar-logo"
                                style={{ border: "1px solid #e7e7e7" }}
                                >
                                {checkValue(imagePreviewUrl) ? (
                                    <img
                                    src={imagePreviewUrl}
                                    alt="avatar"
                                    style={{ width: "100%" }}
                                    />
                                ) : (
                                    <UserOutlined />
                                )}
                            </Avatar>
                        </Col>
						<Col span={24} className="alignC m-top-10">
							<Upload beforeUpload={beforeUpload} showUploadList={false}>
								<Button>
									<UploadOutlined />
									{checkValue(imagePreviewUrl) ? "Change Logo" : "Upload Logo"}
								</Button>
							</Upload>
						</Col>
					</Row>
					<Row gutter={16} className={section2 ? "" : "display_none"}>
						<Col span={24} className="alignC">
							<Avatar
								shape="square"
								size={200}
								className="avatar-logo"
								style={{ border: "#e7e7e7" }}>
								{imagePreviewUrl ? (
									<img
										src={imagePreviewUrl}
										alt="avatar"
										style={{ width: "100%" }}
									/>
								) : (
									""
								)}
							</Avatar>
						</Col>
						<Col span={24} className="alignC">
							<div>
								<ul className="report-footer">
									<li>
										<Upload beforeUpload={beforeUpload} showUploadList={false}>
											<Button danger>
												<UploadOutlined />
												Re-Upload
											</Button>
										</Upload>
									</li>
									<li>
										<Button
											type="primary"
											loading={loading}
											onClick={() => uploadConfirmChild()}>
											Confirm
										</Button>
									</li>
									<li>
										<Button type="default" onClick={onCloseModal}>
											Close
										</Button>
									</li>
								</ul>
							</div>
						</Col>
					</Row>
				</div>
			</Modal>
		</div>
	);
};

export default CommonUploadImage;
