//--------LIBRARIES--------
import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

//--------COMMON COMPONENTS--------
import Subscriptions from "../components/Subscriptions";

//--------MANAGER COMPONENTS--------
import Manager from "../components/managerComponents/Manager";

//--------SEARCH COMPONENTS--------
import Search from "../components/searchComponents/Search";

//--------WATCH COMPONENTS--------
import WatchDashboard from "../components/Watch/Layout/Main";

import NoMatch from "../components/commonComponents/NoMatch";

// //--------STYLESHEETS--------
// import "antd/dist/antd.css";

import "../stylesheets/App.css";
import ForgotPassword from "../components/passwordResetComponents/ForgotPassword";
import ResetPassword from "../components/passwordResetComponents/ResetPassword";

import SignIn from "../components/signInComponents/SignIn";
import AuthRecovery from "../components/signInComponents/AuthRecovery";
import SetUpAuth from "../components/signInComponents/SetUpAuth";
import RecoveryCodes from "../components/signInComponents/RecoveryCodes";

function App() {
  localStorage.removeItem("tnc_accepted", null);
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("MikeLegal");

  useEffect(() => {
    const path = location.pathname;

    if (path.startsWith("/search")) {
      setPageTitle("TM Search");
    } else if (path.startsWith("/watch")) {
      setPageTitle("TM Watch");
    } else if (path.startsWith("/manager")) {
      setPageTitle("TM Manager");
    } else {
      setPageTitle("MikeLegal");
    }
  }, [location.pathname]);

  return (
    <div className="App">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Switch>
        <Route exact path="/" component={Subscriptions} />
        <Route exact path="/sign-in" component={SignIn} />
        <Route exact path="/auth" component={AuthRecovery} />
        <Route exact path="/setup-auth" component={SetUpAuth} />
        <Route exact path="/recovery-codes" component={RecoveryCodes} />
        <Route exact path="/forgot" component={ForgotPassword} />
        <Route exact path="/reset/password" component={ResetPassword} />
        <Route path="/manager" component={Manager} />
        <Route path="/watch" component={WatchDashboard} />
        <Route path="/search" component={Search} />
        <Route component={NoMatch} />
      </Switch>
    </div>
  );
}

export default App;
