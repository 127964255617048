//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";

import {
  DownOutlined,
  InfoCircleFilled,
  MinusSquareFilled,
  PieChartOutlined,
  PlusSquareFilled,
  QuestionCircleFilled,
  StopOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

import {
  Dropdown,
  Button,
  Menu,
  Input,
  Pagination,
  Radio,
  Checkbox,
  Empty,
  Drawer,
  Tooltip,
  Badge,
  notification,
  Alert,
  Spin,
  Modal,
  InputNumber,
  Row,
  Col,
  Space,
  Image,
  Typography,
} from "antd";
import deepFreeze from "deep-freeze";
import { debounce } from "lodash";
import Axios from "axios";
import { FixedSizeList } from "react-window";
import introJs from "intro.js";

//--------IP-SUITE COMMON COMPONENTS--------
import GeneralMarkCard from "../../commonComponents/generalCards/GeneralMarkCard";
import ResultsAnalysis from "../../commonComponents/subComponents/ResultsAnalysis";
import DateRangePicker from "../../../functions/commonFunctions/dateRangePicker";

//--------IP-SUITE CONSTANTS--------
import {
  SUCCESS,
  LOADING,
  PDF,
  EXCEL,
  ERROR,
  BI_EXCEL,
} from "../../../constants/commonConstants/generalConstants";

//--------IP-SUITE COMMON ACTIONS--------
import {
  TPAction,
  TPSAction,
  logOut,
} from "../../../redux/actions/commonActions/actions";

//--------IP-SUITE COMMON GENERAL FUNCTIONS--------
// import { changeStateValue_L1 } from '../../../functions/commonFunctions/generalFunctions';

//--------IP-SUITE COMMON CHECK VALUE FUNCTIONS--------
import { checkNA } from "../../../functions/commonFunctions/checkValueFunctions";

//--------SEARCH ROOT CONFIG--------
import { SRCH_RT_URL } from "../../../configs/rootConfigs";

//--------SEARCH SUB-COMPONENTS--------
import TrademarksAlgosInfo from "../subComponents/TrademarksAlgosInfo";

//--------SEARCH COMMON ACTIONS--------
import {
  getSearchResults,
  postReport,
  handleSearchInfoDispatch,
  getImageSearchResults,
  fetchSearchAnalysisNew,
} from "../../../redux/actions/searchActions/commonActions";

//--------SEARCH ACTION TYPES--------
import {
  TS_SELECT_VIEW,
  CHANGE_SEARCH_RESULTS_TAB,
  TS_SET_SEARCHES_CANCEL,
  TS_SET_FILTERS_CANCEL,
  TS_GET_SEARCH_FILTERS,
  TS_SET_REMINDER_STAMP,
  TS_GET_REPORT_AVAILABILITY,
  TS_SET_STATE,
  TS_SET_DEFAULT_STATE,
} from "../../../redux/actions/searchActions/types";

//--------SEARCH STYLESHEETS--------
import "../../../stylesheets/searchStyleSheets/search.css";

//--------TRADEMARKS SEARCH ACTIONS--------
import {
  getTrademarkReportStatus,
  patchTrademarkReport,
} from "../../../redux/actions/searchActions/trademarksSearchActions";

//--------TRADEMARKS SEARCH CONSTANTS--------
import {
  TS_trademarksSearchState,
  TS_selectedFilters,
  TS_singlewordSearch,
  TS_multiwordSearch,
} from "../../../constants/searchConstants/trademarksSearchConstants";
import { refreshPeriod } from "../../../constants/searchConstants/generalConstants";

// import {SearchFilterSearchBasic}
import SearchFilterSearchBasic from "./../../commonComponents/FilterComponents/SearchFilterSearchBasic";
import SelectContainer from "../filterContainer/SelectContainer";
import DateFilterContainer from "../filterContainer/DateFilterContainer";
import {
  changeSearchDashboardSearchType,
  changeSearchDashboardSearchValue,
  getSearchAllFilters,
  resetAllSearchFilter,
  updateTrademarkSearchFilterApplied,
} from "../../../redux/actions/searchActions/searchFiltersActions";
import NewResultsAnalysis from "../../commonComponents/subComponents/NewResultAnalysis";
import HighlightPrefix from "../subComponents/PrefixHighlight";
import HighlightSuffix from "../subComponents/SuffixHighlight";

/**
 * This function will help in resetting this component's state
 * to it's initial values.
 */
export function resetTrademarksSearchState() {
  this.props.TPAction(TS_SET_DEFAULT_STATE, {});
}

class TrademarksSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...TS_trademarksSearchState,
      range1: null,
      range2: null,
      open: null,
      wildcardSearch: this.props["Wildcard"].searchParameters.search_query,
      booleanSearch: this.props["Boolean"].searchParameters.search_query,
      page: 10,
    };

    this.changeStateValue_L1 = this.changeStateValue_L1.bind(this);
    // this.changeStateValue_L2 = changeStateValue_L2.bind(this);

    this.changeStateValue_L1_Debounced = debounce(
      this.changeStateValue_L1,
      400,
    );

    this.handleConfirmReport_Debounced = debounce(
      this.handleConfirmReport,
      400,
    );
    this.handleGetFilters_Debounced = debounce(this.handleGetFilters, 2000);
    this.handleGetSingleFilter = this.handleGetSingleFilter.bind(this);
    resetTrademarksSearchState = resetTrademarksSearchState.bind(this);
    this.countSelections = this.countSelections.bind(this);
    this.setDate1 = this.setDate1.bind(this);
    this.setDate2 = this.setDate2.bind(this);
  }

  getSearchLogIds = () => {
    let search_log_ids = [];

    if (this.props.multiwordTrademark) {
      TS_multiwordSearch.map(
        (e) =>
          (search_log_ids = [...search_log_ids, this.props[e].searchLogID]),
      );
    } else {
      TS_singlewordSearch.map(
        (e) =>
          (search_log_ids = [...search_log_ids, this.props[e].searchLogID]),
      );
    }
    search_log_ids = `${search_log_ids.filter((e) => e !== null)}`;
    return search_log_ids;
  };

  async handleGetSingleFilter(body) {
    const search_log_ids = this.getSearchLogIds();

    body = {
      ...body,
      flash_flag: this.props.trademarksSearchAlgo === "Flash",
    };
    if (this.props.trademarksSearchAlgo === "Flash") {
      body = {
        ...body,
        search_log_ids,
      };
      if (search_log_ids?.length > 0) this.props.getSearchAllFilters(body);
    } else {
      const search_log_id =
        this.props[this.props.trademarksSearchAlgo]?.searchLogID;
      body = { ...body, search_log_id };
      if (search_log_id !== null) this.props.getSearchAllFilters(body);
    }
  }

  changeStateValue_L1(stateKey, subStateKey, value) {
    this.props.TPAction(TS_SET_STATE, {
      [stateKey]: {
        ...this.props.searchState[stateKey],
        [subStateKey]: value,
      },
    });
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.history.push(this.props.searchPath);
      let trademarksSearchTour;
      if (window.location.pathname === this.props.searchPath) {
        try {
          trademarksSearchTour = document.cookie
            .split("; ")
            .find((row) => row.startsWith("trademarksSearchTour"));
        } catch (err) {
          introJs().start();
          document.cookie = "trademarksSearchTour=false";
        }
      }
    } else {
      this.props.logOut();
    }
  }

  setDate1(val) {
    this.setState({ range1: val });
  }

  setDate2(val) {
    this.setState({ range2: val });
  }

  generateAxiosSources = () => {
    //generate cancellation token
    const axiosSources = {};

    TS_multiwordSearch.forEach((key) => {
      const cancelToken = Axios.CancelToken;
      axiosSources[`${key.toLowerCase()}CancelToken`] = cancelToken;
      axiosSources[`${key.toLowerCase()}AxiosSource`] = cancelToken.source();
    });
    return axiosSources;
  };

  searchAlgos = async (filters, filter_flag, refresh = false) => {
    Object.keys(this.props.axiosCancelSearches).map(function () {
      return "Request is cancelled!!!";
    });

    if (this.props.axiosCancelFilters) {
      this.props.axiosCancelFilters("Filters Request Cancelled");
    }

    const axiosSources = this.generateAxiosSources();

    // Generate the cancellation object
    const cancellationTokens = TS_multiwordSearch.reduce((acc, key) => {
      acc[key] = axiosSources[`${key.toLowerCase()}AxiosSource`]?.cancel;
      return acc;
    }, {});
    //{
    //   Fuzzy: fuzzyAxiosSource.cancel,
    //   Wildcard: wildcardAxiosSource.cancel,
    //   Standard: standardAxiosSource.cancel,....
    // }
    this.props.TPAction(TS_SET_SEARCHES_CANCEL, cancellationTokens);
    let flashAlgos = [];

    if (this.props.multiwordTrademark) {
      flashAlgos = TS_multiwordSearch.filter((key) => this.props[key]).map(
        (key) => ({
          key,
          token: axiosSources[`${key.toLowerCase()}AxiosSource`]?.token,
        }),
      );
    } else {
      flashAlgos = TS_singlewordSearch.filter((key) => this.props[key]).map(
        (key) => ({
          key,
          token: axiosSources[`${key.toLowerCase()}AxiosSource`]?.token,
        }),
      );
    }
    //generate algos config to call search result in the form  [{ key: "Boolean", token: booleanAxiosSource.token }],
    // flashAlgos = [
    //   { key: "Boolean", token: booleanAxiosSource.token },
    //   { key: "Initials", token: initialsAxiosSource.token },...
    // ]

    switch (this.props.trademarksSearchAlgo) {
      case "Flash":
        if (refresh) {
          const {
            trademarksSearchAlgo,
            activeTab,
            userTrademark,
            multiwordTrademark,
          } = this.props;

          this.props.handleSearchInfoDispatch({
            trademarksSearchAlgo,
            activeTab,
            userTrademark,
            multiwordTrademark,
            axiosCancelSearches: cancellationTokens,
            searchCounter: multiwordTrademark
              ? TS_multiwordSearch.length
              : TS_singlewordSearch.length,
            reminderStamp: new Date().getTime() + refreshPeriod,
            searchStamp: new Date().getTime() + refreshPeriod,
          });

          flashAlgos.forEach(({ key, token }) => {
            this.props.getSearchResults(
              {
                ...this.props[key].searchParameters,
                search_log_id: null,
                operation: "search",
                flash_flag: false,
                page: 1,
                filter_flag,
                ...filters,
                ...(key === "Prefix" || key === "Suffix"
                  ? {
                      fuzzy_depth: this.state.selectedFilters.queries[key],
                    }
                  : {}),
              },
              key,
              token,
              true,
            );
          });
        } else {
          flashAlgos.forEach(({ key, token }) => {
            this.props.getSearchResults(
              {
                ...this.props[key].searchParameters,
                search_log_id: this.props[key].searchLogID,
                operation: "search",
                flash_flag: true,
                page: 1,
                filter_flag,
                ...filters,
                ...(key === "Prefix" || key === "Suffix"
                  ? {
                      fuzzy_depth: this.state.selectedFilters.queries[key],
                    }
                  : {}),
              },
              key,
              token,
            );
          });
        }

        break;

      case "Boolean":
      case "Initials":
      case "Fuzzy":
      case "Wildcard":
      case "Contains":
      case "Phonetic":
      case "Prefix":
      case "Suffix":
        // Find the matching algorithm from the flashAlgos array
        const algo = flashAlgos.find(
          ({ key }) => key === this.props.trademarksSearchAlgo,
        );

        if (refresh) {
          if (algo) {
            const {
              trademarksSearchAlgo,
              activeTab,
              userTrademark,
              multiwordTrademark,
            } = this.props;

            this.props.handleSearchInfoDispatch({
              trademarksSearchAlgo,
              activeTab,
              userTrademark,
              multiwordTrademark,
              axiosCancelSearches: {
                [algo.key]: cancellationTokens[algo.key],
              },
              searchCounter: 1,
              reminderStamp: new Date().getTime() + refreshPeriod,
              searchStamp: new Date().getTime() + refreshPeriod,
            });

            this.props.getSearchResults(
              {
                ...this.props[algo.key].searchParameters,
                search_log_id: null,
                operation: "search",
                page: 1,
                filter_flag,
                ...filters,
                ...(algo.key === "Prefix" || algo.key === "Suffix"
                  ? {
                      fuzzy_depth: this.state.selectedFilters.queries[algo.key],
                    }
                  : {}),
              },
              algo.key,
              algo.token,
              true,
            );
          }
        } else {
          if (algo) {
            this.props.getSearchResults(
              {
                ...this.props[algo.key].searchParameters,
                search_log_id: this.props[algo.key].searchLogID,
                operation: "search",
                page: 1,
                filter_flag,
                ...filters,
                ...(algo.key === "Prefix" || algo.key === "Suffix"
                  ? {
                      fuzzy_depth: this.state.selectedFilters.queries[algo.key],
                    }
                  : {}),
              },
              algo.key,
              algo.token,
            );
          }
        }
        break;

      case "GOODS_SERVICE":
        if (refresh) {
          this.props.handleSearchInfoDispatch({
            trademarksSearchAlgo,
            activeTab,
            userTrademark,
            multiwordTrademark,
            axiosCancelSearches: {},
            searchCounter: 1,
            reminderStamp: new Date().getTime() + refreshPeriod,
            searchStamp: new Date().getTime() + refreshPeriod,
          });
          this.props.getSearchResults(
            {
              ...this.props["GOODS_SERVICE"].searchParameters,
              operation: "search",
              flash_flag: false,
              page: 1,
              search_log_id: null,
            },
            "GOODS_SERVICE",
            null,
            true,
          );
        } else {
          this.props.getSearchResults(
            {
              ...this.props["GOODS_SERVICE"].searchParameters,
              search_log_id: this.props["GOODS_SERVICE"].searchLogID,
              operation: "search",
              page: 1,
              filter_flag,
              ...filters,
            },
            "GOODS_SERVICE",
            null,
          );
        }

        break;

      default:
        break;
    }
  };

  async handleFilters(reset = false) {
    if (this.props.searchCounter < 0) {
      let filter_flag = false;

      const { searchFilters } = this.props;

      const classes = searchFilters?.classConfig?.onlyValues;
      const deselectClasses = searchFilters?.classConfig?.onlyExcludedValues;
      const statuses = searchFilters?.statusConfig?.onlyValues;
      const deselectStatuses = searchFilters?.statusConfig?.onlyExcludedValues;

      const states = searchFilters?.stateConfig?.onlyValues;
      const deselectStates = searchFilters?.stateConfig?.onlyExcludedValues;

      const selectProprietors = searchFilters?.proprietorConfig?.onlyValues;
      const deselectProprietors =
        searchFilters?.proprietorConfig?.onlyExcludedValues;
      const types = searchFilters?.typeConfig?.onlyValues;
      const deselectTypes = searchFilters?.typeConfig?.onlyExcludedValues;

      const doa = searchFilters?.dateOfApplicationConfig?.value?.dateString;
      const doaUnStructured = searchFilters?.dateOfApplicationConfig?.filter_is_unstructured_application_date;
      const dou = searchFilters?.dateOfUsageConfig?.value?.dateString;
      const douProposed = searchFilters?.dateOfUsageConfig?.dateOfUsageProposed;
      const douUnStructured = searchFilters?.dateOfUsageConfig?.filter_is_unstructured_date_of_usage;
      const filter_active_status = searchFilters?.statusConfig?.activeStatus;
      const filter_inactive_status =
        searchFilters?.statusConfig?.inActiveStatus;
      const applicationSearchValue = searchFilters?.applicationSearchValue;
      const descriptionSearchValue = searchFilters?.descriptionSearchValue;

      let filters = {
        filter_trademark_type: null,
        filter_proprietor_select: null,
        filter_proprietor_deselect: null,
        filter_application_date: null,
        filter_date_of_usage: null,
        filter_contains_term: null,
        filter_date_of_usage_proposed: null,
        filter_class_select: null,
        filter_status_select: null,
        filter_state_select: null,
        filter_type_select: null,
        filter_class_deselect: null,
        filter_status_deselect: null,
        filter_state_deselect: null,
        filter_type_deselect: null,
        filter_g_and_s_contains_term: null,
        filter_active_status: null,
        filter_inactive_status: null,
        filter_is_unstructured_application_date: null,
        filter_is_unstructured_date_of_usage: null
      };

      if (!reset) {
        if (
          classes.length > 0 ||
          deselectClasses.length > 0 ||
          statuses.length > 0 ||
          deselectStatuses.length > 0 ||
          states.length > 0 ||
          deselectStates.length > 0 ||
          types.length > 0 ||
          selectProprietors.length > 0 ||
          deselectProprietors.length > 0 ||
          doa ||
          dou ||
          (applicationSearchValue && applicationSearchValue.length > 0) ||
          (descriptionSearchValue && descriptionSearchValue.length > 0) ||
          douProposed ||
          filter_active_status ||
          filter_inactive_status ||
          doaUnStructured ||
          douUnStructured
        ) {
          filter_flag = true;
        }

        filters = {
          ...filters,
          filter_class_select: classes,
          filter_status_select: statuses,
          filter_state_select: states,
          filter_type_select: types,

          filter_class_deselect: deselectClasses,
          filter_status_deselect: deselectStatuses,
          filter_state_deselect: deselectStates,
          filter_type_deselect: deselectTypes,

          filter_proprietor_select: selectProprietors,
          filter_proprietor_deselect: deselectProprietors,
          filter_g_and_s_contains_term: "",

          filter_active_status,
          filter_inactive_status,

          filter_application_date: doa,
          filter_date_of_usage: dou,

          filter_contains_term: applicationSearchValue.toLowerCase().trim(),
          filter_g_and_s_contains_term: descriptionSearchValue,
          filter_date_of_usage_proposed: douProposed,
          filter_is_unstructured_application_date: doaUnStructured,
          filter_is_unstructured_date_of_usage: douUnStructured
        };
        this.props.updateTrademarkSearchFilterApplied(true);
      } else {
        this.props.resetAllSearchFilter();
        this.handleGetFilters()
        this.setState({ range1: null, range2: null });
        this.props.TPAction(TS_SET_STATE, {
          selectedFilters: TS_selectedFilters,
        });
        filters = {};
      }

      this.searchAlgos(filters, filter_flag, false);
    } else {
      notification["warning"]({
        message: "Wait For Filters",
        description: "Filters are being fetched. Kindly, wait for them.",
      });
    }
  }

  isFilterSelected = () => {
    const { searchFilters } = this.props;

    const classes = searchFilters?.classConfig?.onlyValues;
    const deselectClasses = searchFilters?.classConfig?.onlyExcludedValues;
    const statuses = searchFilters?.statusConfig?.onlyValues;
    const deselectStatuses = searchFilters?.statusConfig?.onlyExcludedValues;

    const states = searchFilters?.stateConfig?.onlyValues;
    const deselectStates = searchFilters?.stateConfig?.onlyExcludedValues;

    const selectProprietors = searchFilters?.proprietorConfig?.onlyValues;
    const deselectProprietors =
      searchFilters?.proprietorConfig?.onlyExcludedValues;
    const types = searchFilters?.typeConfig?.onlyValues;
    const deselectTypes = searchFilters?.typeConfig?.onlyExcludedValues;

    const doa = searchFilters?.dateOfApplicationConfig?.value?.dateString;
    const doaUnStructured = searchFilters?.dateOfApplicationConfig?.filter_is_unstructured_application_date;
    const dou = searchFilters?.dateOfUsageConfig?.value?.dateString;
    const douProposed = searchFilters?.dateOfUsageConfig?.dateOfUsageProposed;
    const douUnStructured = searchFilters?.dateOfUsageConfig?.filter_is_unstructured_date_of_usage;
    const filter_active_status = searchFilters?.statusConfig?.activeStatus;
    const filter_inactive_status = searchFilters?.statusConfig?.inActiveStatus;
    const applicationSearchValue = searchFilters?.applicationSearchValue;
    const descriptionSearchValue = searchFilters?.descriptionSearchValue;
    if (
      classes.length > 0 ||
      deselectClasses.length > 0 ||
      statuses.length > 0 ||
      deselectStatuses.length > 0 ||
      states.length > 0 ||
      deselectStates.length > 0 ||
      types.length > 0 ||
      selectProprietors.length > 0 ||
      deselectProprietors.length > 0 ||
      doa ||
      dou ||
      (applicationSearchValue && applicationSearchValue.length > 0) ||
      (descriptionSearchValue && descriptionSearchValue.length > 0) ||
      douProposed ||
      filter_active_status ||
      filter_inactive_status ||
      doaUnStructured ||
      douUnStructured
    ) {
      return true;
    }
    return false;
  };
  async handleConfirmReport() {
    let response = null;

    switch (this.props.reportAvailabilityStatus) {
      case SUCCESS:
        this.changeStateValue_L1("drawersVisibility", "customReport", false);
        if (!this.props.reportAvailability.id) {
          response = await this.props.postReport({
            report_source: "MARK",
            report_type: this.props.searchState.customReportData.reportType,
            report_file:
              this.props.searchState.customReportData.reportFormat.length > 0
                ? true
                : false,
            report_file_types:
              this.props.searchState.customReportData.reportFormat,
            report_file_name:
              this.props.searchState.customReportData.reportName,
            mark_id: this.props.userTrademark.id,
            selected_mark_id_list:
              this.props.searchState.customReportData.applicationsIDs,
            ...this.props.searchState.customReportData,
          });

          if (response.status === SUCCESS) {
            if (
              this.props.searchState.customReportData.reportFormat.length > 0
            ) {
              notification["success"]({
                message: "Report Queued",
                description:
                  "Your Report is being generated. You can visit the Reports section to access the report.",
              });
            } else {
              notification["success"]({
                message: "Report Queued",
                description: "The report has been queued successfully.",
              });
            }
          }
        } else {
          if (this.props.reportAvailability.available) {
            response = await this.props.patchTrademarkReport(
              {
                report_source: "MARK",
                operation: this.props.searchState.customReportData.operation,
                report_type: this.props.searchState.customReportData.reportType,
                report_file:
                  this.props.searchState.customReportData.reportFormat.length >
                  0
                    ? true
                    : false,
                report_file_types:
                  this.props.searchState.customReportData.reportFormat,
                report_file_name:
                  this.props.searchState.customReportData.reportName,
                selected_mark_id_list:
                  this.props.searchState.customReportData.applicationsIDs,
                ...this.props.searchState.customReportData,
              },
              this.props.reportAvailability.id,
            );

            if (response.status === SUCCESS) {
              if (
                this.props.searchState.customReportData.reportFormat.length > 0
              ) {
                notification["success"]({
                  message: "Report Queued",
                  description:
                    "Your Report is being generated. You can visit the Reports section to access the report.",
                });
              } else {
                notification["success"]({
                  message: "Report Queued",
                  description: "The report has been queued successfully.",
                });
              }
            }
          } else {
            notification["warning"]({
              message: "Report Under Process",
              description:
                "The report is already under process. Kindly, wait for its completion",
            });
          }
        }
        break;

      case LOADING:
        break;

      default:
        break;
    }
  }

  async handleGetFilters() {
    this.setState({
      wildcardSearch: this.props["Wildcard"].searchParameters.search_query,
      booleanSearch: this.props["Boolean"].searchParameters.search_query,
    });
    let CancelToken = Axios.CancelToken;
    let axiosSource = CancelToken.source();

    this.props.TPAction(TS_SET_FILTERS_CANCEL, axiosSource.cancel);

    let search_log_id = [];
    let search_log_ids = [];

    if (this.props.trademarksSearchAlgo === "Flash") {
      search_log_ids = this.getSearchLogIds();
      // search_log_ids = `${search_log_ids.filter((e) => e !== null)}`;
    } else {
      search_log_id = this.props[this.props.activeTab].searchLogID;
    }

    let response = {};
    try {
      if (this.props.activeTab === "ImageSearch") {
        response = await Axios.get(`${SRCH_RT_URL}/trademarks/image`, {
          params: {
            operation: "filters",
            search_log_id,
          },
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });
      } else {
        let filterKeys = ["STATUS", "STATE", "CLASS", "PROPRIETOR", "TYPE"];
        filterKeys.map((item) => {
          let body = {
            // flash_flag: this.props.trademarksSearchAlgo === "Flash",
            // flash_flag: false,
            // search_log_ids: search_log_ids,
            // search_log_id: this.props[this.props.activeTab].searchLogID,
            required_filter: item,
            page: 1,
            search_term: "",
          };

          this.handleGetSingleFilter(body);
        });
      }

      // this.props.TPSAction(TS_GET_SEARCH_FILTERS, response?.data, SUCCESS);
    } catch (err) {
      if (Axios.isCancel(err)) {
        return err;
      } else {
        // notification["error"]({
        //   message: "Error! Search Filters",
        //   description:
        //     "We are sorry that something unexpected has happened. Please, search again. Meanwhile, we are looking into it.",
        // });
        // this.props.TPSAction(TS_GET_SEARCH_FILTERS, null, ERROR);
      }
    }
  }

  handleGetAnalysis = async () => {
    let CancelToken = Axios.CancelToken;
    let axiosSource = CancelToken.source();

    let filterKeys = ["STATUS", "STATE", "CLASS", "PROPRIETOR", "TYPE"];
    filterKeys.map(async (item) => {
      const search_log_ids = this.getSearchLogIds();

      let body = {
        flash_flag: this.props.trademarksSearchAlgo === "Flash",
        search_log_ids: search_log_ids,
        search_log_id: this.props[this.props.activeTab].searchLogID,
        required_filter: item,
        page: 1,
        searchTab: this.props.trademarksSearchAlgo,
      };

      if (this.props.trademarksSearchAlgo === "Flash") {
        body = {
          ...body,
          search_log_ids,
        };
        if (search_log_ids?.length > 0)
          await this.props.fetchSearchAnalysisNew(item, body, axiosSource);
      } else {
        const search_log_id =
          this.props[this.props.trademarksSearchAlgo].searchLogID;

        body = { ...body, search_log_id };
        if (search_log_id !== null)
          await this.props.fetchSearchAnalysisNew(item, body, axiosSource);
      }
    });
  };

  handleSearchRefresh(refresh) {
    if (refresh === true) {
      this.searchAlgos((refresh = true));
    } else {
      this.props.TPAction(
        TS_SET_REMINDER_STAMP,
        new Date().getTime() + refreshPeriod,
      );
    }
  }

  async handleQueryChange(query) {
    if (this.props.searchStamp > new Date().getTime()) {
      if (query) {
        const {
          trademarksSearchAlgo,
          activeTab,
          userTrademark,
          multiwordTrademark,
          searchCounter,
          reminderStamp,
          axiosCancelSearches,
          searchStamp,
        } = this.props;

        let CancelToken0 = Axios.CancelToken;
        let axiosSource0 = CancelToken0.source();

        let CancelToken1 = Axios.CancelToken;
        let axiosSource1 = CancelToken1.source();

        const filter_flag = this.props[activeTab].searchParameters.filter_flag;

        // if (searchCounter < 0 && this.props.filtersStatus !== LOADING) {
        //   if (this.props[activeTab].status === LOADING) {
        //     notification["warning"]({
        //       message: "Wait For Results",
        //       description: "Results are being fetched. Kindly, wait for them.",
        //     });
        //   } else {
        //     switch (activeTab) {
        //       case "Fuzzy":
        //         if (/^\d+$/.test(query)) {
        //           if (query > userTrademark.term.length || query < 2) {
        //             notification["warning"]({
        //               message: "Fuzz Depth Too Low or High",
        //               description:
        //                 "Your fuzz depth cannot be lower than 2 and higher than the length of the selected trademark.",
        //             });

        //             break;
        //           }

        //           this.props.handleSearchInfoDispatch({
        //             trademarksSearchAlgo,
        //             activeTab,
        //             userTrademark,
        //             multiwordTrademark,
        //             axiosCancelSearches: {
        //               ...axiosCancelSearches,
        //               [activeTab]: axiosSource0.cancel,
        //             },
        //             searchCounter: 1,
        //             reminderStamp,
        //             searchStamp,
        //           });

        //           await this.props.getSearchResults(
        //             {
        //               ...this.props[activeTab].searchParameters,
        //               operation: "search",
        //               filter_flag: false,
        //               page: 1,
        //               search_log_id: null,
        //               fuzzy_depth: query,
        //             },
        //             activeTab,
        //             axiosSource0.token,
        //             true
        //           );

        //           if (filter_flag) {
        //             this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
        //               ...axiosCancelSearches,
        //               [activeTab]: axiosSource1.cancel,
        //             });

        //             this.props.getSearchResults(
        //               {
        //                 ...this.props[activeTab].searchParameters,
        //                 operation: "search",
        //                 filter_flag,
        //                 page: 1,
        //                 fuzzy_depth: query,
        //               },
        //               activeTab,
        //               axiosSource1.token
        //             );
        //           }
        //         } else {
        //           notification["warning"]({
        //             message: "Not An Integer",
        //             description: "Fuzz depth can consist of only numbers.",
        //           });
        //         }
        //         break;

        //       case "Boolean":
        //         if (
        //           !(
        //             query.includes("[[&]]") ||
        //             query.includes("[[!]]") ||
        //             query.includes("[[|]]")
        //           )
        //         ) {
        //           notification["warning"]({
        //             message: "No Boolean Operator Used",
        //             description:
        //               "Your query must consist of any one of the three boolean operators( [[&]], [[|]], [[!]] ).",
        //           });
        //           break;
        //         }

        //         if (
        //           query
        //             .replace(/ {1,}/g, " ")
        //             .replace(/\[\[&\]\]/g, "")
        //             .replace(/\[\[\|\]\]/g, "")
        //             .replace(/\[\[!\]\]/g, "")
        //             .trim()
        //             .search(/[&|!'"()$%_^]/g) !== -1
        //         ) {
        //           notification["warning"]({
        //             message: "Invalid Boolean Characters",
        //             description:
        //               "Your query cannot have & ' \" ( ) $ %_^ | and ! apart from the boolean operators.",
        //           });
        //           break;
        //         }

        //         if (
        //           !query
        //             .replace(/ {1,}/g, " ")
        //             .replace(/\[\[&\]\]/g, " ")
        //             .replace(/\[\[\|\]\]/g, " ")
        //             .replace(/\[\[!\]\]/g, "")
        //             .trim()
        //             .includes(" ")
        //         ) {
        //           notification["warning"]({
        //             message: "Single Word Query",
        //             description: "Your query must have more than 1 word.",
        //           });
        //           break;
        //         }

        //         this.props.handleSearchInfoDispatch({
        //           trademarksSearchAlgo,
        //           activeTab,
        //           userTrademark,
        //           multiwordTrademark,
        //           axiosCancelSearches: {
        //             ...axiosCancelSearches,
        //             [activeTab]: axiosSource0.cancel,
        //           },
        //           searchCounter: 1,
        //           reminderStamp,
        //           searchStamp,
        //         });

        //         await this.props.getSearchResults(
        //           {
        //             ...this.props[activeTab].searchParameters,
        //             operation: "search",
        //             filter_flag: false,
        //             page: 1,
        //             search_log_id: null,
        //             query: query.replace(/ {1,}/g, " ").trim(),
        //           },
        //           activeTab,
        //           axiosSource0.token,
        //           true
        //         );

        //         if (filter_flag) {
        //           this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
        //             ...axiosCancelSearches,
        //             [activeTab]: axiosSource1.cancel,
        //           });

        //           this.props.getSearchResults(
        //             {
        //               ...this.props[activeTab].searchParameters,
        //               operation: "search",
        //               filter_flag,
        //               page: 1,
        //               query: query.replace(/ {1,}/g, " ").trim(),
        //             },
        //             activeTab,
        //             axiosSource1.token
        //           );
        //         }
        //         break;

        //       case "Wildcard":
        //         this.props.handleSearchInfoDispatch({
        //           trademarksSearchAlgo,
        //           activeTab,
        //           userTrademark,
        //           multiwordTrademark,
        //           axiosCancelSearches: {
        //             ...axiosCancelSearches,
        //             [activeTab]: axiosSource0.cancel,
        //           },
        //           searchCounter: 1,
        //           reminderStamp,
        //           searchStamp,
        //         });

        //         await this.props.getSearchResults(
        //           {
        //             ...this.props[activeTab].searchParameters,
        //             operation: "search",
        //             filter_flag: false,
        //             page: 1,
        //             search_log_id: null,
        //             query: query
        //               .replace(/[&|!'"()$%_^]/g, "")
        //               .replace(/ {1,}/g, " ")
        //               .trim(),
        //           },
        //           activeTab,
        //           axiosSource0.token,
        //           true
        //         );

        //         if (filter_flag) {
        //           this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
        //             ...axiosCancelSearches,
        //             [activeTab]: axiosSource1.cancel,
        //           });

        //           this.props.getSearchResults(
        //             {
        //               ...this.props[activeTab].searchParameters,
        //               operation: "search",
        //               filter_flag,
        //               page: 1,
        //               query: query
        //                 .replace(/[&|!'"()$%_^]/g, "")
        //                 .replace(/ {1,}/g, " ")
        //                 .trim(),
        //             },
        //             activeTab,
        //             axiosSource1.token
        //           );
        //         }
        //         break;

        //       case "Contains":
        //         this.props.handleSearchInfoDispatch({
        //           trademarksSearchAlgo,
        //           activeTab,
        //           userTrademark,
        //           multiwordTrademark,
        //           axiosCancelSearches: {
        //             ...axiosCancelSearches,
        //             [activeTab]: axiosSource0.cancel,
        //           },
        //           searchCounter: 1,
        //           reminderStamp,
        //           searchStamp,
        //         });

        //         await this.props.getSearchResults(
        //           {
        //             ...this.props[activeTab].searchParameters,
        //             operation: "search",
        //             filter_flag: false,
        //             page: 1,
        //             search_log_id: null,
        //             query: query
        //               .replace(/[&:|!'"()$%_^]/g, "")
        //               .replace(/ {1,}/g, " ")
        //               .trim(),
        //           },
        //           activeTab,
        //           axiosSource0.token,
        //           true
        //         );

        //         if (filter_flag) {
        //           this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
        //             ...axiosCancelSearches,
        //             [activeTab]: axiosSource1.cancel,
        //           });

        //           this.props.getSearchResults(
        //             {
        //               ...this.props[activeTab].searchParameters,
        //               operation: "search",
        //               filter_flag,
        //               page: 1,
        //               query: query.replace(/ {1,}/g, " ").trim(),
        //             },
        //             activeTab,
        //             axiosSource1.token
        //           );
        //         }
        //         break;

        //       case "Phonetic":
        //         this.props.handleSearchInfoDispatch({
        //           trademarksSearchAlgo,
        //           activeTab,
        //           userTrademark,
        //           multiwordTrademark,
        //           axiosCancelSearches: {
        //             ...axiosCancelSearches,
        //             [activeTab]: axiosSource0.cancel,
        //           },
        //           searchCounter: 1,
        //           reminderStamp,
        //           searchStamp,
        //         });

        //         await this.props.getSearchResults(
        //           {
        //             ...this.props[activeTab].searchParameters,
        //             operation: "search",
        //             filter_flag: false,
        //             page: 1,
        //             search_log_id: null,
        //             query: query
        //               .replace(/[^a-zA-Z ]/g, "")
        //               .replace(/ {1,}/g, " ")
        //               .trim(),
        //           },
        //           activeTab,
        //           axiosSource0.token,
        //           true
        //         );

        //         if (filter_flag) {
        //           this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
        //             ...axiosCancelSearches,
        //             [activeTab]: axiosSource1.cancel,
        //           });

        //           this.props.getSearchResults(
        //             {
        //               ...this.props[activeTab].searchParameters,
        //               operation: "search",
        //               filter_flag,
        //               page: 1,
        //               query: query
        //                 .replace(/[^a-zA-Z ]/g, "")
        //                 .replace(/ {1,}/g, " ")
        //                 .trim(),
        //             },
        //             activeTab,
        //             axiosSource1.token
        //           );
        //         }
        //         break;

        //       default:
        //         break;
        //     }
        //   }
        // }
        if (searchCounter < 0) {
          if (this.props[activeTab].status === LOADING) {
            notification["warning"]({
              message: "Wait For Results",
              description: "Results are being fetched. Kindly, wait for them.",
            });
          } else {
            switch (activeTab) {
              case "Prefix":
                if (/^\d+$/.test(query)) {
                  if (
                    Number(query) > userTrademark.term.length ||
                    Number(query) < 3
                  ) {
                    notification["warning"]({
                      message: "Prefix/Suffix Length Too Low or High",
                      description:
                        "Your prefix/suffix depth cannot be lower than 3 and higher than the length of the selected trademark.",
                    });

                    break;
                  }

                  this.props.handleSearchInfoDispatch({
                    trademarksSearchAlgo,
                    activeTab,
                    userTrademark,
                    multiwordTrademark,
                    axiosCancelSearches: {
                      ...axiosCancelSearches,
                      [activeTab]: axiosSource0.cancel,
                    },
                    searchCounter: 1,
                    reminderStamp,
                    searchStamp,
                  });

                  await this.props.getSearchResults(
                    {
                      ...this.props[activeTab].searchParameters,
                      operation: "search",
                      filter_flag: false,
                      page: 1,
                      search_log_id: null,
                      prefix_suffix_length: Number(query),
                    },
                    activeTab,
                    axiosSource0.token,
                    true,
                  );

                  if (filter_flag) {
                    this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
                      ...axiosCancelSearches,
                      [activeTab]: axiosSource1.cancel,
                    });

                    this.props.getSearchResults(
                      {
                        ...this.props[activeTab].searchParameters,
                        search_log_id:
                          this.props[this.props.activeTab].searchLogID,
                        operation: "search",
                        filter_flag,
                        page: 1,
                        prefix_suffix_length: Number(query),
                      },
                      activeTab,
                      axiosSource1.token,
                    );
                  }
                } else {
                  notification["warning"]({
                    message: "Not An Integer",
                    description:
                      "Prefix/Suffix length depth can consist of only numbers.",
                  });
                }
                break;
              case "Suffix":
                if (/^\d+$/.test(query)) {
                  if (
                    Number(query) > userTrademark.term.length ||
                    Number(query) < 3
                  ) {
                    notification["warning"]({
                      message: "Prefix/Suffix Length Too Low or High",
                      description:
                        "Your prefix/suffix depth cannot be lower than 3 and higher than the length of the selected trademark.",
                    });

                    break;
                  }

                  this.props.handleSearchInfoDispatch({
                    trademarksSearchAlgo,
                    activeTab,
                    userTrademark,
                    multiwordTrademark,
                    axiosCancelSearches: {
                      ...axiosCancelSearches,
                      [activeTab]: axiosSource0.cancel,
                    },
                    searchCounter: 1,
                    reminderStamp,
                    searchStamp,
                  });

                  await this.props.getSearchResults(
                    {
                      ...this.props[activeTab].searchParameters,
                      operation: "search",
                      filter_flag: false,
                      page: 1,
                      search_log_id: null,
                      prefix_suffix_length: Number(query),
                    },
                    activeTab,
                    axiosSource0.token,
                    true,
                  );

                  if (filter_flag) {
                    this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
                      ...axiosCancelSearches,
                      [activeTab]: axiosSource1.cancel,
                    });

                    this.props.getSearchResults(
                      {
                        ...this.props[activeTab].searchParameters,
                        search_log_id:
                          this.props[this.props.activeTab].searchLogID,
                        operation: "search",
                        filter_flag,
                        page: 1,
                        prefix_suffix_length: Number(query),
                      },
                      activeTab,
                      axiosSource1.token,
                    );
                  }
                } else {
                  notification["warning"]({
                    message: "Not An Integer",
                    description:
                      "Prefix/Suffix length depth can consist of only numbers.",
                  });
                }
                break;

              case "Boolean":
                if (
                  !(
                    query.includes("[[&]]") ||
                    query.includes("[[!]]") ||
                    query.includes("[[|]]")
                  )
                ) {
                  notification["warning"]({
                    message: "No Boolean Operator Used",
                    description:
                      "Your query must consist of any one of the three boolean operators( [[&]], [[|]], [[!]] ).",
                  });
                  break;
                }

                if (
                  query
                    .replace(/ {1,}/g, " ")
                    .replace(/\[\[&\]\]/g, "")
                    .replace(/\[\[\|\]\]/g, "")
                    .replace(/\[\[!\]\]/g, "")
                    .trim()
                    .search(/[&|!'"()$%_^]/g) !== -1
                ) {
                  notification["warning"]({
                    message: "Invalid Boolean Characters",
                    description:
                      "Your query cannot have & ' \" ( ) $ %_^ | and ! apart from the boolean operators.",
                  });
                  break;
                }

                if (
                  !query
                    .replace(/ {1,}/g, " ")
                    .replace(/\[\[&\]\]/g, " ")
                    .replace(/\[\[\|\]\]/g, " ")
                    .replace(/\[\[!\]\]/g, "")
                    .trim()
                    .includes(" ")
                ) {
                  notification["warning"]({
                    message: "Single Word Query",
                    description: "Your query must have more than 1 word.",
                  });
                  break;
                }

                this.props.handleSearchInfoDispatch({
                  trademarksSearchAlgo,
                  activeTab,
                  userTrademark,
                  multiwordTrademark,
                  axiosCancelSearches: {
                    ...axiosCancelSearches,
                    [activeTab]: axiosSource0.cancel,
                  },
                  searchCounter: 1,
                  reminderStamp,
                  searchStamp,
                });

                await this.props.getSearchResults(
                  {
                    ...this.props[activeTab].searchParameters,
                    operation: "search",
                    filter_flag: false,
                    page: 1,
                    search_log_id: null,
                    query: query.replace(/ {1,}/g, " ").trim(),
                  },
                  activeTab,
                  axiosSource0.token,
                  true,
                );

                if (filter_flag) {
                  this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
                    ...axiosCancelSearches,
                    [activeTab]: axiosSource1.cancel,
                  });

                  this.props.getSearchResults(
                    {
                      ...this.props[activeTab].searchParameters,
                      search_log_id:
                        this.props[this.props.activeTab].searchLogID,
                      operation: "search",
                      filter_flag,
                      page: 1,
                      query: query.replace(/ {1,}/g, " ").trim(),
                    },
                    activeTab,
                    axiosSource1.token,
                  );
                }
                break;

              case "Wildcard":
                this.props.handleSearchInfoDispatch({
                  trademarksSearchAlgo,
                  activeTab,
                  userTrademark,
                  multiwordTrademark,
                  axiosCancelSearches: {
                    ...axiosCancelSearches,
                    [activeTab]: axiosSource0.cancel,
                  },
                  searchCounter: 1,
                  reminderStamp,
                  searchStamp,
                });

                await this.props.getSearchResults(
                  {
                    ...this.props[activeTab].searchParameters,
                    operation: "search",
                    filter_flag: false,
                    page: 1,
                    search_log_id: null,
                    query: query
                      .replace(/[&|!'"()$%_^]/g, "")
                      .replace(/ {1,}/g, " ")
                      .trim(),
                  },
                  activeTab,
                  axiosSource0.token,
                  true,
                );

                if (filter_flag) {
                  this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
                    ...axiosCancelSearches,
                    [activeTab]: axiosSource1.cancel,
                  });

                  this.props.getSearchResults(
                    {
                      ...this.props[activeTab].searchParameters,
                      search_log_id:
                        this.props[this.props.activeTab].searchLogID,
                      operation: "search",
                      filter_flag,
                      page: 1,
                      query: query
                        .replace(/[&|!'"()$%_^]/g, "")
                        .replace(/ {1,}/g, " ")
                        .trim(),
                    },
                    activeTab,
                    axiosSource1.token,
                  );
                }
                break;

              case "Contains":
                this.props.handleSearchInfoDispatch({
                  trademarksSearchAlgo,
                  activeTab,
                  userTrademark,
                  multiwordTrademark,
                  axiosCancelSearches: {
                    ...axiosCancelSearches,
                    [activeTab]: axiosSource0.cancel,
                  },
                  searchCounter: 1,
                  reminderStamp,
                  searchStamp,
                });

                await this.props.getSearchResults(
                  {
                    ...this.props[activeTab].searchParameters,
                    operation: "search",
                    filter_flag: false,
                    page: 1,
                    search_log_id: null,
                    query: query
                      .replace(/[&:|!'"()$%_^]/g, "")
                      .replace(/ {1,}/g, " ")
                      .trim(),
                  },
                  activeTab,
                  axiosSource0.token,
                  true,
                );

                if (filter_flag) {
                  this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
                    ...axiosCancelSearches,
                    [activeTab]: axiosSource1.cancel,
                  });

                  this.props.getSearchResults(
                    {
                      ...this.props[activeTab].searchParameters,
                      search_log_id:
                        this.props[this.props.activeTab].searchLogID,
                      operation: "search",
                      filter_flag,
                      page: 1,
                      query: query.replace(/ {1,}/g, " ").trim(),
                    },
                    activeTab,
                    axiosSource1.token,
                  );
                }
                break;

              // case "Phonetic":
              //   this.props.handleSearchInfoDispatch({
              //     trademarksSearchAlgo,
              //     activeTab,
              //     userTrademark,
              //     multiwordTrademark,
              //     axiosCancelSearches: {
              //       ...axiosCancelSearches,
              //       [activeTab]: axiosSource0.cancel,
              //     },
              //     searchCounter: 1,
              //     reminderStamp,
              //     searchStamp,
              //   });

              //   await this.props.getSearchResults(
              //     {
              //       ...this.props[activeTab].searchParameters,
              //       operation: "search",
              //       filter_flag: false,
              //       page: 1,
              //       search_log_id: null,
              //       query: query
              //         .replace(/[^a-zA-Z ]/g, "")
              //         .replace(/ {1,}/g, " ")
              //         .trim(),
              //     },
              //     activeTab,
              //     axiosSource0.token,
              //     true
              //   );

              //   if (filter_flag) {
              //     this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
              //       ...axiosCancelSearches,
              //       [activeTab]: axiosSource1.cancel,
              //     });

              //     this.props.getSearchResults(
              //       {
              //         ...this.props[activeTab].searchParameters,
              //         operation: "search",
              //         filter_flag,
              //         page: 1,
              //         query: query
              //           .replace(/[^a-zA-Z ]/g, "")
              //           .replace(/ {1,}/g, " ")
              //           .trim(),
              //       },
              //       activeTab,
              //       axiosSource1.token
              //     );
              //   }
              //   break;

              default:
                break;
            }
          }
        } else {
          notification["warning"]({
            message: "Wait For Filters",
            description: "Filters are being fetched. Kindly, wait for them.",
          });
        }
      } else {
        notification["warning"]({
          message: "Empty Query",
          description: "No input is provided. Please, enter a valid query.",
        });
      }
    } else {
      this.changeStateValue_L1("modalsVisibility", "needSearchRefresh", true);
    }
  }

  countSelections() {
    const tempArr = [
      ...this.props.searchState.customReportData["fuzzy"].selected,
      ...this.props.searchState.customReportData["contains"].selected,
      ...this.props.searchState.customReportData["wildcard"].selected,
      ...this.props.searchState.customReportData["phonetic"].selected,
      ...this.props.searchState.customReportData["boolean"].selected,
      ...this.props.searchState.customReportData["prefix"].selected,
      ...this.props.searchState.customReportData["suffix"].selected,
    ];

    const uniqueSelections = new Set(tempArr);

    return uniqueSelections.size;
  }

  handleOpenChange = (flag, key) => {
    if (flag) {
      this.setState({
        open: key,
      });
    } else {
      this.setState({
        open: null,
      });
    }
  };

  render() {
    if (this.props.searchCounter === 0) {
      this.handleGetFilters_Debounced();
    }

    const filterMenuScroll = {
      maxHeight: "260px",
      minWidth: "150px",
      overflowY: "auto",
    };

    const searches = this.props.multiwordTrademark
      ? TS_multiwordSearch
      : TS_singlewordSearch;

    // let noFilterData = (
    //   <Menu>
    //     <Menu.Item>No Filters Data Found</Menu.Item>
    //   </Menu>
    // );
    // let classesMenu = noFilterData;
    // let statusesMenu = noFilterData;
    // let typesMenu = noFilterData;
    // let statesMenu = noFilterData;
    // let proprietorsMenu = noFilterData;

    const moreActionsMenu = (
      <Menu
        selectable={true}
        multiple={true}
        selectedKeys={[]}
        style={filterMenuScroll}
      >
        <Menu.Item
          disabled={
            this.props?.searchState?.customReportData?.selectAll === true
          }
          key="Select page"
          onClick={() => {
            this.props.TPAction(TS_SET_STATE, {
              ["customReportData"]: {
                ...this.props.searchState.customReportData,
                ["selectAll"]: false,
                ["selectAllTypes"]: [],
                [this.props.activeTab.toLowerCase()]: {
                  ...this.props.searchState.customReportData[
                    this.props.activeTab.toLowerCase()
                  ],
                  selected: [
                    ...new Set([
                      ...this.props[this.props.activeTab].results.map(
                        (e) => e.id,
                      ),
                      ...this.props.searchState.customReportData[
                        this.props.activeTab.toLowerCase()
                      ].selected,
                    ]),
                  ],
                  select_all: false,
                  search_log_id: this.props[this.props.activeTab].searchLogID,
                },
                applicationsIDs: [
                  ...new Set([
                    ...this.props[this.props.activeTab].results.map(
                      (e) => e.id,
                    ),
                    ...this.props.searchState.customReportData.applicationsIDs,
                  ]),
                ],
              },
            });
          }}
        >
          Select page
        </Menu.Item>
        <Menu.Item
          disabled={
            this.props?.searchState?.customReportData?.selectAll === true
          }
          key="Deselect page"
          onClick={() => {
            this.props.TPAction(TS_SET_STATE, {
              ["customReportData"]: {
                ...this.props.searchState.customReportData,
                ["selectAll"]: false,
                ["selectAllTypes"]: [],
                [this.props.activeTab.toLowerCase()]: {
                  ...this.props.searchState.customReportData[
                    this.props.activeTab.toLowerCase()
                  ],
                  selected: this.props.searchState.customReportData[
                    this.props.activeTab.toLowerCase()
                  ].selected.filter(
                    (e) =>
                      !this.props[this.props.activeTab].results
                        .map((x) => x.id)
                        .includes(e),
                  ),
                  search_log_id: this.props[this.props.activeTab].searchLogID,
                  select_all: false,
                },
                applicationsIDs:
                  this.props.searchState.customReportData.applicationsIDs.filter(
                    (item) =>
                      ![
                        ...this.props[this.props.activeTab].results.map(
                          (e) => e.id,
                        ),
                      ].includes(item),
                  ),
              },
            });
          }}
        >
          Deselect page
        </Menu.Item>

        <Menu.Item
          key="DeSelect all"
          onClick={() => {
            let customReportData = {
              ...this.props.searchState.customReportData,
              applicationsIDs: [],
              selectAllTypes: [],
              selectAll: false,
              [this.props.activeTab.toLowerCase()]: {
                ...this.props.searchState.customReportData[
                  this.props.activeTab.toLowerCase()
                ],
              },
            };
            // Dynamically generate filter data based on TS_multiwordSearch
            TS_multiwordSearch.forEach((key) => {
              customReportData[key.toLowerCase()] = {
                select_all: false,
                selected: [],
                deselected: [],
                search_log_id: this.props[key].searchLogID, // Accessing the searchLogID dynamically
              };
            });

            if(this.props.activeTab === "GOODS_SERVICE"){
              customReportData.goods_service = {
                select_all: false,
                selected: [],
                deselected: [],
                search_log_id: this.props["ImageSearch"].searchLogID,
              };
            }

            // Update the state
            this.props.TPAction(TS_SET_STATE, {
              customReportData: {
                ...customReportData,
              },
            });
          }}
        >
          Deselect All
        </Menu.Item>
      </Menu>
    );

    // if (this.props.filters.classes.length > 0) {
    //   classesMenu = (
    //     <SearchFilterSearchBasic
    //       data={this.props?.filters?.classes}
    //       searchFilters={this.state?.filtersSearchTerms}
    //       search_key={"classes"}
    //       filter_key={"classes"}
    //       selectedFilters={this.props?.searchState?.selectedFilters}
    //       onSelect={this.changeStateValue_L1}
    //       noSearch={"NOSearch"}
    //     />
    //   );
    // }

    // if (this.props.filters.statuses.length > 0) {
    //   statusesMenu = (
    //     <SearchFilterSearchBasic
    //       data={this.props?.filters?.statuses}
    //       searchFilters={this.props?.searchState?.filtersSearchTerms}
    //       search_key={"statuses"}
    //       filter_key={"statuses"}
    //       selectedFilters={this.props?.searchState?.selectedFilters}
    //       onSelect={this.changeStateValue_L1}
    //     />
    //   );
    // }

    // if (this.props.filters.states.length > 0) {
    //   statesMenu = (
    //     <SearchFilterSearchBasic
    //       data={this.props?.filters?.states}
    //       searchFilters={this.props?.searchState?.filtersSearchTerms}
    //       search_key={"states"}
    //       filter_key={"states"}
    //       selectedFilters={this.props?.searchState?.selectedFilters}
    //       onSelect={this.changeStateValue_L1}
    //     />
    //   );
    // }

    // if (this.props.filters.types.length > 0) {
    //   typesMenu = (
    //     <SearchFilterSearchBasic
    //       data={this.props?.filters?.types}
    //       searchFilters={this.props?.searchState?.filtersSearchTerms}
    //       search_key={"types"}
    //       filter_key={"types"}
    //       selectedFilters={this.props?.searchState?.selectedFilters}
    //       onSelect={this.changeStateValue_L1}
    //       type={"name"}
    //       noSearch={"NOSearch"}
    //     />
    //   );
    // }

    // if (this.props.filters.proprietors.length > 0) {
    //   let filteredProprietors = this.props.filters.proprietors.filter(
    //     (eachProprietor) =>
    //       eachProprietor.name
    //         .toLowerCase()
    //         .indexOf(
    //           `${this.props.searchState.filtersSearchTerms.proprietors}`,
    //         ) !== -1,
    //   );

    //   proprietorsMenu = (
    //     <>
    //       <Input
    //         placeholder="Search proprietors"
    //         onChange={(event) =>
    //           this.changeStateValue_L1_Debounced(
    //             "filtersSearchTerms",
    //             "proprietors",
    //             event.target.value.toLowerCase(),
    //           )
    //         }
    //         allowClear
    //       />
    //       <Menu
    //         onScroll={(x) => {
    //           const { scrollTop, scrollHeight, clientHeight } = x.target;
    //           if (scrollTop + clientHeight >= scrollHeight - 20) {
    //             this.setState({ page: this.state.page + 100 });
    //           }
    //         }}
    //         className="List"
    //         style={{ height: 150, width: 450 }}
    //       >
    //         {filteredProprietors.slice(0, this.state.page).map((item) => (
    //           <Menu.Item
    //             key={item.id}
    //             style={{
    //               padding: "5px",
    //               cursor:
    //                 this.props.searchState.selectedFilters.deselectProprietors.includes(
    //                   `${item.id}`,
    //                 )
    //                   ? "not-allowed"
    //                   : "",
    //             }}
    //             className={`
    //     ${
    //       this.props.searchState.selectedFilters.selectProprietors.indexOf(
    //         `${item.id}`,
    //       ) !== -1
    //         ? "ant-dropdown-menu-item-selected"
    //         : ""
    //     }
    //   `}
    //             onClick={() => {
    //               if (
    //                 !this.props.searchState.selectedFilters.deselectProprietors.includes(
    //                   `${item.id}`,
    //                 )
    //               ) {
    //                 let { selectProprietors } =
    //                   this.props.searchState.selectedFilters;
    //                 if (selectProprietors.indexOf(`${item.id}`) !== -1) {
    //                   this.changeStateValue_L1(
    //                     "selectedFilters",
    //                     "selectProprietors",
    //                     selectProprietors.filter(
    //                       (eachProprietor) => eachProprietor !== `${item.id}`,
    //                     ),
    //                   );
    //                 } else {
    //                   this.changeStateValue_L1(
    //                     "selectedFilters",
    //                     "selectProprietors",
    //                     [...selectProprietors, `${item.id}`],
    //                   );
    //                 }
    //               }
    //             }}
    //           >
    //             <div style={{ display: "flex", alignItems: "end" }}>
    //               <Button
    //                 type="link"
    //                 disabled={this.props.searchState.selectedFilters.selectProprietors.includes(
    //                   `${item.id}`,
    //                 )}
    //                 style={{
    //                   color: `${
    //                     this.props.searchState.selectedFilters.deselectProprietors.includes(
    //                       `${item.id}`,
    //                     )
    //                       ? "#ff0000"
    //                       : ""
    //                   }`,
    //                 }}
    //                 onClick={(event) => {
    //                   event.stopPropagation();
    //                   if (
    //                     !this.props.searchState.selectedFilters.selectProprietors.includes(
    //                       `${item.id}`,
    //                     )
    //                   ) {
    //                     let { deselectProprietors } =
    //                       this.props.searchState.selectedFilters;
    //                     if (deselectProprietors.indexOf(`${item.id}`) !== -1) {
    //                       this.changeStateValue_L1(
    //                         "selectedFilters",
    //                         "deselectProprietors",
    //                         deselectProprietors.filter(
    //                           (eachProprietor) =>
    //                             eachProprietor !== `${item.id}`,
    //                         ),
    //                       );
    //                     } else {
    //                       this.changeStateValue_L1(
    //                         "selectedFilters",
    //                         "deselectProprietors",
    //                         [...deselectProprietors, `${item.id}`],
    //                       );
    //                     }
    //                   }
    //                 }}
    //               >
    //                 <Tooltip placement="left" title="Exclude">
    //                   <StopOutlined />
    //                 </Tooltip>
    //               </Button>
    //               <span
    //                 style={{
    //                   overflow: "hidden",
    //                   textOverflow: "ellipsis",
    //                   // whiteSpace: "nowrap",
    //                   width: 300,
    //                 }}
    //               >
    //                 {item.name}
    //               </span>
    //             </div>
    //           </Menu.Item>
    //         ))}
    //       </Menu>
    //     </>
    //   );
    // }

    const searchesMenu = (
      <>
        <Menu
          selectable={true}
          selectedKeys={[this.props.activeTab]}
          onClick={({ key }) => {
            this.props.TPAction(CHANGE_SEARCH_RESULTS_TAB, key);
            let element = document.getElementById("Results-Header");
            if (element !== null) {
              element.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
              });
            }
          }}
          style={{
            maxHeight: "200px",
            width: "200px",
            fontSize: "16px",
            overflow: "auto",
            boxShadow: "0px 0px 5px #c9c9c9",
          }}
        >
          {searches.map((eachClass) => (
            <Menu.Item key={eachClass} style={{ fontSize: "16px" }}>
              {eachClass}
            </Menu.Item>
          ))}
        </Menu>
        <div style={{ textAlign: "center", marginTop: "15px" }}>
          <Tooltip
            placement="left"
            open={
              this.props.searchState.filtersVisibility.trademarksSearchAlgos
            }
            title="What are these search types?"
          >
            <span
              style={{
                padding: "10px",
                backgroundColor: "#ffffff",
                borderRadius: "50%",
                boxShadow: "0px 0px 5px #4285f4",
                cursor: "pointer",
              }}
              onClick={() =>
                this.changeStateValue_L1(
                  "modalsVisibility",
                  "trademarksAlgosInfo",
                  true,
                )
              }
            >
              <QuestionCircleFilled
                style={{
                  fontSize: "20px",
                  verticalAlign: "0px",
                  color: "#4285f4",
                }}
              />
            </span>
          </Tooltip>
        </div>
      </>
    );

    const searchTypesPrimaryMenu = (
      <>
        <Menu
          selectable
          selectedKeys={[this.props?.searchFilters?.reduxSearchType]}
          onClick={({ key }) => {
            this.props.changeSearchDashboardSearchType(key);
            // this.setState({
            //   ["selectedFilters"]: {
            //     ...this.state["selectedFilters"],
            //     ["searchType"]: key,
            //   },
            // })
          }}
          style={{
            maxHeight: "200px",
            width: "250px",
            fontSize: "16px",
            overflow: "auto",
            boxShadow: "0px 0px 5px #c9c9c9",
          }}
        >
          {["Application No/Name", "G&S Description Search"].map((e) => (
            <Menu.Item key={e} style={{ fontSize: "16px" }}>
              {e}
            </Menu.Item>
          ))}
        </Menu>
      </>
    );

    return (
      <>
        <header
          style={{
            textAlign: "center",
            marginBottom: "10px",
            width: "100%",
            alignSelf: "center",
          }}
        >
          <Row justify="center" className="search-filters">
            <Col span={23}>
              <Dropdown overlay={searchTypesPrimaryMenu}>
                <Button
                  // onClick={() => this.handleFilters(true)}
                  type="link"
                  style={{
                    color: "#ffffff",
                    fontWeight: "600",
                    border: "none",
                    padding: "0px 5px",
                    margin: "2px",
                    color: "rgb(66, 133, 244)",
                    backgroundColor: "rgb(245, 246, 248)",
                    border: "1px solid rgb(24, 144, 255)",
                  }}
                >
                  {this.props?.searchFilters?.reduxSearchType}
                  <DownOutlined style={{ fontSize: "12px" }} />
                </Button>
              </Dropdown>
              {this.props?.searchFilters?.reduxSearchType ===
                "Application No/Name" && (
                <Input
                  placeholder="Application No/Name"
                  onChange={(event) => {
                    this.props.changeSearchDashboardSearchValue(
                      "applicationSearchValue",
                      event.target.value,
                    );
                    // this.changeStateValue_L1(
                    //   "selectedFilters",
                    //   "containsTerm",
                    //   event.target.value,
                    // )
                  }}
                  value={this.props?.searchFilters?.applicationSearchValue}
                  allowClear
                  style={{ width: 200 }}
                />
              )}
              {this.props?.searchFilters?.reduxSearchType ===
                "G&S Description Search" && (
                <Input
                  placeholder="G&S Description"
                  onChange={(event) => {
                    this.props.changeSearchDashboardSearchValue(
                      "descriptionSearchValue",
                      event.target.value,
                    );
                    // this.changeStateValue_L1(
                    //   "selectedFilters",
                    //   "containsTerm",
                    //   event.target.value,
                    // )
                  }}
                  value={this.props?.searchFilters?.descriptionSearchValue}
                  allowClear
                  style={{ width: 250 }}
                />
              )}
              <SelectContainer
                filteredData={this.props.searchFilters.classConfig}
                key={this.props.searchFilters.classConfig.key}
                loading={this.props.searchFilters.classConfig.loading}
                showCountOnRight
                handleGetSingleFilter={this.handleGetSingleFilter}
              />
              <SelectContainer
                filteredData={this.props.searchFilters.statusConfig}
                key={this.props.searchFilters.statusConfig.key}
                loading={this.props.searchFilters.statusConfig.loading}
                showCountOnRight
                handleGetSingleFilter={this.handleGetSingleFilter}
              />
              <SelectContainer
                filteredData={this.props.searchFilters.stateConfig}
                key={this.props.searchFilters.stateConfig.key}
                loading={this.props.searchFilters.stateConfig.loading}
                showCountOnRight
                handleGetSingleFilter={this.handleGetSingleFilter}
              />
              <SelectContainer
                filteredData={this.props.searchFilters.typeConfig}
                key={this.props.searchFilters.typeConfig.key}
                loading={this.props.searchFilters.typeConfig.loading}
                showCountOnRight
                handleGetSingleFilter={this.handleGetSingleFilter}
              />
              <SelectContainer
                filteredData={this.props.searchFilters.proprietorConfig}
                key={this.props.searchFilters.proprietorConfig.key}
                loading={this.props.searchFilters.proprietorConfig.loading}
                showCountOnRight
                handleGetSingleFilter={this.handleGetSingleFilter}
              />
              <DateFilterContainer
                menu_label={"DoA|DoU"}
                subtitle1={"Date of Application"}
                subtitle2={"Date of Usage"}
                key={"search_date_filter"}
                filter_key_1="SEARCH_DATE_OF_APPLICATION"
                filter_key_2="SEARCH_DATE_OF_USAGE"
                value1={this.props.searchFilters.dateOfApplicationConfig.value}
                value2={this.props.searchFilters.dateOfUsageConfig.value}
                proposed={
                  this.props.searchFilters.dateOfUsageConfig.dateOfUsageProposed
                }
                doaUnStructured={
                  this.props.searchFilters.dateOfApplicationConfig.filter_is_unstructured_application_date
                }
                douUnStructured={
                  this.props.searchFilters.dateOfUsageConfig.filter_is_unstructured_date_of_usage
                }
              />
              <Button
                onClick={() => this.handleFilters()}
                // loading={this.props.filtersStatus === LOADING}
                style={{
                  color: "#ffffff",
                  fontWeight: "600",
                  border: "none",
                  backgroundColor: "#4285f4",
                  padding: "0px 5px",
                  margin: "2px",
                }}
              >
                Apply
              </Button>
              <Button
                onClick={() => this.handleFilters(true)}
                style={{ padding: "0px 3px" }}
                type="link"
                disabled={!this.props.searchFilters.isFilterApplied}
              >
                Reset
              </Button>
              <Badge
                overflowCount={9999}
                count={
                  this.props.searchState.customReportData?.applicationsIDs
                    ?.length ||
                  (this.props.searchState.customReportData.selectAll ||
                  TS_multiwordSearch.some(
                    (x) =>
                      this.props.searchState.customReportData[
                        x.toLocaleLowerCase()
                      ].select_all,
                  )
                    ? "All"
                    : this.props.searchState.customReportData.applicationsIDs
                        .length) ||
                  this.props.searchState.customReportData.applicationsIDs.length
                  // this.countSelections()
                }
              >
                {(!(TS_multiwordSearch.some(
                  (x) =>
                    this.props.searchState.customReportData[
                      [this.props.activeTab.toLowerCase()]
                    ].selected.length > 0,
                ) ||
                this.props.searchState.customReportData.applicationsIDs.length >
                  0
                  ? false
                  : !TS_multiwordSearch.some(
                      (x) =>
                        this.props.searchState.customReportData[
                          x.toLocaleLowerCase()
                        ].select_all,
                    )) ||
                  this.props.isTourOpen) && (
                  <Button
                    style={{ padding: "0px 5px" }}
                    className="search-create-report"
                    disabled={
                      TS_multiwordSearch.some(
                        (x) =>
                          this.props.searchState.customReportData[
                            [this.props.activeTab.toLowerCase()]
                          ].selected.length > 0,
                      ) ||
                      this.props.searchState.customReportData.applicationsIDs
                        .length > 0
                        ? false
                        : !TS_multiwordSearch.some(
                            (x) =>
                              this.props.searchState.customReportData[
                                x.toLocaleLowerCase()
                              ].select_all,
                          )
                    }
                    onClick={() => {
                      this.props.getTrademarkReportStatus(
                        {
                          check: true,
                          report_source: "MARK",
                          mark_id: this.props.userTrademark.id,
                        },
                        TS_GET_REPORT_AVAILABILITY,
                      );
                      this.changeStateValue_L1(
                        "drawersVisibility",
                        "customReport",
                        true,
                      );
                    }}
                  >
                    Create Report
                  </Button>
                )}
              </Badge>
              {/* <Icon
                      onClick={() => this.changeStateValue_L1("customReportData", "applicationsIDs", [])}
                      type="minus-circle"
                      style={{
                          margin: "0px 5px",
                          fontSize: "14px",
                          visibility: `${this.props.searchState.customReportData.applicationsIDs.length === 0 ? "hidden" : ""}`
                      }}
                  /> */}
              <Dropdown
                disabled={this.props[this.props.activeTab].status === LOADING}
                overlay={moreActionsMenu}
                overlayClassName="Filters-Menu"
                open={this.props.searchState.filtersVisibility.moreActionsMenu}
                onVisibleChange={(event) =>
                  this.changeStateValue_L1(
                    "filtersVisibility",
                    "moreActionsMenu",
                    event,
                  )
                }
              >
                <Button
                  data-step="2"
                  data-intro="Select/Deselect all marks on the current page from here."
                  data-highlightclass="Data-Highlight-Class-General"
                  data-tooltipclass="Data-Tooltip-Class-General"
                  className="Search-More-Actions"
                >
                  <UnorderedListOutlined
                    style={{
                      fontSize: "16px",
                      position: "relative",
                      bottom: "2px",
                    }}
                  />
                </Button>
              </Dropdown>
              {this.props.trademarksSearchAlgo === "Flash" && (
                <Dropdown overlay={searchesMenu}>
                  <Button
                    style={{
                      color: "#ffffff",
                      fontWeight: "600",
                      border: "none",
                      backgroundColor: "#4285f4",
                      padding: "0px 5px",
                    }}
                    data-step="1"
                    data-intro="Access the different result tabs."
                    data-highlightclass="Data-Highlight-Class-General"
                    data-tooltipclass="Data-Tooltip-Class-General"
                  >
                    {this.props.activeTab}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              )}
            </Col>
          </Row>
        </header>
        <div
          className="General-Search-Content Flex-Content vertical_scroll"
          style={{
            height:
              this.props.searchType === "Image_Search"
                ? `${
                    this.props.searchAlert.length > 0
                      ? "calc(100vh - 290px)"
                      : "calc(100vh - 244px)"
                  }`
                : `${
                    this.props.searchAlert.length > 0
                      ? "calc(100vh - 220px)"
                      : "calc(100vh - 180px)"
                  }`,
          }}
        >
          <header
            id="Results-Header"
            style={{
              backgroundColor: "#ffffff",
              margin: "5px",
              width: "100%",
              height: "fit-content",
            }}
          >
            {this.props.trademarksSearchAlgo === "Flash" && (
              <Alert
                message={
                  <div>
                    <InfoCircleFilled
                      style={{
                        fontSize: "20px",
                        verticalAlign: "0px",
                        color: "#4285f4",
                        marginRight: "10px",
                        backgroundColor: "#ffffff",
                        border: "2px solid #ffffff",
                        borderRadius: "50%",
                      }}
                    />
                    For the best analysis please go through all search result
                    tabs such as Fuzzy, Phonetic, Wildcard etc.
                  </div>
                }
                type="info"
              />
            )}
            {this.props.trademarksSearchAlgo === "Flash" &&
              !(this.props[this.props.activeTab].status === LOADING) &&
              searches?.includes(this.props.activeTab) && (
                <div
                  data-step="6"
                  data-intro="Change your query to get more customized results."
                  data-highlightclass="Data-Highlight-Class-General"
                  data-tooltipclass="Data-Tooltip-Class-General"
                  style={{ marginTop: "5px" }}
                >
                  {this.props.activeTab === "Contains" && (
                    <Input.Search
                      defaultValue={
                        this.props["Contains"].searchParameters.search_query
                      }
                      style={{ width: "100%" }}
                      placeholder="Enter contains term"
                      onSearch={(query) => this.handleQueryChange(query)}
                    />
                  )}
                  {this.props.activeTab === "Boolean" && (
                    <>
                      <Tooltip title="And" placement="bottom">
                        <Button
                          onClick={() => {
                            document.getElementById("Boolean-Query").value = `${
                              document.getElementById("Boolean-Query").value
                            }[[&]]`;
                            this.setState({
                              booleanSearch:
                                document.getElementById("Boolean-Query").value,
                            });
                          }}
                          type="link"
                        >
                          {"[[&]]"}
                        </Button>
                      </Tooltip>
                      <Tooltip title="Or" placement="bottom">
                        <Button
                          onClick={() => {
                            document.getElementById("Boolean-Query").value = `${
                              document.getElementById("Boolean-Query").value
                            }[[|]]`;
                            this.setState({
                              booleanSearch:
                                document.getElementById("Boolean-Query").value,
                            });
                          }}
                          type="link"
                        >
                          {"[[|]]"}
                        </Button>
                      </Tooltip>
                      <Tooltip title="Not" placement="bottom">
                        <Button
                          onClick={() => {
                            document.getElementById("Boolean-Query").value = `${
                              document.getElementById("Boolean-Query").value
                            }[[!]]`;
                            this.setState({
                              booleanSearch:
                                document.getElementById("Boolean-Query").value,
                            });
                          }}
                          type="link"
                        >
                          {"[[!]]"}
                        </Button>
                      </Tooltip>
                      <Input.Search
                        id="Boolean-Query"
                        defaultValue={
                          this.props["Boolean"].searchParameters.search_query
                        }
                        value={this.state.booleanSearch}
                        onChange={(event) =>
                          this.setState({ booleanSearch: event.target.value })
                        }
                        style={{ width: "calc(100% - 180px)" }}
                        placeholder="Enter boolean query"
                        onSearch={(query) => this.handleQueryChange(query)}
                      />
                    </>
                  )}
                  {this.props.activeTab === "Wildcard" && (
                    <>
                      <Tooltip title="Multiple characters" placement="bottom">
                        <Button
                          onClick={() => {
                            document.getElementById("Wildcard-Query").value =
                              `${
                                document.getElementById("Wildcard-Query").value
                              }[[*]]`;
                            this.setState({
                              wildcardSearch:
                                document.getElementById("Wildcard-Query").value,
                            });
                          }}
                          type="link"
                        >
                          {"[[*]]"}
                        </Button>
                      </Tooltip>
                      <Tooltip title="Single character" placement="bottom">
                        <Button
                          onClick={() => {
                            document.getElementById("Wildcard-Query").value =
                              `${
                                document.getElementById("Wildcard-Query").value
                              }[[?]]`;
                            this.setState({
                              wildcardSearch:
                                document.getElementById("Wildcard-Query").value,
                            });
                          }}
                          type="link"
                        >
                          {"[[?]]"}
                        </Button>
                      </Tooltip>
                      <Input.Search
                        id="Wildcard-Query"
                        defaultValue={
                          this.props["Wildcard"].searchParameters.search_query
                        }
                        value={this.state.wildcardSearch}
                        style={{ width: "calc(100% - 120px)" }}
                        placeholder="Enter wildcard query"
                        onChange={(event) =>
                          this.setState({ wildcardSearch: event.target.value })
                        }
                        onSearch={(query) => this.handleQueryChange(query)}
                      />
                    </>
                  )}
                  {this.props.activeTab === "Prefix" && (
                    <div style={{ display: "flex" }}>
                      <InputNumber
                        min={0}
                        onPressEnter={(event) => {
                          this.handleQueryChange(event.target.value);
                        }}
                        defaultValue={
                          this.props[this.props.activeTab].searchParameters
                            .prefix_suffix_length
                        }
                        placeholder="Enter prefix/suffix length"
                        style={{ width: "120px" }}
                      />

                      <div
                        style={{
                          margin: "4px 8px",
                          width: "calc(100% - 120px)",
                        }}
                      >
                        <HighlightPrefix
                          text={
                            this.props[this.props.activeTab].searchParameters
                              ?.search_query
                          }
                          highlightLength={
                            this.props[this.props.activeTab].searchParameters
                              ?.prefix_suffix_length
                          }
                        />
                      </div>
                    </div>
                  )}
                  {this.props.activeTab === "Suffix" && (
                    <div style={{ display: "flex" }}>
                      <InputNumber
                        min={0}
                        onPressEnter={(event) => {
                          this.handleQueryChange(event.target.value);
                        }}
                        defaultValue={
                          this.props[this.props.activeTab].searchParameters
                            .prefix_suffix_length
                        }
                        placeholder="Enter prefix/suffix length"
                        style={{ width: "120px" }}
                      />

                      <div
                        style={{
                          margin: "4px 8px",
                          width: "calc(100% - 120px)",
                        }}
                      >
                        <HighlightPrefix
                          text={
                            this.props[this.props.activeTab].searchParameters
                              ?.search_query
                          }
                          highlightLength={
                            this.props[this.props.activeTab].searchParameters
                              ?.prefix_suffix_length
                          }
                          suffix={true}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
          </header>

          {this.props[this.props.activeTab].status === LOADING && (
            <Spin size="large" style={{ width: "100%" }} />
          )}

          {this.props.selectedView === "1" &&
            this.props[this.props.activeTab].status === SUCCESS &&
            this.props[this.props.activeTab].results.length > 0 &&
            this.props[this.props.activeTab].results.map((eachMark, index) => {
              return (
                <GeneralMarkCard
                  customClassName={`search-card-${index}`}
                  key={eachMark.application_number}
                  eachMark={eachMark}
                  onSelectMark={(eachMark, checked) => {
                    let { applicationsIDs } =
                      this.props.searchState.customReportData;

                    if (checked) {
                      this.props.TPAction(TS_SET_STATE, {
                        ["customReportData"]: {
                          ...this.props.searchState.customReportData,
                          ["selectAll"]: false,
                          ["selectAllTypes"]: [],
                          fuzzy: this.props.Fuzzy.results
                            .map(({ id }) => id)
                            .some((id) => id === eachMark.id)
                            ? {
                                ...this.props.searchState.customReportData[
                                  "fuzzy"
                                ],
                                selected: [
                                  eachMark.id,
                                  ...this.props.searchState.customReportData[
                                    "fuzzy"
                                  ].selected,
                                ],
                                select_all: false,
                              }
                            : {
                                ...this.props.searchState.customReportData[
                                  "fuzzy"
                                ],
                              },
                          contains: this.props.Contains.results
                            .map(({ id }) => id)
                            .some((id) => id === eachMark.id)
                            ? {
                                ...this.props.searchState.customReportData[
                                  "contains"
                                ],
                                selected: [
                                  eachMark.id,
                                  ...this.props.searchState.customReportData[
                                    "contains"
                                  ].selected,
                                ],
                                select_all: false,
                              }
                            : {
                                ...this.props.searchState.customReportData[
                                  "contains"
                                ],
                              },
                          wildcard: this.props.Wildcard.results
                            .map(({ id }) => id)
                            .some((id) => id === eachMark.id)
                            ? {
                                ...this.props.searchState.customReportData[
                                  "wildcard"
                                ],
                                selected: [
                                  eachMark.id,
                                  ...this.props.searchState.customReportData[
                                    "wildcard"
                                  ].selected,
                                ],
                                select_all: false,
                              }
                            : {
                                ...this.props.searchState.customReportData[
                                  "wildcard"
                                ],
                              },
                          phonetic: this.props.Phonetic.results
                            .map(({ id }) => id)
                            .some((id) => id === eachMark.id)
                            ? {
                                ...this.props.searchState.customReportData[
                                  "phonetic"
                                ],
                                selected: [
                                  eachMark.id,
                                  ...this.props.searchState.customReportData[
                                    "phonetic"
                                  ].selected,
                                ],
                                select_all: false,
                              }
                            : {
                                ...this.props.searchState.customReportData[
                                  "phonetic"
                                ],
                              },
                          initials: this.props.Initials.results
                            .map(({ id }) => id)
                            .some((id) => id === eachMark.id)
                            ? {
                                ...this.props.searchState.customReportData[
                                  "initials"
                                ],
                                selected: [
                                  eachMark.id,
                                  ...this.props.searchState.customReportData[
                                    "initials"
                                  ].selected,
                                ],
                                select_all: false,
                              }
                            : {
                                ...this.props.searchState.customReportData[
                                  "initials"
                                ],
                              },
                          boolean: this.props.Boolean.results
                            .map(({ id }) => id)
                            .some((id) => id === eachMark.id)
                            ? {
                                ...this.props.searchState.customReportData[
                                  "boolean"
                                ],
                                selected: [
                                  eachMark.id,
                                  ...this.props.searchState.customReportData[
                                    "boolean"
                                  ].selected,
                                ],
                                select_all: false,
                              }
                            : {
                                ...this.props.searchState.customReportData[
                                  "boolean"
                                ],
                              },
                          prefix: this.props.Prefix.results
                            .map(({ id }) => id)
                            .some((id) => id === eachMark.id)
                            ? {
                                ...this.props.searchState.customReportData[
                                  "prefix"
                                ],
                                selected: [
                                  eachMark.id,
                                  ...this.props.searchState.customReportData[
                                    "prefix"
                                  ].selected,
                                ],
                                select_all: false,
                              }
                            : {
                                ...this.props.searchState.customReportData[
                                  "prefix"
                                ],
                              },
                          suffix: this.props.Suffix.results
                            .map(({ id }) => id)
                            .some((id) => id === eachMark.id)
                            ? {
                                ...this.props.searchState.customReportData[
                                  "suffix"
                                ],
                                selected: [
                                  eachMark.id,
                                  ...this.props.searchState.customReportData[
                                    "suffix"
                                  ].selected,
                                ],
                                select_all: false,
                              }
                            : {
                                ...this.props.searchState.customReportData[
                                  "suffix"
                                ],
                              },
                          goods_service: this.props.GOODS_SERVICE.results
                            .map(({ id }) => id)
                            .some((id) => id === eachMark.id)
                            ? {
                                ...this.props.searchState.customReportData[
                                  "goods_service"
                                ],
                                selected: [
                                  eachMark.id,
                                  ...this.props.searchState.customReportData[
                                    "goods_service"
                                  ].selected,
                                ],
                                select_all: false,
                              }
                            : {
                                ...this.props.searchState.customReportData[
                                  "goods_service"
                                ],
                              },
                          applicationsIDs: [eachMark.id, ...applicationsIDs],
                        },
                      });
                    } else {
                      this.props.TPAction(TS_SET_STATE, {
                        ["customReportData"]: {
                          ...this.props.searchState.customReportData,
                          ["selectAll"]: false,
                          ["selectAllTypes"]: [],
                          [this.props.activeTab.toLowerCase()]: {
                            ...this.props.searchState.customReportData[
                              this.props.activeTab.toLowerCase()
                            ],
                            selected: this.props.searchState.customReportData[
                              this.props.activeTab.toLowerCase()
                            ].selected.filter((e) => e !== eachMark.id),
                            select_all: false,
                          },
                          fuzzy: this.props.Fuzzy.results
                            .map(({ id }) => id)
                            .some((id) => id === eachMark.id)
                            ? {
                                ...this.props.searchState.customReportData[
                                  "fuzzy"
                                ],
                                selected:
                                  this.props.searchState.customReportData[
                                    "fuzzy"
                                  ].selected.filter((e) => e !== eachMark.id),
                                select_all: false,
                              }
                            : {
                                ...this.props.searchState.customReportData[
                                  "fuzzy"
                                ],
                              },
                          contains: this.props.Contains.results
                            .map(({ id }) => id)
                            .some((id) => id === eachMark.id)
                            ? {
                                ...this.props.searchState.customReportData[
                                  "contains"
                                ],
                                selected:
                                  this.props.searchState.customReportData[
                                    "contains"
                                  ].selected.filter((e) => e !== eachMark.id),
                                select_all: false,
                              }
                            : {
                                ...this.props.searchState.customReportData[
                                  "contains"
                                ],
                              },
                          wildcard: this.props.Wildcard.results
                            .map(({ id }) => id)
                            .some((id) => id === eachMark.id)
                            ? {
                                ...this.props.searchState.customReportData[
                                  "wildcard"
                                ],
                                selected:
                                  this.props.searchState.customReportData[
                                    "wildcard"
                                  ].selected.filter((e) => e !== eachMark.id),
                                select_all: false,
                              }
                            : {
                                ...this.props.searchState.customReportData[
                                  "wildcard"
                                ],
                              },
                          phonetic: this.props.Phonetic.results
                            .map(({ id }) => id)
                            .some((id) => id === eachMark.id)
                            ? {
                                ...this.props.searchState.customReportData[
                                  "phonetic"
                                ],
                                selected:
                                  this.props.searchState.customReportData[
                                    "phonetic"
                                  ].selected.filter((e) => e !== eachMark.id),
                                select_all: false,
                              }
                            : {
                                ...this.props.searchState.customReportData[
                                  "phonetic"
                                ],
                              },
                          initials: this.props.Initials.results
                            .map(({ id }) => id)
                            .some((id) => id === eachMark.id)
                            ? {
                                ...this.props.searchState.customReportData[
                                  "initials"
                                ],
                                selected:
                                  this.props.searchState.customReportData[
                                    "initials"
                                  ].selected.filter((e) => e !== eachMark.id),
                                select_all: false,
                              }
                            : {
                                ...this.props.searchState.customReportData[
                                  "initials"
                                ],
                              },
                          boolean: this.props.Boolean.results
                            .map(({ id }) => id)
                            .some((id) => id === eachMark.id)
                            ? {
                                ...this.props.searchState.customReportData[
                                  "boolean"
                                ],
                                selected: [
                                  eachMark.id,
                                  ...this.props.searchState.customReportData[
                                    "boolean"
                                  ].selected,
                                ],
                                selected:
                                  this.props.searchState.customReportData[
                                    "boolean"
                                  ].selected.filter((e) => e !== eachMark.id),
                                select_all: false,
                              }
                            : {
                                ...this.props.searchState.customReportData[
                                  "boolean"
                                ],
                              },
                          prefix: this.props.Prefix.results
                            .map(({ id }) => id)
                            .some((id) => id === eachMark.id)
                            ? {
                                ...this.props.searchState.customReportData[
                                  "prefix"
                                ],
                                selected: [
                                  eachMark.id,
                                  ...this.props.searchState.customReportData[
                                    "prefix"
                                  ].selected,
                                ],
                                selected:
                                  this.props.searchState.customReportData[
                                    "prefix"
                                  ].selected.filter((e) => e !== eachMark.id),
                                select_all: false,
                              }
                            : {
                                ...this.props.searchState.customReportData[
                                  "prefix"
                                ],
                              },
                          suffix: this.props.Suffix.results
                            .map(({ id }) => id)
                            .some((id) => id === eachMark.id)
                            ? {
                                ...this.props.searchState.customReportData[
                                  "suffix"
                                ],
                                selected: [
                                  eachMark.id,
                                  ...this.props.searchState.customReportData[
                                    "suffix"
                                  ].selected,
                                ],
                                selected:
                                  this.props.searchState.customReportData[
                                    "suffix"
                                  ].selected.filter((e) => e !== eachMark.id),
                                select_all: false,
                              }
                            : {
                                ...this.props.searchState.customReportData[
                                  "suffix"
                                ],
                              },
                          goods_service: this.props.GOODS_SERVICE.results
                            .map(({ id }) => id)
                            .some((id) => id === eachMark.id)
                            ? {
                                ...this.props.searchState.customReportData[
                                  "goods_service"
                                ],
                                selected: [
                                  eachMark.id,
                                  ...this.props.searchState.customReportData[
                                    "goods_service"
                                  ].selected,
                                ],
                                select_all: false,
                              }
                            : {
                                ...this.props.searchState.customReportData[
                                  "goods_service"
                                ],
                              },
                          applicationsIDs: applicationsIDs.filter(
                            (e) => e !== eachMark.id,
                          ),
                        },
                      });
                    }
                  }}
                  selectAllCards={
                    this.props.searchState.customReportData.selectAll ||
                    this.props.searchState.customReportData.selectAllTypes.includes(
                      this.props.activeTab,
                    ) ||
                    this.props.searchState.customReportData[
                      this.props.activeTab.toLowerCase()
                    ].select_all
                  }
                  checked={
                    this.props.searchState.customReportData[
                      this.props.activeTab.toLowerCase()
                    ].selected.includes(eachMark.id) ||
                    this.props.searchState.customReportData[
                      this.props.activeTab.toLowerCase()
                    ].select_all ||
                    this.props.searchState.customReportData?.applicationsIDs?.includes(
                      eachMark.id,
                    ) ||
                    this.props.searchState.customReportData.selectAll ||
                    this.props.searchState.customReportData.selectAllTypes.includes(
                      this.props.activeTab,
                    )
                  }
                  changeStateValue_L1={(x, y, application) => {
                    sessionStorage.setItem("application", application);
                    window.open(`/search/trademark-profile`);
                  }}
                  styleCard={{ textAlign: "left", height: "inherit" }}
                  similarity={null}
                  status={checkNA(eachMark.status)}
                  proprietorClickable={true}
                  onProprietorClick={(proprietor) => {
                    sessionStorage.setItem("proprietor", proprietor);
                    window.open(`/search/proprietor-profile/${proprietor}`);
                  }}
                />
              );
            })}

          {/* When the data is received successfully and selectedVew is 1, cards will be rendered by the following code. */}
          {this.props.selectedView === "2" &&
            this.props[this.props.activeTab].status === SUCCESS &&
            this.props[this.props.activeTab].results.length > 0 && (
              <table className="Table" style={{ height: "inherit" }}>
                <thead className="Table-Head">
                  <tr className="Table-Head-Row">
                    <th
                      className="Table-Head-Cell Black-Shadow"
                      style={{ width: "4%", minWidth: "50px" }}
                    ></th>
                    <th className="Table-Head-Cell Red-Shadow Red-BG">MARK</th>
                    <th className="Table-Head-Cell Red-Shadow Red-BG">
                      STATUS
                    </th>
                    <th className="Table-Head-Cell Red-Shadow Red-BG">TYPE</th>
                    <th className="Table-Head-Cell Red-Shadow Red-BG">
                      PROPRIETORS
                    </th>
                    <th className="Table-Head-Cell Red-Shadow Red-BG">
                      CLASSES
                    </th>
                    <th className="Table-Head-Cell Red-Shadow Red-BG">
                      DoA | DoU
                    </th>
                    <th
                      className="Table-Head-Cell Red-Shadow Red-BG search-gs-btn"
                      style={{ width: "4%", minWidth: "50px" }}
                    >
                      {this.props.searchState.tableDescriptionRowsVisibility ? (
                        <Tooltip placement="left" title="Hide Descriptions">
                          <MinusSquareFilled
                            style={{
                              fontSize: "20px",
                              verticalAlign: "baseline",
                            }}
                            onClick={() =>
                              this.props.TPAction(TS_SET_STATE, {
                                tableDescriptionRowsVisibility: false,
                              })
                            }
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip placement="left" title="Show Descriptions">
                          <PlusSquareFilled
                            style={{
                              fontSize: "20px",
                              verticalAlign: "baseline",
                            }}
                            onClick={() =>
                              this.props.TPAction(TS_SET_STATE, {
                                tableDescriptionRowsVisibility: true,
                              })
                            }
                          />
                        </Tooltip>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody className="Table-Body">
                  {/* When table data is received successfully and is not empty, this table will render */}
                  {this.props[this.props.activeTab].status === SUCCESS &&
                    this.props[this.props.activeTab].count > 0 &&
                    this.props[this.props.activeTab].results.map(
                      (eachMark, rowIndex) => {
                        return (
                          <React.Fragment key={eachMark.application_number}>
                            <tr
                              className={`Table-Body-Row ${
                                rowIndex % 2 === 0 ? "Row-Even" : "Row-Odd"
                              }`}
                            >
                              <td>
                                <Checkbox
                                  className={`search-card-${rowIndex}-check checkbox-content-list`}
                                  disabled={
                                    this.props.searchState.customReportData
                                      .selectAll ||
                                    this.props.searchState.customReportData.selectAllTypes.includes(
                                      this.props.activeTab,
                                    )
                                  }
                                  onChange={(event) => {
                                    let { applicationsIDs } =
                                      this.props.searchState.customReportData;

                                    if (event.target.checked) {
                                      this.props.TPAction(TS_SET_STATE, {
                                        ["customReportData"]: {
                                          ...this.props.searchState
                                            .customReportData,
                                          ["selectAll"]: false,
                                          ["selectAllTypes"]: [],
                                          fuzzy: this.props.Fuzzy.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["fuzzy"],
                                                selected: [
                                                  eachMark.id,
                                                  ...this.props.searchState
                                                    .customReportData["fuzzy"]
                                                    .selected,
                                                ],
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["fuzzy"],
                                              },
                                          contains: this.props.Contains.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["contains"],
                                                selected: [
                                                  eachMark.id,
                                                  ...this.props.searchState
                                                    .customReportData[
                                                    "contains"
                                                  ].selected,
                                                ],
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["contains"],
                                              },
                                          wildcard: this.props.Wildcard.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["wildcard"],
                                                selected: [
                                                  eachMark.id,
                                                  ...this.props.searchState
                                                    .customReportData[
                                                    "wildcard"
                                                  ].selected,
                                                ],
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["wildcard"],
                                              },
                                          phonetic: this.props.Phonetic.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["phonetic"],
                                                selected: [
                                                  eachMark.id,
                                                  ...this.props.searchState
                                                    .customReportData[
                                                    "phonetic"
                                                  ].selected,
                                                ],
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["phonetic"],
                                              },
                                          initials: this.props.Initials.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["initials"],
                                                selected: [
                                                  eachMark.id,
                                                  ...this.props.searchState
                                                    .customReportData[
                                                    "initials"
                                                  ].selected,
                                                ],
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["initials"],
                                              },
                                          boolean: this.props.Boolean.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["boolean"],
                                                selected: [
                                                  eachMark.id,
                                                  ...this.props.searchState
                                                    .customReportData["boolean"]
                                                    .selected,
                                                ],
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["boolean"],
                                              },
                                          prefix: this.props.Prefix.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["prefix"],
                                                selected: [
                                                  eachMark.id,
                                                  ...this.props.searchState
                                                    .customReportData["prefix"]
                                                    .selected,
                                                ],
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["prefix"],
                                              },
                                          suffix: this.props.Suffix.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["suffix"],
                                                selected: [
                                                  eachMark.id,
                                                  ...this.props.searchState
                                                    .customReportData["suffix"]
                                                    .selected,
                                                ],
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["suffix"],
                                              },
                                          goods_service:
                                            this.props.GOODS_SERVICE.results
                                              .map(({ id }) => id)
                                              .some((id) => id === eachMark.id)
                                              ? {
                                                  ...this.props.searchState
                                                    .customReportData[
                                                    "goods_service"
                                                  ],
                                                  selected: [
                                                    eachMark.id,
                                                    ...this.props.searchState
                                                      .customReportData[
                                                      "goods_service"
                                                    ].selected,
                                                  ],
                                                  select_all: false,
                                                }
                                              : {
                                                  ...this.props.searchState
                                                    .customReportData[
                                                    "goods_service"
                                                  ],
                                                },
                                          applicationsIDs: [
                                            eachMark.id,
                                            ...applicationsIDs,
                                          ],
                                        },
                                      });
                                    } else {
                                      this.props.TPAction(TS_SET_STATE, {
                                        ["customReportData"]: {
                                          ...this.props.searchState
                                            .customReportData,
                                          ["selectAll"]: false,
                                          ["selectAllTypes"]: [],
                                          [this.props.activeTab.toLowerCase()]:
                                            {
                                              ...this.props.searchState
                                                .customReportData[
                                                this.props.activeTab.toLowerCase()
                                              ],
                                              selected:
                                                this.props.searchState.customReportData[
                                                  this.props.activeTab.toLowerCase()
                                                ].selected.filter(
                                                  (e) => e !== eachMark.id,
                                                ),
                                              select_all: false,
                                            },
                                          fuzzy: this.props.Fuzzy.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["fuzzy"],
                                                selected:
                                                  this.props.searchState.customReportData[
                                                    "fuzzy"
                                                  ].selected.filter(
                                                    (e) => e !== eachMark.id,
                                                  ),
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["fuzzy"],
                                              },
                                          contains: this.props.Contains.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["contains"],
                                                selected:
                                                  this.props.searchState.customReportData[
                                                    "contains"
                                                  ].selected.filter(
                                                    (e) => e !== eachMark.id,
                                                  ),
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["contains"],
                                              },
                                          wildcard: this.props.Wildcard.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["wildcard"],
                                                selected:
                                                  this.props.searchState.customReportData[
                                                    "wildcard"
                                                  ].selected.filter(
                                                    (e) => e !== eachMark.id,
                                                  ),
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["wildcard"],
                                              },
                                          phonetic: this.props.Phonetic.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["phonetic"],
                                                selected:
                                                  this.props.searchState.customReportData[
                                                    "phonetic"
                                                  ].selected.filter(
                                                    (e) => e !== eachMark.id,
                                                  ),
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["phonetic"],
                                              },
                                          initials: this.props.Initials.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["initials"],
                                                selected:
                                                  this.props.searchState.customReportData[
                                                    "initials"
                                                  ].selected.filter(
                                                    (e) => e !== eachMark.id,
                                                  ),
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["initials"],
                                              },
                                          boolean: this.props.Boolean.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["boolean"],
                                                selected: [
                                                  eachMark.id,
                                                  ...this.props.searchState
                                                    .customReportData["boolean"]
                                                    .selected,
                                                ],
                                                selected:
                                                  this.props.searchState.customReportData[
                                                    "boolean"
                                                  ].selected.filter(
                                                    (e) => e !== eachMark.id,
                                                  ),
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["boolean"],
                                              },
                                          prefix: this.props.Prefix.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["prefix"],
                                                selected: [
                                                  eachMark.id,
                                                  ...this.props.searchState
                                                    .customReportData["prefix"]
                                                    .selected,
                                                ],
                                                selected:
                                                  this.props.searchState.customReportData[
                                                    "prefix"
                                                  ].selected.filter(
                                                    (e) => e !== eachMark.id,
                                                  ),
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["prefix"],
                                              },
                                          suffix: this.props.Suffix.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["suffix"],
                                                selected: [
                                                  eachMark.id,
                                                  ...this.props.searchState
                                                    .customReportData["suffix"]
                                                    .selected,
                                                ],
                                                selected:
                                                  this.props.searchState.customReportData[
                                                    "suffix"
                                                  ].selected.filter(
                                                    (e) => e !== eachMark.id,
                                                  ),
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["suffix"],
                                              },
                                          goods_service:
                                            this.props.GOODS_SERVICE.results
                                              .map(({ id }) => id)
                                              .some((id) => id === eachMark.id)
                                              ? {
                                                  ...this.props.searchState
                                                    .customReportData[
                                                    "goods_service"
                                                  ],
                                                  selected: [
                                                    eachMark.id,
                                                    ...this.props.searchState
                                                      .customReportData[
                                                      "goods_service"
                                                    ].selected,
                                                  ],
                                                  selected:
                                                    this.props.searchState.customReportData[
                                                      "goods_service"
                                                    ].selected.filter(
                                                      (e) => e !== eachMark.id,
                                                    ),
                                                  select_all: false,
                                                }
                                              : {
                                                  ...this.props.searchState
                                                    .customReportData[
                                                    "goods_service"
                                                  ],
                                                },
                                          applicationsIDs:
                                            applicationsIDs.filter(
                                              (e) => e !== eachMark.id,
                                            ),
                                        },
                                      });
                                    }
                                    // if (event.target.checked) {
                                    //     this.props.TPAction(TS_SET_STATE,{["customReportData"]: {
                                    //         ...this.props.searchState.customReportData,
                                    //         ["selectAll"]: false,
                                    //         [ "selectAllTypes"]:[],
                                    //         [this.props.activeTab.toLowerCase()]:{
                                    //             ...this.props.searchState.customReportData[this.props.activeTab.toLowerCase()],
                                    //             selected:[eachRow.id, ...this.props.searchState.customReportData[this.props.activeTab.toLowerCase()].selected],
                                    //             select_all: false,
                                    //         },
                                    //     }});
                                    // } else {
                                    //     this.props.TPAction(TS_SET_STATE,{["customReportData"]: {
                                    //         ...this.props.searchState.customReportData,
                                    //         ["selectAll"]: false,
                                    //         [ "selectAllTypes"]:[],
                                    //         [this.props.activeTab.toLowerCase()]:{...this.props.searchState.customReportData[this.props.activeTab.toLowerCase()],
                                    //             selected:this.props.searchState.customReportData[this.props.activeTab.toLowerCase()].selected.filter(e => e !== eachRow.id),
                                    //             select_all:false
                                    //         }
                                    //     }});
                                    // }
                                  }}
                                  checked={
                                    this.props.searchState.customReportData[
                                      this.props.activeTab.toLowerCase()
                                    ].selected.includes(eachMark.id) ||
                                    this.props.searchState.customReportData[
                                      this.props.activeTab.toLowerCase()
                                    ].select_all ||
                                    this.props.searchState.customReportData?.applicationsIDs?.includes(
                                      eachMark.id,
                                    ) ||
                                    this.props.searchState.customReportData
                                      .selectAll ||
                                    this.props.searchState.customReportData.selectAllTypes.includes(
                                      this.props.activeTab,
                                    )
                                  }
                                  // checked={
                                  //     this.props.searchState.customReportData[this.props.activeTab.toLowerCase()].selected.includes(eachRow.id) ||
                                  //     this.props.searchState.customReportData.applicationsIDs.includes(eachRow.id) ||
                                  //     this.props.searchState.customReportData.selectAll ||
                                  //     this.props.searchState.customReportData.selectAllTypes.includes(this.props.activeTab)
                                  // }
                                  // onChange={(event) => {
                                  //     let { applicationsIDs } = this.props.searchState.customReportData;
                                  //     if (event.target.checked) {
                                  //         this.changeStateValue_L1(
                                  //             "customReportData", "applicationsIDs",
                                  //             [eachRow.id, ...applicationsIDs]
                                  //         );
                                  //     } else {
                                  //         this.changeStateValue_L1(
                                  //             "customReportData", "applicationsIDs",
                                  //             applicationsIDs.filter(e => e !== eachRow.id)
                                  //         );
                                  //     }
                                  // }}
                                />
                              </td>
                              <td>
                                <span>{eachMark.applied_for}</span>
                                <br />
                                {eachMark.associated_image && (
                                  <>
                                    <span>
                                      {/* <img
                                        src={eachMark.associated_image}
                                        height="70px"
                                        width="70px"
                                        alt=""
                                      /> */}
                                      <Image
                                        src={eachMark.associated_image}
                                        width="100px"
                                        height="70px"
                                        alt=""
                                      />
                                    </span>
                                    <br />
                                  </>
                                )}
                                <span
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      "application",
                                      eachMark.application_number,
                                    );
                                    window.open(`/search/trademark-profile`);
                                  }}
                                  className="Application-Number"
                                >
                                  {eachMark.application_number}
                                </span>
                              </td>
                              <td>{eachMark.status}</td>
                              <td>{eachMark?.type?.name}</td>
                              <td>
                                {eachMark.proprietor.map(
                                  (eachProprietor, index, allProprietors) => (
                                    <React.Fragment key={eachProprietor.id}>
                                      <span>
                                        <span>
                                          <strong>Proprietor:</strong>{" "}
                                          <span
                                            onClick={() => {
                                              sessionStorage.setItem(
                                                "proprietor",
                                                eachProprietor.id,
                                              );
                                              window.open(
                                                `/search/proprietor-profile/${eachProprietor.id}`,
                                              );
                                            }}
                                            className="Application-Number"
                                          >
                                            {eachProprietor.name}
                                          </span>
                                        </span>
                                        <br />
                                        <span>
                                          <strong>Address:</strong>{" "}
                                          {eachProprietor.address}
                                        </span>
                                      </span>
                                      {allProprietors.length === index + 1 ? (
                                        ""
                                      ) : (
                                        <hr />
                                      )}
                                    </React.Fragment>
                                  ),
                                )}
                              </td>
                              <td>
                                {eachMark.associated_class.map(
                                  (eachClass, index, allClasses) =>
                                    allClasses.length === index + 1
                                      ? `${eachClass}`
                                      : `${eachClass}, `,
                                )}
                              </td>
                              <td>
                                <span>
                                  DoA:{" "}
                                  {eachMark.application_date
                                    ? eachMark.application_date
                                    : "N/A"}
                                </span>
                                <br />
                                <span>
                                  DoU:{" "}
                                  {eachMark.date_of_usage
                                    ? eachMark.date_of_usage
                                    : "Proposed"}
                                </span>
                              </td>
                              <td></td>
                            </tr>
                            {(this.props.searchState
                              .tableDescriptionRowsVisibility ||
                              this.props.isTourOpen) && (
                              <tr
                                className={`Table-Body-Row ${
                                  rowIndex % 2 === 0 ? "Row-Even" : "Row-Odd"
                                }`}
                              >
                                <td></td>
                                <td
                                  colSpan="6"
                                  style={{ verticalAlign: "top" }}
                                >
                                  {eachMark.description
                                    ? eachMark.description
                                    : ""}
                                </td>
                                <td></td>
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      },
                    )}
                </tbody>
              </table>
            )}

          {/* Data is received successfully but it is empty */}
          {this.props[this.props.activeTab].status === SUCCESS &&
            this.props[this.props.activeTab].results.length === 0 && (
              <Empty
                style={{ width: "100%" }}
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                description={
                  <Typography.Text>
                    No similar marks were found for your query. Try other search
                    tabs or modify query.
                  </Typography.Text>
                }
              />
            )}

          {/* Request fails or there is any kind of error while fetching the data */}
          {this.props[this.props.activeTab].status === ERROR && (
            <div style={{ display: "table", height: "40vh", width: "100%" }}>
              <div
                style={{
                  display: "table-cell",
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Alert
                    showIcon
                    message="Unfortunately, due to the occurrence of an error, we were
             unable to fetch the data from the server."
                    description="We apologize for the inconvenience. Please, try again after
             some time."
                    type="error"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <footer className="General-Footer">
          <Radio.Group
            onChange={(event) =>
              this.props.TPAction(TS_SELECT_VIEW, event.target.value)
            }
            value={this.props.selectedView}
            style={{ float: "left" }}
          >
            <Radio.Button value="1" className="search-view-cards">Cards</Radio.Button>
            <Radio.Button value="2" className="search-view-table">
              <Tooltip title={"View marks along with the G&S Description"}>
                <span
                  data-step="4"
                  data-intro={`View marks with the G&S description.`}
                  data-highlightclass="Data-Highlight-Class-General"
                  data-tooltipclass="Data-Tooltip-Class-General"
                >
                  Table
                </span>
              </Tooltip>
            </Radio.Button>
          </Radio.Group>
          <Tooltip title="Results Analysis" placement="right">
            <Button
              className="search-analysis-btn"
              data-step="5"
              data-intro="Graphical representation of similar marks."
              data-highlightclass="Data-Highlight-Class-General"
              data-tooltipclass="Data-Tooltip-Class-General"
              style={{ float: "left", margin: "0px 5px" }}
              onClick={() =>
                this.changeStateValue_L1(
                  "drawersVisibility",
                  "resultsAnalysis",
                  true,
                )
              }
            >
              <PieChartOutlined style={{ fontSize: "14px" }} />
            </Button>
          </Tooltip>
          <Pagination
            showQuickJumper
            defaultCurrent={1}
            current={this.props[this.props.activeTab].pageNumber}
            total={this.props[this.props.activeTab].count}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} `}
            pageSize={100}
            showSizeChanger={false}
            onChange={(page) => {
              if (this.props[this.props.activeTab].status === LOADING) {
                notification["warning"]({
                  message: "Request Under Process",
                  description:
                    "A request is under process. Kindly, wait for its completion",
                });
              } else {
                let CancelToken = Axios.CancelToken;
                let axiosSource = CancelToken.source();

                this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
                  ...this.props.axiosCancelSearches,
                  [this.props.activeTab]: axiosSource.cancel,
                });

                if (this.props.activeTab === "ImageSearch") {
                  let myParams = {
                    ...this.props["ImageSearch"].searchParameters,
                  };
                  delete myParams.image;
                  // let newParams = {...myParams}
                  this.props.getImageSearchResults(
                    {
                      ...myParams,
                      search_log_id:
                        this.props[this.props.activeTab].searchLogID,
                      operation: "search",
                      page,
                    },
                    "ImageSearch",
                  );
                } else {
                  this.props.getSearchResults(
                    {
                      ...this.props[this.props.activeTab].searchParameters,
                      search_log_id:
                        this.props[this.props.activeTab].searchLogID,
                      page,
                      flash_flag: this.props.trademarksSearchAlgo === "Flash",
                    },
                    this.props.activeTab,
                    axiosSource.token,
                  );
                }
              }
            }}
          />
        </footer>

        <Drawer
          // title={<h2>Results Analysis({`${this.props.activeTab}`})</h2>}
          title={<h2>Results Analysis</h2>}
          placement="right"
          width={800}
          closable={true}
          open={this.props.searchState.drawersVisibility.resultsAnalysis}
          onClose={() =>
            this.changeStateValue_L1(
              "drawersVisibility",
              "resultsAnalysis",
              false,
            )
          }
          className="analysis-body"
          bodyStyle={{
            padding: 0,
          }}
        >
          <div className="analysis-body-container vertical_scroll">
            {/* {this.props.searchState.drawersVisibility.resultsAnalysis && (
              <ResultsAnalysis
                analysisStatus={this.props[this.props.activeTab].analysisStatus}
                statusesDistribution={
                  this.props[this.props.activeTab].analysis
                    ? this.props[this.props.activeTab].analysis.status
                    : []
                }
                classesDistribution={
                  this.props[this.props.activeTab].analysis
                    ? this.props[this.props.activeTab].analysis.class
                    : []
                }
                typesDistribution={
                  this.props[this.props.activeTab].analysis
                    ? this.props[this.props.activeTab].analysis.type
                    : []
                }
              />
            )} */}
            {this.props.searchState.drawersVisibility.resultsAnalysis && (
              <NewResultsAnalysis
                analysisStatus={this.props.commonAnalysis?.analysisStatus}
                statusesDistribution={
                  this.props.commonAnalysis?.analysis
                    ? this.props.commonAnalysis?.analysis?.STATUS?.data
                    : []
                }
                statusLoading={
                  this.props.commonAnalysis?.analysis
                    ? this.props.commonAnalysis?.analysis?.STATUS?.loading
                    : false
                }
                classesDistribution={
                  this.props.commonAnalysis.analysis
                    ? this.props.commonAnalysis?.analysis?.CLASS?.data
                    : []
                }
                classLoading={
                  this.props.commonAnalysis?.analysis
                    ? this.props.commonAnalysis?.analysis?.CLASS?.loading
                    : false
                }
                typesDistribution={
                  this.props.commonAnalysis.analysis
                    ? this.props.commonAnalysis?.analysis?.TYPE?.data
                    : []
                }
                typesLoading={
                  this.props.commonAnalysis?.analysis
                    ? this.props.commonAnalysis?.analysis?.TYPE?.loading
                    : false
                }
                proprietorsDistribution={
                  this.props.commonAnalysis.analysis
                    ? this.props.commonAnalysis?.analysis?.PROPRIETOR?.data
                    : []
                }
                proprietorsLoading={
                  this.props.commonAnalysis?.analysis
                    ? this.props.commonAnalysis?.analysis?.PROPRIETOR?.loading
                    : false
                }
                statesDistribution={
                  this.props.commonAnalysis.analysis
                    ? this.props.commonAnalysis?.analysis?.STATE?.data
                    : []
                }
                statesLoading={
                  this.props.commonAnalysis?.analysis
                    ? this.props.commonAnalysis?.analysis?.STATE?.loading
                    : false
                }
                handleGetAnalysis={this.handleGetAnalysis}
              />
            )}
          </div>
        </Drawer>

        <Drawer
          // style={!this.props.reportExplicitTrigger2 && { display: "none" }}
          {...(this.props.isTourOpen
            ? {
                style: !this.props.reportExplicitTrigger2 && {
                  display: "none",
                },
              }
            : {})}
          className="search-report-drawer"
          title={<h2>Custom Report Details</h2>}
          placement="right"
          width={400}
          closable={false}
          open={
            this.props.searchState.drawersVisibility.customReport ||
            this.props.reportExplicitTrigger1
          }
          onClose={() =>
            this.changeStateValue_L1("drawersVisibility", "customReport", false)
          }
        >
          <form>
            {this.props.reportAvailabilityStatus === SUCCESS &&
              this.props.reportAvailability &&
              this.props.reportAvailability.id && (
                <>
                  Choose an action:
                  <br />
                  <Radio.Group
                    value={this.props.searchState.customReportData.operation}
                    onChange={(event) =>
                      this.changeStateValue_L1(
                        "customReportData",
                        "operation",
                        event.target.value,
                      )
                    }
                  >
                    <Radio value="APPEND">Add marks to existing report</Radio>
                    <br />
                    <Radio value="CLEAR">
                      Remove previous marks from the report
                    </Radio>
                  </Radio.Group>
                  <br />
                  <br />
                </>
              )}
            Choose your preferred report type:
            <br />
            <Radio.Group
              value={this.props.searchState.customReportData.reportType}
              onChange={(event) =>
                this.changeStateValue_L1(
                  "customReportData",
                  "reportType",
                  event.target.value,
                )
              }
            >
              <Radio value="URGENT">Urgent Report</Radio>
              <br />
              <Radio value="REALTIME">Realtime report</Radio>
            </Radio.Group>
            <br />
            <br />
            {this.props.searchState.customReportData.selectAll ||
            TS_multiwordSearch.some(
              (x) =>
                this.props.searchState.customReportData[x.toLocaleLowerCase()]
                  .select_all,
            ) ? (
              this.props.searchState.customReportData.reportType !==
                "REALTIME" && (
                <>
                  {" "}
                  Choose your preferred report format: <br />
                  <Checkbox
                    checked={this.props.searchState.customReportData.reportFormat.includes(
                      EXCEL,
                    )}
                    onChange={(event) => {
                      if (event.target.checked) {
                        this.changeStateValue_L1(
                          "customReportData",
                          "reportFormat",
                          [
                            ...this.props.searchState.customReportData
                              .reportFormat,
                            EXCEL,
                          ],
                        );
                      } else {
                        this.changeStateValue_L1(
                          "customReportData",
                          "reportFormat",
                          this.props.searchState.customReportData.reportFormat.filter(
                            (e) => e !== EXCEL,
                          ),
                        );
                      }
                    }}
                  >
                    EXCEL
                  </Checkbox>
                  <br />
                  <Tooltip
                    placement="left"
                    title="Generated bifurcated excel based on each Search algorithm"
                  >
                    <Checkbox
                      checked={this.props.searchState.customReportData.reportFormat.includes(
                        BI_EXCEL,
                      )}
                      onChange={(event) => {
                        if (event.target.checked) {
                          this.changeStateValue_L1(
                            "customReportData",
                            "reportFormat",
                            [
                              ...this.props.searchState.customReportData
                                .reportFormat,
                              BI_EXCEL,
                            ],
                          );
                        } else {
                          this.changeStateValue_L1(
                            "customReportData",
                            "reportFormat",
                            this.props.searchState.customReportData.reportFormat.filter(
                              (e) => e !== BI_EXCEL,
                            ),
                          );
                        }
                      }}
                    >
                      BIFURCATED EXCEL
                    </Checkbox>
                  </Tooltip>
                  <br />
                  <br />
                  <Alert
                    type="warning"
                    message="Note: If no format is selected, the report won't be sent to your e-mail but the changes will get reflected in the reports section."
                  />
                </>
              )
            ) : (
              <>
                {" "}
                Choose your preferred report format: <br />
                <Checkbox
                  checked={this.props.searchState.customReportData.reportFormat.includes(
                    PDF,
                  )}
                  onChange={(event) => {
                    if (event.target.checked) {
                      this.changeStateValue_L1(
                        "customReportData",
                        "reportFormat",
                        [
                          ...this.props.searchState.customReportData
                            .reportFormat,
                          PDF,
                        ],
                      );
                    } else {
                      this.changeStateValue_L1(
                        "customReportData",
                        "reportFormat",
                        this.props.searchState.customReportData.reportFormat.filter(
                          (e) => e !== PDF,
                        ),
                      );
                    }
                  }}
                >
                  PDF
                </Checkbox>
                <br />
                <Checkbox
                  checked={this.props.searchState.customReportData.reportFormat.includes(
                    EXCEL,
                  )}
                  onChange={(event) => {
                    if (event.target.checked) {
                      this.changeStateValue_L1(
                        "customReportData",
                        "reportFormat",
                        [
                          ...this.props.searchState.customReportData
                            .reportFormat,
                          EXCEL,
                        ],
                      );
                    } else {
                      this.changeStateValue_L1(
                        "customReportData",
                        "reportFormat",
                        this.props.searchState.customReportData.reportFormat.filter(
                          (e) => e !== EXCEL,
                        ),
                      );
                    }
                  }}
                >
                  EXCEL
                </Checkbox>
                <br />
                <br />
                Enter the name of your report:
                <br />
                <Input
                  disabled={
                    !(
                      this.props.searchState.customReportData.reportFormat.includes(
                        EXCEL,
                      ) ||
                      this.props.searchState.customReportData.reportFormat.includes(
                        PDF,
                      )
                    )
                  }
                  onChange={(event) => {
                    if (
                      /^[ \w!?:_\-()@#,;<>[\]{}|]*$/g.test(event.target.value)
                    ) {
                      this.changeStateValue_L1(
                        "customReportData",
                        "reportName",
                        event.target.value,
                      );
                    }
                  }}
                  value={this.props.searchState.customReportData.reportName}
                  placeholder="Report's name (Optional)"
                />
                <br />
                <br />
                <Alert
                  type="warning"
                  message="Note: If no format is selected, the report won't be sent to your e-mail but the changes will get reflected in the reports section."
                />
              </>
            )}
            <br />
            <br />
            <Button
              type="primary"
              ghost
              style={{ marginRight: "10px" }}
              disabled={
                this.props.reportAvailabilityStatus !== SUCCESS ||
                (this.props.reportAvailability &&
                  this.props.reportAvailability.id &&
                  this.props.reportAvailability.available === false)
              }
              onClick={() => this.handleConfirmReport_Debounced()}
            >
              Confirm
            </Button>
            <Button
              danger
              ghost
              onClick={() =>
                this.changeStateValue_L1(
                  "drawersVisibility",
                  "customReport",
                  false,
                )
              }
            >
              Cancel
            </Button>
          </form>
        </Drawer>

        {/* This Modal will open when the question mark in searches menu is clicked */}
        <TrademarksAlgosInfo
          visible={this.props.searchState.modalsVisibility.trademarksAlgosInfo}
          onClose={() =>
            this.changeStateValue_L1(
              "modalsVisibility",
              "trademarksAlgosInfo",
              false,
            )
          }
        />

        {/* Search refresh reminder modal */}
        <Modal
          title="Search Refresh"
          closable={false}
          maskClosable={false}
          open={this.props.reminderStamp < new Date().getTime()}
          onOk={() => this.handleSearchRefresh(true)}
          onCancel={() => this.handleSearchRefresh(false)}
          okText="Refresh"
          cancelText="Snooze"
        >
          <p>
            It has been more than 2 hours since you searched for{" "}
            <strong>{`${this.props?.userTrademark?.term ?? ""}`}</strong>.
          </p>
          <p>
            There is a chance that some new trademarks has been added to the
            database.
          </p>
          <p>
            Do you want us to refresh the data for you or do you want this
            reminder to be snoozed?
          </p>
          <br />
          <br />
          <Alert
            type="warning"
            message={
              <div>
                Note:
                <br />
                <p>
                  <strong>1.</strong> Refreshing the data will take you to the
                  first page.
                </p>
                <p>
                  <strong>2.</strong> Marks selected for the report will remain
                  selected.
                </p>
                <p>
                  <strong>3.</strong> Your filters will reset but they will stay
                  selected in the dropdowns so you can simply click apply if you
                  ant to filter them.
                </p>
              </div>
            }
          />
        </Modal>

        {/* Need Search refresh to chane query reminder modal */}
        <Modal
          title="Need Search Refresh"
          closable={false}
          maskClosable={false}
          open={this.props.searchState.modalsVisibility.needSearchRefresh}
          onOk={() => {
            this.handleSearchRefresh(true);
            this.changeStateValue_L1(
              "modalsVisibility",
              "needSearchRefresh",
              false,
            );
          }}
          onCancel={() =>
            this.changeStateValue_L1(
              "modalsVisibility",
              "needSearchRefresh",
              false,
            )
          }
          okText="Refresh"
          cancelText="Not now"
        >
          <p>
            It has been more than 2 hours since you searched or refreshed search
            for <strong>{`${this.props?.userTrademark?.term ?? ""}`}</strong>.
          </p>
          <p>
            To change the queries you need to refresh the search first. After
            that you can change the query.
          </p>
          <p>Do you want us to refresh the data for you?</p>
          <br />
          <br />
          <Alert
            type="warning"
            message={
              <div>
                Note:
                <br />
                <p>
                  <strong>1.</strong> Refreshing the data will take you to the
                  first page.
                </p>
                <p>
                  <strong>2.</strong> Marks selected for the report will remain
                  selected.
                </p>
                <p>
                  <strong>3.</strong> Your filters will reset but they will stay
                  selected in the dropdowns so you can simply click apply if you
                  ant to filter them.
                </p>
              </div>
            }
          />
        </Modal>
      </>
    );
  }

  componentWillUnmount() {}
}

const mapDispatchToProps = {
  getSearchResults,
  getTrademarkReportStatus,
  TPAction,
  TPSAction,
  postReport,
  patchTrademarkReport,
  logOut,
  handleSearchInfoDispatch,
  getImageSearchResults,
  getSearchAllFilters,
  resetAllSearchFilter,
  changeSearchDashboardSearchType,
  changeSearchDashboardSearchValue,
  fetchSearchAnalysisNew,
  updateTrademarkSearchFilterApplied,
};

function mapStateToProps(state) {
  const {
    Fuzzy,
    Contains,
    Wildcard,
    Phonetic,
    Initials,
    Boolean,
    Prefix,
    Suffix,
    ImageSearch,
    GOODS_SERVICE,
    imageFile,
    trademarksSearchAlgo,
    activeTab,
    userTrademark,
    multiwordTrademark,
    filters,
    selectedView,
    axiosCancelSearches,
    reportAvailability,
    reportAvailabilityStatus,
    searchCounter,
    filtersStatus,
    reminderStamp,
    axiosCancelAnalysis,
    axiosCancelFilters,
    searchStamp,
    searchState,
    commonAnalysis,
  } = state.searchState.trademarksSearch;

  const { searchFilters } = state.searchState;
  return deepFreeze({
    Fuzzy,
    Contains,
    Wildcard,
    Phonetic,
    Initials,
    Boolean,
    Prefix,
    Suffix,
    ImageSearch,
    GOODS_SERVICE,
    imageFile,
    trademarksSearchAlgo,
    activeTab,
    userTrademark,
    multiwordTrademark,
    filters,
    selectedView,
    axiosCancelSearches,
    reportAvailability,
    reportAvailabilityStatus,
    searchCounter,
    filtersStatus,
    reminderStamp,
    searchPath: state.searchState.searchBar.searchPath,
    axiosCancelAnalysis,
    axiosCancelFilters,
    searchStamp,
    searchAlert: state.alertMessages.search,
    searchState,
    searchFilters,
    commonAnalysis,
    updateTrademarkSearchFilterApplied,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(TrademarksSearch);
