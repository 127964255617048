import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getDashboard,
	getDashboardAnalytics,
} from "../../../../redux/actions/managerActions/profile/actions";
import { logOut } from "../../../../redux/actions/commonActions/actions";
import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";
import {
	CalendarTwoTone,
	ClockCircleTwoTone,
	FileTwoTone,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { LOADING } from "../../../../constants/commonConstants/generalConstants";
import { Card, Col, Row, Typography } from "antd";
import TrademarkAnalysis from "./Trademark-Analysis";
import CalendarComponent from "./ParentComponent/CalendarComponent";

const { Text, Title } = Typography;

const OpposedTrademarkDashboardNew = () => {
	const dispatch = useDispatch();

	const { opposedTmDashboard, opposedTmDashboardStatus } = useSelector(
		(state) => state.managerState.profileState
	);

	const { userSubscriptions } = useSelector((state) => state.userSubscriptions);

	useEffect(() => {
		if (localStorage.getItem("token")) {
			if (opposedTmDashboard === null) {
				dispatch(getDashboard("trademark", "opposed"));
				dispatch(getDashboardAnalytics("trademark", "opposed"));
			}
		} else {
			dispatch(logOut());
		}
	}, [opposedTmDashboard, getDashboard, getDashboardAnalytics, logOut]); // Dependencies for effect

	const informationSection = () => (
		<Row>
			<Col span={24} className="alignR">
				<Text strong style={{ fontSize: "14px", color: "#5aac44" }}>
					*Information shown here is not more than{" "}
					{checkValue(opposedTmDashboard)
						? opposedTmDashboard.trademark.days_old
						: 0}{" "}
					day(s) old.
				</Text>
			</Col>
		</Row>
	);

	const cardsSection = () => (
		<Row gutter={16} className="m-top-20">
			<Col className="gutter-row" span={8}>
				<Link to="/manager/trademark/opposed-trademark/total-marks">
					<Card
						hoverable
						bodyStyle={{
							textAlign: "center",
							height: "120px",
							padding: "15px",
						}}
						loading={opposedTmDashboardStatus === LOADING ? true : false}>
						<Row>
							<Col span={4}>
								<FileTwoTone style={{ fontSize: "35px" }} />
							</Col>
							<Col span={20}>
								<Title level={4} style={{ fontSize: "14px" }}>
									TOTAL APPLICATION
								</Title>
								<Text strong style={{ fontSize: "14px" }}>
									{checkValue(opposedTmDashboard)
										? opposedTmDashboard.trademark.managed_trademarks_count
										: 0}
								</Text>
							</Col>
						</Row>
					</Card>
				</Link>
			</Col>
			<Col className="gutter-row" span={8}>
				<Link to="/manager/trademark/opposed-trademark/reply-to-correspondence">
					<Card
						hoverable
						bodyStyle={{
							textAlign: "center",
							height: "120px",
							padding: "15px",
						}}
						loading={opposedTmDashboardStatus === LOADING ? true : false}>
						<Row>
							<Col span={4}>
								<ClockCircleTwoTone style={{ fontSize: "35px" }} />
							</Col>
							<Col span={20}>
								<Title level={4} style={{ fontSize: "14px" }}>
									DEADLINE MANAGEMENT
								</Title>
								<div>
									<div>
										<Text
											strong
											className={
												checkValue(userSubscriptions)
													? userSubscriptions.manager.includes(
															"PSEUDO_TRADEMARK"
													  )
														? ""
														: "display_none"
													: "display_none"
											}>
											IP INDIA -{" "}
										</Text>
										{userSubscriptions &&
											userSubscriptions.manager.includes("TRADEMARK") && (
												<Text strong style={{ fontSize: "14px" }}>
													{checkValue(opposedTmDashboard)
														? opposedTmDashboard.trademark
																.pending_reply_ip_india_count
														: 0}
												</Text>
											)}
									</div>
									<div>
										<Text
											strong
											className={
												checkValue(userSubscriptions)
													? userSubscriptions.manager.includes(
															"PSEUDO_TRADEMARK"
													  )
														? ""
														: "display_none"
													: "display_none"
											}>
											CUSTOM -{" "}
										</Text>
										{userSubscriptions &&
											userSubscriptions.manager.includes(
												"PSEUDO_TRADEMARK"
											) && (
												<Text strong style={{ fontSize: "14px" }}>
													{checkValue(opposedTmDashboard)
														? opposedTmDashboard.trademark
																.pending_reply_own_data_count
														: 0}
												</Text>
											)}
									</div>
								</div>
							</Col>
						</Row>
					</Card>
				</Link>
			</Col>
			<Col className="gutter-row" span={8}>
				<Link to="/manager/trademark/opposed-trademark/upcoming-hearings">
					<Card
						hoverable
						bodyStyle={{
							textAlign: "center",
							height: "120px",
							padding: "15px",
						}}
						loading={opposedTmDashboardStatus === LOADING ? true : false}>
						<Row gutter={[8, 8]}>
							<Col xs={24} sm={24} md={4} style={{ textAlign: "center" }}>
								<CalendarTwoTone style={{ fontSize: "35px" }} />
							</Col>
							<Col span={20}>
								<Title
									level={4}
									style={{ fontSize: "14px", marginBottom: "10px" }}>
									UPCOMING HEARINGS
								</Title>
								<Row gutter={[8, 8]}>
									<Col xs={24} sm={12} style={{ textAlign: "left" }}>
										<Text strong>
											Opposition (Cause list):
											<Text strong>
												{checkValue(opposedTmDashboard)
													? " " +
													  opposedTmDashboard.trademark
															.opposition_hearings_count
													: " 0"}
											</Text>
										</Text>
									</Col>
									<Col xs={24} sm={12} style={{ textAlign: "left" }}>
										<Text strong>
											Opposition (IP India):
											<Text strong>
												{checkValue(
													opposedTmDashboard?.trademark
														?.opposition_hearings_based_ip_india_count
												)
													? " " +
													  opposedTmDashboard.trademark
															.opposition_hearings_based_ip_india_count
													: " 0"}
											</Text>
										</Text>
									</Col>
									<Col xs={24} sm={12} style={{ textAlign: "left" }}>
										<Text strong>
											TLA (Cause list):
											<Text strong>
												{checkValue(opposedTmDashboard)
													? " " +
													  opposedTmDashboard.trademark.tla_hearings_count
													: " 0"}
											</Text>
										</Text>
									</Col>
									<Col xs={24} sm={12} style={{ textAlign: "left" }}>
										<Text strong>
											TLA (IP India):
											<Text strong>
												{checkValue(opposedTmDashboard)
													? " " +
													  opposedTmDashboard.trademark
															.tla_hearings_based_on_ip_india_count
													: " 0"}
											</Text>
										</Text>
									</Col>
								</Row>
							</Col>
						</Row>
					</Card>
				</Link>
			</Col>
		</Row>
	);

    const calendarSection = () => <CalendarComponent />;

	const analysisSection = () => (
		<Row className="m-top-20">
			<Col span={24}>
				<TrademarkAnalysis />
			</Col>
		</Row>
	);

	return (
		<div style={{ padding: "10px" }}>
			{/* Information Section Starts*/}
			{informationSection()}
			{/* Information Section Ends */}

			{/* Cards Section Starts */}
			{cardsSection()}
			{/* Cards Section Ends */}

            {/* Calendar Section Starts */}
			{calendarSection()}
			{/* Calendar Section Ends */}

			{/* Analysis Section Starts */}
			{analysisSection()}
			{/* Analysis Section Ends */}
		</div>
	);
};

export default OpposedTrademarkDashboardNew;
