import React from "react";
import { Button, Drawer, Row, Col, Space, Popconfirm } from "antd";
import { CloseOutlined, FilterFilled } from "@ant-design/icons";
import TemplateSection from "./template/TemplateSection";
import FilterSection from "./FilterSection";
import { date_format, default_template_page } from "../../../utils/utils";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  changeFilterAndOr,
  changeFilterApplied,
  changeListExcludeDropdownValues,
  changeListSelectedDropdownValues,
  changeSearchType,
  changeSearchValue,
  changeTemplateId,
  createWatchTemplate,
  getWatchFilterTemplate,
  updateBooleanFilters,
  updateDateProposedValues,
  updateDateValues,
  updateNoTags,
  updateSimilarityConfig,
  updateWatchTemplate,
  updateWholeFilterConfig,
  updateimageTextPercentageConfig,
  updatethreatImageTextPercentageConfig,
} from "../../../../../../redux/actions/watchActions/watchActions";
import {
  USER_TEMPLATE_TYPE,
  RECOMMENDED_TEMPLATE_TYPE,
} from "../../../../../../redux/actions/watchActions/types";
import { getListLayoutData } from "../../../../../../redux/actions/watchActions/listDashboardActions";

const MoreFilterDrawer = (props) => {
  const { open, setOpen, journal, setFilterApplied, isAnyFilterSelected } =
    props;
  const dispatch = useDispatch();
  const [selectedTemplate, setSelectedTemplate] = React.useState([]);
  let urlKey = window.location.pathname.split("/")[2];
  let dashboardLayout = window.location.pathname.split("/")[3];

  const { USER, RECOMMENDED, current_template_id, template_status } =
    useSelector(
      (state) => state.watchState.watchTemplate[dashboardLayout][urlKey].config,
    );

  // Extra state to achieve data from template and later on store it on real parent filter.

  const [searchType, setSearchType] = React.useState("Application No/Name");
  const [searchValue, setSearchValue] = React.useState("");
  const [excludeReported, setExcludeReported] = React.useState(false);

  // Advanced filter state ------------------------------------------------------------
  const [threatAttorneyValue, setThreatAttorneyValue] = React.useState([]);
  const [proprietors, setProprietors] = React.useState([]);
  const [threatProprietors, setThreatProprietors] = React.useState([]);
  const [excludeMarkProprietors, setExcludeMarkProprietors] = React.useState(
    [],
  );
  const [excludeThreatProprietors, setExcludeThreatProprietors] =
    React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [tmClass, setTMClass] = React.useState([]);
  const [threatTMClass, setThreatClass] = React.useState([]);
  const [similarity, setSimilarity] = React.useState({
    from: 0,
    to: 100,
  });
  const [yourDateOfApplication, setYourDateOfApplication] = React.useState({
    from: "",
    to: "",
    dateString: "",
  });
  const [threatDateOfApplication, setThreatDateOfApplication] = React.useState({
    from: "",
    to: "",
    dateString: "",
  });
  const [yourDateOfUsage, setYourDateOfUsage] = React.useState({
    from: "",
    to: "",
    dateString: "",
  });
  const [threatDateOfUsage, setThreatDateOfUsage] = React.useState({
    from: "",
    to: "",
    dateString: "",
  });
  const [yourDateOfUsageProposed, setYourDateOfUsageProposed] =
    React.useState(false);
  const [threatDateOfUsageProposed, setThreatDateOfUsageProposed] =
    React.useState(false);
  const [noTags, setNoTags] = React.useState(false);
  const [sameClassInfringement, setSameClassInfringement] =
    React.useState(false);
  const [excludeSameClassInfringement, setExcludeSameClassInfringement] =
    React.useState(false);

  // Dashboard filter state ------------------------------------------------------------
  const [tmTypes, setTMTypes] = React.useState([]);
  const [threatTMTypes, setThreatTMTypes] = React.useState([]);
  const [tmCategories, setTMCategories] = React.useState([]);
  const [threatTMCategories, setThreatTMCategories] = React.useState([]);
  const [tmOffices, setTMOffices] = React.useState([]);
  const [threatTMOffices, setThreatTMOffices] = React.useState([]);
  const [infringement, setInfringement] = React.useState([]);
  const [shortlisted, setShortlisted] = React.useState(false);
  const [excludeShortlisted, setExcludeShortlisted] = React.useState(false);

  const [copyOfFilterConfig, setCopyOfFilterConfig] = React.useState({});

  // AND - OR  states
  const [andOrTags, setAndOrTags] = React.useState(false);
  const [andOrClass, setAndOrClass] = React.useState(false);
  const [andOrThreatClass, setAndOrThreatClass] = React.useState(false);

  // For Image Similarity
  const [imageTextPercentage, setImageTextPercentage] = React.useState({
    from: 0,
    to: 100,
  });
  const [threatImageTextPercentage, setThreatImageTextPercentage] =
    React.useState({
      from: 0,
      to: 100,
    });

  const {
    markTypeConfig,
    threatTypeConfig,
    markCategoryConfig,
    threatCategoryConfig,
    markOfficeConfig,
    threatOfficeConfig,
    infringementConfig,
    markproprietorConfig,
    threatproprietorConfig,
    markClassConfig,
    threatClassConfig,
    tagsConfig,
    threatAttorneyConfig,
  } = useSelector(
    (state) => state.watchState.watchFilters[dashboardLayout][urlKey].config,
  );

  const wholeFilterConfig = useSelector(
    (state) => state.watchState.watchFilters[dashboardLayout][urlKey].config,
  );

  React.useEffect(() => {
    setCopyOfFilterConfig({ ...wholeFilterConfig });
  }, []);

  const updateReduxStore = () => {
    // code to update redux store with the help of states
    dispatch(changeSearchType(searchType));
    dispatch(changeSearchValue(searchValue));
    dispatch(updateNoTags(noTags));
    dispatch(updateBooleanFilters("excludeReportedConfig", excludeReported));
    dispatch(updateBooleanFilters("shortlistedConfig", shortlisted));
    dispatch(
      updateBooleanFilters("excludeShortlistedConfig", excludeShortlisted),
    );
    dispatch(
      updateBooleanFilters(
        "sameClassInfringementConfig",
        sameClassInfringement,
      ),
    );
    dispatch(
      updateBooleanFilters(
        "excludeSameClassInfringementConfig",
        excludeSameClassInfringement,
      ),
    );
    dispatch(changeFilterAndOr("tags", andOrTags));
    dispatch(changeFilterAndOr("mark_classes", andOrClass));
    dispatch(changeFilterAndOr("threat_classes", andOrThreatClass));
    dispatch(updateSimilarityConfig(similarity));
    dispatch(
      updateDateValues("your_date_of_application", yourDateOfApplication),
    );
    dispatch(
      updateDateValues("threat_date_of_application", threatDateOfApplication),
    );
    dispatch(updateDateValues("your_date_of_usage", yourDateOfUsage));
    dispatch(updateDateValues("threat_date_of_usage", threatDateOfUsage));
    dispatch(
      updateDateProposedValues("your_date_of_usage", yourDateOfUsageProposed),
    );
    dispatch(
      updateDateProposedValues(
        "threat_date_of_usage",
        threatDateOfUsageProposed,
      ),
    );

    const getArrayOfObjectWithValues = (
      dataArray,
      values,
      functionName = "Number",
    ) => {
      const existsInDataArray = (val) =>
        functionName === "String"
          ? values.includes(val.toString())
          : values.includes(val);
      let result = dataArray.filter((obj) => existsInDataArray(obj.value));
      return result;
    };
    dispatch(
      changeListSelectedDropdownValues(
        "threat_attorney",
        getArrayOfObjectWithValues(
          [
            ...threatAttorneyConfig.data,
            ...threatAttorneyConfig.selectedValues,
          ],
          threatAttorneyValue,
        ),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "mark_proprietors",
        getArrayOfObjectWithValues(
          [
            ...markproprietorConfig.data,
            ...markproprietorConfig.selectedValues,
          ],
          proprietors,
        ),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "threat_proprietors",
        getArrayOfObjectWithValues(
          [
            ...threatproprietorConfig.data,
            ...threatproprietorConfig.selectedValues,
          ],
          threatProprietors,
        ),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "tags",
        getArrayOfObjectWithValues(
          [...tagsConfig.data, ...tagsConfig.selectedValues],
          tags,
        ),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "mark_classes",
        getArrayOfObjectWithValues(markClassConfig.data, tmClass),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "threat_classes",
        getArrayOfObjectWithValues(threatClassConfig.data, threatTMClass),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "mark_types",
        getArrayOfObjectWithValues(markTypeConfig.data, tmTypes, "String"),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "threat_types",
        getArrayOfObjectWithValues(
          threatTypeConfig.data,
          threatTMTypes,
          "String",
        ),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "mark_categories",
        getArrayOfObjectWithValues(
          markCategoryConfig.data,
          tmCategories,
          "String",
        ),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "threat_categories",
        getArrayOfObjectWithValues(
          threatCategoryConfig.data,
          threatTMCategories,
          "String",
        ),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "mark_offices",
        getArrayOfObjectWithValues(markOfficeConfig.data, tmOffices, "String"),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "threat_offices",
        getArrayOfObjectWithValues(
          threatOfficeConfig.data,
          threatTMOffices,
          "String",
        ),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "threat_sources",
        getArrayOfObjectWithValues(
          infringementConfig.data,
          infringement,
          "String",
        ),
      ),
    );

    dispatch(
      changeListExcludeDropdownValues(
        "mark_proprietors",
        getArrayOfObjectWithValues(
          [...markproprietorConfig.data, ...markproprietorConfig.excludeValues],
          excludeMarkProprietors,
        ),
      ),
    );
    dispatch(
      changeListExcludeDropdownValues(
        "threat_proprietors",
        getArrayOfObjectWithValues(
          [
            ...threatproprietorConfig.data,
            ...threatproprietorConfig.excludeValues,
          ],
          excludeThreatProprietors,
        ),
      ),
    );

    if (urlKey === "image_similarity") {
      dispatch(updateimageTextPercentageConfig(imageTextPercentage));
      dispatch(
        updatethreatImageTextPercentageConfig(threatImageTextPercentage),
      );
    }
  };

  const getValueFromParentProps = () => {
    setTMTypes(props.tmTypes);
    setThreatTMTypes(props.threatTMTypes);
    setTMCategories(props.tmCategories);
    setThreatTMCategories(props.threatTMCategories);
    setTMOffices(props.tmOffices);
    setThreatTMOffices(props.threatTMOffices);
    setInfringement(props.infringement);
    setSearchType(props.searchType);
    setSearchValue(props.searchValue);
    setExcludeReported(props.excludeReported);
    setThreatAttorneyValue(props.threatAttorneyValue);
    setProprietors(props.proprietors);
    setThreatProprietors(props.threatProprietors);
    setExcludeMarkProprietors(props.excludeMarkProprietors);
    setExcludeThreatProprietors(props.excludeThreatProprietors);
    setTags(props.tags);
    setTMClass(props.tmClass);
    setThreatClass(props.threatTMClass);
    setSimilarity(props.similarity);
    setYourDateOfApplication(props.yourDateOfApplication);
    setThreatDateOfApplication(props.threatDateOfApplication);
    setYourDateOfUsage(props.yourDateOfUsage);
    setThreatDateOfUsage(props.threatDateOfUsage);
    setYourDateOfUsageProposed(props.yourDateOfUsageProposed);
    setThreatDateOfUsageProposed(props.threatDateOfUsageProposed);
    setNoTags(props.noTags);
    setSameClassInfringement(props.sameClassInfringement);
    setExcludeSameClassInfringement(props.excludeSameClassInfringement);
    setShortlisted(props.shortlisted);
    setExcludeShortlisted(props.excludeShortlisted);
    setAndOrTags(props.andOrTags);
    setAndOrClass(props.andOrClass);
    setAndOrThreatClass(props.andOrThreatClass);
    setImageTextPercentage(props.imageTextPercentage);
    setThreatImageTextPercentage(props.threatImageTextPercentage);
  };

  React.useEffect(() => {
    // to fetch all the values from dashboard whenever props changes
    getValueFromParentProps();
  }, [
    props.tmTypes,
    props.threatTMTypes,
    props.tmCategories,
    props.threatTMCategories,
    props.tmOffices,
    props.threatTMOffices,
    props.infringement,
    props.searchType,
    props.searchValue,
    props.excludeReported,
    props.threatAttorneyValue,
    props.proprietors,
    props.threatProprietors,
    props.excludeMarkProprietors,
    props.excludeThreatProprietors,
    props.tags,
    props.tmClass,
    props.threatTMClass,
    props.similarity,
    props.yourDateOfApplication,
    props.threatDateOfApplication,
    props.yourDateOfUsage,
    props.threatDateOfUsage,
    props.yourDateOfUsageProposed,
    props.threatDateOfUsageProposed,
    props.noTags,
    props.sameClassInfringement,
    props.excludeSameClassInfringement,
    props.shortlisted,
    props.excludeShortlisted,
    props.andOrTags,
    props.andOrClass,
    props.andOrThreatClass,
    props.imageTextPercentage,
    props.threatImageTextPercentage,
  ]);

  React.useState(() => {
    dispatch(
      getWatchFilterTemplate(
        USER_TEMPLATE_TYPE,
        `${props.getFilterPath()}/`,
        0,
        default_template_page,
      ),
    );
    dispatch(
      getWatchFilterTemplate(
        RECOMMENDED_TEMPLATE_TYPE,
        `${props.getFilterPath()}/`,
        0,
        default_template_page,
      ),
    );
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  const getRealSearchParameters = () => {
    const defaultSearchParameters = {
      contains_term: {
        value: null,
      },
      mark_desc_contains_term: {
        value: null,
      },
      threat_desc_contains_term: {
        value: null,
      },
    };

    switch (searchType) {
      case "Application No/Name":
        return Object.assign({}, defaultSearchParameters, {
          contains_term: {
            value: searchValue,
          },
        });
      case "Your Mark Description Search":
        return Object.assign({}, defaultSearchParameters, {
          mark_desc_contains_term: {
            value: searchValue,
          },
        });
      case "Your Threat Description Search":
        return Object.assign({}, defaultSearchParameters, {
          threat_desc_contains_term: {
            value: searchValue,
          },
        });
      case "Description Search":
        return Object.assign({}, defaultSearchParameters, {
          threat_desc_contains_term: {
            value: searchValue,
          },
        });
      default:
        return defaultSearchParameters;
    }
  };

  const updateParentStates = () => {
    props.setTMTypes(tmTypes);
    props.setThreatTMTypes(threatTMTypes);
    props.setTMCategories(tmCategories);
    props.setThreatTMCategories(threatTMCategories);
    props.setTMOffices(tmOffices);
    props.setThreatTMOffices(threatTMOffices);
    props.setInfringement(infringement);
    props.setSearchType(searchType);
    props.setSearchValue(searchValue);
    props.setExcludeReported(excludeReported);
    props.setThreatAttorneyValue(threatAttorneyValue);
    props.setProprietors(proprietors);
    props.setThreatProprietors(threatProprietors);
    props.setExcludeMarkProprietors(excludeMarkProprietors);
    props.setExcludeThreatProprietors(excludeThreatProprietors);
    props.setTags(tags);
    props.setTMClass(tmClass);
    props.setThreatClass(threatTMClass);
    props.setSimilarity(similarity);
    props.setYourDateOfApplication(yourDateOfApplication);
    props.setThreatDateOfApplication(threatDateOfApplication);
    props.setYourDateOfUsage(yourDateOfUsage);
    props.setThreatDateOfUsage(threatDateOfUsage);
    props.setYourDateOfUsageProposed(yourDateOfUsageProposed);
    props.setThreatDateOfUsageProposed(threatDateOfUsageProposed);
    props.setNoTags(noTags);
    props.setSameClassInfringement(sameClassInfringement);
    props.setExcludeSameClassInfringement(excludeSameClassInfringement);
    props.setAndOrTags(andOrTags);
    props.setAndOrClass(andOrClass);
    props.setAndOrThreatClass(andOrThreatClass);
    props.setShortlisted(shortlisted);
    props.setExcludeShortlisted(excludeShortlisted);
    props.setExcludeReported(excludeReported);
    props.setImageTextPercentage(imageTextPercentage);
    props.setThreatImageTextPercentage(threatImageTextPercentage);
  };

  const generateFilterObject = () => {
    const filterObject = {
      tags: {
        value: tags,
        logic: andOrTags ? "AND" : "OR",
      },
      no_tags: {
        value: noTags,
      },
      mark_classes: {
        value: tmClass,
        logic: andOrClass ? "AND" : "OR",
      },
      threat_classes: {
        value: threatTMClass,
        logic: andOrThreatClass ? "AND" : "OR",
      },
      threat_sources: {
        value: infringement,
      },
      threat_similarity: {
        value: {
          to: similarity.to,
          from: similarity.from,
        },
      },
      mark_types: {
        value: tmTypes,
      },
      threat_types: {
        value: threatTMTypes,
      },
      mark_proprietors: {
        value: proprietors,
      },
      threat_proprietors: {
        value: threatProprietors,
      },
      mark_categories: {
        value: tmCategories,
      },
      threat_categories: {
        value: threatTMCategories,
      },
      mark_offices: {
        value: tmOffices,
      },
      threat_offices: {
        value: threatTMOffices,
      },
      mark_date_of_application: {
        value: {
          from: yourDateOfApplication.from
            ? dayjs(yourDateOfApplication.from).format(date_format)
            : "",
          to: yourDateOfApplication.to
            ? dayjs(yourDateOfApplication.to).format(date_format)
            : "",
        },
      },
      threat_date_of_application: {
        value: {
          from: threatDateOfApplication.from
            ? dayjs(threatDateOfApplication.from).format(date_format)
            : "",
          to: threatDateOfApplication.to
            ? dayjs(threatDateOfApplication.to).format(date_format)
            : "",
        },
      },
      mark_date_of_usage: {
        value: {
          from: yourDateOfUsage.from
            ? dayjs(yourDateOfUsage.from).format(date_format)
            : "",
          to: yourDateOfUsage.to
            ? dayjs(yourDateOfUsage.to).format(date_format)
            : "",
        },
      },
      threat_date_of_usage: {
        value: {
          from: threatDateOfUsage.from
            ? dayjs(threatDateOfUsage.from).format(date_format)
            : "",
          to: threatDateOfUsage.to
            ? dayjs(threatDateOfUsage.to).format(date_format)
            : "",
        },
      },
      mark_date_of_usage_proposed: {
        value: yourDateOfUsageProposed,
      },
      threat_date_of_usage_proposed: {
        value: threatDateOfUsageProposed,
      },
      red_band: {
        value: sameClassInfringement,
      },
      exclude_same_class: {
        value: excludeSameClassInfringement,
      },
      exclude_marks_wrt_custom_report: {
        value: excludeReported,
      },
      shortlisted: {
        value: shortlisted,
      },
      exclude_shortlisted: {
        value: excludeShortlisted,
      },
      exclude_mark_proprietors: {
        value: excludeMarkProprietors,
      },
      exclude_threat_proprietors: {
        value: excludeThreatProprietors,
      },
      threat_attorney: {
        value: threatAttorneyValue,
      },
      ...getRealSearchParameters(),
    };
    if (urlKey === "journals") {
      delete filterObject.threat_similarity;
      delete filterObject.threat_attorney
    }

    if (urlKey === "image_similarity") {
      filterObject.image_text_percentage = {
        value: {
          to: imageTextPercentage.to,
          from: imageTextPercentage.from,
        },
      };
      filterObject.threat_image_text_percentage = {
        value: {
          to: threatImageTextPercentage.to,
          from: threatImageTextPercentage.from,
        },
      };
    }

    return filterObject;
  };

  const handleApplyFilter = () => {
    setFilterApplied(true);
    updateParentStates();
    onClose();
    const filters = generateFilterObject();
    dispatch(getListLayoutData(journal, 1, true, filters));
    dispatch(changeFilterApplied(true));
    if (current_template_id) {
      updateReduxStore();
    }
  };

  const handleResetFilterAndTemplate = () => {
    dispatch(changeTemplateId(0));
    getValueFromParentProps();
    updateWholeFilterConfig(copyOfFilterConfig);
  };

  const parentProps = {
    journal: props.journal,
  };

  const localProps = {
    tmTypes,
    setTMTypes,
    threatTMTypes,
    setThreatTMTypes,
    tmCategories,
    setTMCategories,
    threatTMCategories,
    setThreatTMCategories,
    tmOffices,
    setTMOffices,
    threatTMOffices,
    setThreatTMOffices,
    infringement,
    setInfringement,
    threatAttorneyValue,
    setThreatAttorneyValue,
    proprietors,
    setProprietors,
    threatProprietors,
    setThreatProprietors,
    tags,
    setTags,
    tmClass,
    setTMClass,
    threatTMClass,
    setThreatClass,
    similarity,
    setSimilarity,
    yourDateOfApplication,
    setYourDateOfApplication,
    threatDateOfApplication,
    setThreatDateOfApplication,
    yourDateOfUsage,
    setYourDateOfUsage,
    threatDateOfUsage,
    setThreatDateOfUsage,
    yourDateOfUsageProposed,
    setYourDateOfUsageProposed,
    threatDateOfUsageProposed,
    setThreatDateOfUsageProposed,
    noTags,
    setNoTags,
    excludeMarkProprietors,
    setExcludeMarkProprietors,
    excludeThreatProprietors,
    setExcludeThreatProprietors,
    sameClassInfringement,
    setSameClassInfringement,
    excludeSameClassInfringement,
    setExcludeSameClassInfringement,
    shortlisted,
    setShortlisted,
    excludeShortlisted,
    setExcludeShortlisted,
    andOrTags,
    setAndOrTags,
    andOrClass,
    setAndOrClass,
    andOrThreatClass,
    setAndOrThreatClass,
    excludeReported,
    setExcludeReported,
    imageTextPercentage,
    setImageTextPercentage,
    threatImageTextPercentage,
    setThreatImageTextPercentage,
  };

  const handleTemplateSave = async (fileName, optionSelected, setFileName) => {
    let body = {
      name: fileName,
      template_key: `${props.getFilterPath()}/`,
      template_type: "USER",
      template_json: generateFilterObject(),
    };
    if (optionSelected === 2) {
      // Update
      const modifiedBody = {
        ...body,
        pk: current_template_id,
      };
      await dispatch(
        updateWatchTemplate(
          modifiedBody,
          USER_TEMPLATE_TYPE,
          `${props.getFilterPath()}/`,
          USER.pageNumber,
        ),
      );
    } else {
      // Save New
      await dispatch(
        createWatchTemplate(
          body,
          USER_TEMPLATE_TYPE,
          `${props.getFilterPath()}/`,
          USER.pageNumber,
        ),
      );
      setFileName("");
    }
  };

  const generateDateObject = (item) => {
    // Split the date string by '-' and rearrange it to YYYY-MM-DD for dayjs better functionality.
    const fromDateParts = item.value.from.split("-");
    const fromDate = `${fromDateParts[2]}-${fromDateParts[1]}-${fromDateParts[0]}`;

    const toDateParts = item.value.to.split("-");
    const toDate = `${toDateParts[2]}-${toDateParts[1]}-${toDateParts[0]}`;

    return {
      from: fromDate.length ? dayjs(fromDate) : "",
      to: toDate.length ? dayjs(toDate) : "",
      dateString:
        fromDate.length && toDate.length
          ? `${dayjs(fromDate).format(date_format)}to${dayjs(toDate).format(
              date_format,
            )}`
          : "",
    };
  };

  const getSearchTerm = (template) => {
    if (template.contains_term.value) {
      setSearchValue(template.contains_term.value);
      setSearchType("Application No/Name");
    } else if (template.mark_desc_contains_term.value) {
      setSearchValue(template.mark_desc_contains_term.value);
      setSearchType("Your Mark Description Search");
    } else if (template.threat_desc_contains_term.value) {
      setSearchValue(template.threat_desc_contains_term.value);
      if (urlKey === "journals") {
        setSearchType("Description Search");
      } else {
        setSearchType("Your Threat Description Search");
      }
    } else {
      setSearchType("Application No/Name");
      setSearchValue("");
    }
  };

  const handleUseTemplate = (template) => {
    const template_json = template.template_json;
    getSearchTerm(template_json);

    setExcludeSameClassInfringement(
      template_json.exclude_same_class?.value
        ? template_json.exclude_same_class.value
        : false,
    );
    setSameClassInfringement(
      template_json?.red_band?.value ? template_json.red_band.value : false,
    );
    setExcludeReported(
      template_json?.exclude_marks_wrt_custom_report?.value
        ? template_json.exclude_marks_wrt_custom_report.value
        : false,
    );
    setSimilarity(
      template_json?.threat_similarity?.value
        ? template_json.threat_similarity.value
        : {
            from: 0,
            to: 100,
          },
    );
    setTags(template_json.tags?.value.length ? template_json.tags.value : []);
    setTMCategories(
      template_json.mark_categories?.value.length
        ? template_json.mark_categories.value
        : [],
    );
    setTMClass(
      template_json.mark_classes?.value.length
        ? template_json.mark_classes.value
        : [],
    );
    setYourDateOfApplication(
      template_json.mark_date_of_application?.value?.from.length ||
        template_json.mark_date_of_application?.value?.to.length
        ? generateDateObject(template_json.mark_date_of_application)
        : { from: "", to: "", dateString: "" },
    );
    setYourDateOfUsage(
      template_json.mark_date_of_usage?.value?.from.length ||
        template_json.mark_date_of_usage?.value?.to.length
        ? generateDateObject(template_json.mark_date_of_usage)
        : { from: "", to: "", dateString: "" },
    );
    setYourDateOfUsageProposed(
      template_json.mark_date_of_usage_proposed?.value
        ? template_json.mark_date_of_usage_proposed.value
        : false,
    );
    setTMOffices(
      template_json.mark_offices?.value.length
        ? template_json.mark_offices.value
        : [],
    );
    setProprietors(
      template_json.mark_proprietors?.value.length
        ? template_json.mark_proprietors.value
        : [],
    );
    setTMTypes(
      template_json?.mark_types?.value.length
        ? template_json.mark_types.value
        : [],
    );
    setThreatAttorneyValue(
      template_json.threat_attorney?.value.length
        ? template_json.threat_attorney.value
        : [],
    );
    setThreatTMCategories(
      template_json.threat_categories?.value.length
        ? template_json.threat_categories.value
        : [],
    );
    setThreatClass(
      template_json.threat_classes?.value.length
        ? template_json.threat_classes.value
        : [],
    );
    setThreatDateOfApplication(
      template_json.threat_date_of_application?.value.from.length ||
        template_json.threat_date_of_application?.value.to.length
        ? generateDateObject(template_json.threat_date_of_application)
        : { from: "", to: "", dateString: "" },
    );
    setThreatDateOfUsage(
      template_json.threat_date_of_usage?.value.from.length ||
        template_json.threat_date_of_usage?.value.to.length
        ? generateDateObject(template_json.threat_date_of_usage)
        : { from: "", to: "", dateString: "" },
    );
    setThreatDateOfUsageProposed(
      template_json.threat_date_of_usage_proposed?.value
        ? template_json.threat_date_of_usage_proposed.value
        : false,
    );
    setThreatTMOffices(
      template_json.threat_offices?.value.length
        ? template_json.threat_offices.value
        : [],
    );
    setThreatProprietors(
      template_json.threat_proprietors?.value.length
        ? template_json.threat_proprietors.value
        : [],
    );
    setInfringement(
      template_json.threat_sources?.value.length
        ? template_json.threat_sources.value
        : [],
    );
    setThreatTMTypes(
      template_json?.threat_types?.value.length
        ? template_json.threat_types.value
        : [],
    );
    setAndOrTags(template_json.tags.logic === "AND" ? true : false);
    setAndOrClass(template_json.mark_classes.logic === "AND" ? true : false);
    setAndOrThreatClass(
      template_json.threat_classes.logic === "AND" ? true : false,
    );
    setExcludeMarkProprietors(
      template_json?.exclude_mark_proprietors?.value.length
        ? template_json.exclude_mark_proprietors.value
        : [],
    );
    setExcludeThreatProprietors(
      template_json?.exclude_threat_proprietors?.value.length
        ? template_json.exclude_threat_proprietors.value
        : [],
    );

    if (urlKey === "image_similarity") {
      setImageTextPercentage(
        template_json?.image_text_percentage?.value
          ? template_json.image_text_percentage.value
          : {
              from: 0,
              to: 100,
            },
      );
      setThreatImageTextPercentage(
        template_json?.threat_image_text_percentage?.value
          ? template_json.threat_image_text_percentage.value
          : {
              from: 0,
              to: 100,
            },
      );
    }
  };

  let filterSelected = isAnyFilterSelected(generateFilterObject());

  return (
    <Drawer
      title="Filters & Templates"
      placement="right"
      width={"80%"}
      style={{ overflowY: "inherit" }}
      closeIcon={
        filterSelected ? (
          <Popconfirm
            title="Want to apply current selected filters?"
            description="There are unapplied filters. Are you sure you want to leave without applying these filters?"
            okText="Apply"
            cancelText="Leave"
            onCancel={() => {
              handleResetFilterAndTemplate();
              onClose();
            }}
            onConfirm={handleApplyFilter}
          >
            <CloseOutlined />
          </Popconfirm>
        ) : (
          <CloseOutlined onClick={onClose} />
        )
      }
      open={open}
      extra={
        <Space>
          <Button
            type="text"
            danger
            onClick={() => props?.handleResetFilters(journal)}
            disabled={!filterSelected}
          >
            Reset All
          </Button>
          <Button
            type="primary"
            icon={<FilterFilled />}
            onClick={handleApplyFilter}
            disabled={!filterSelected}
          >
            Apply Filters
          </Button>
        </Space>
      }
    >
      <Row>
        <Col
          sm={24}
          md={14}
          style={{ padding: "0px 10px", borderRight: "1px solid lightgrey" }}
        >
          <FilterSection
            {...localProps}
            {...parentProps}
            current_template_id={current_template_id}
            filterSelected={filterSelected}
            getFilterPath={props.getFilterPath}
          />
        </Col>
        <Col
          sm={24}
          md={10}
          style={{ padding: "0px 10px" }}
          className="tmapplied_ll_filter_template"
        >
          <TemplateSection
            selected={selectedTemplate}
            setSelected={setSelectedTemplate}
            handleTemplateSave={handleTemplateSave}
            handleUseTemplate={handleUseTemplate}
            handleReset={handleResetFilterAndTemplate}
            USER={USER}
            RECOMMENDED={RECOMMENDED}
            current_template_id={current_template_id}
            template_status={template_status}
            filterSelected={filterSelected}
            getFilterPath={props.getFilterPath}
          />
        </Col>
      </Row>
    </Drawer>
  );
};

export default MoreFilterDrawer;
